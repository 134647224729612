import URI from 'urijs';

import Chapter from '../../../dataServices/Chapter';
import type Episode from '../../../dataServices/Episode';
import { type TPlayableObject } from '../../types';
import { NON_REWINDABLE_ERROR } from '../constants';
import { type ILiveStationAudioSource } from '../types';
import {
  addTimes,
  isContentTypeAd,
  loadSourcesForItem,
  sFromTime,
  subtractTimes,
  timeFromMs,
  timeFromS,
} from '../utils';
import { type IRewindProvider, type IRewindProviderContext } from './provider';
import {
  type StreamType,
  findCurrentChapter,
  findCurrentChapterFromOffset,
  normalizeResumePoint,
} from './sharedUtils';
import { type IRewindStationMachineContext } from '.';

type IServerSideRewindContext = {
  source?: ILiveStationAudioSource;
  seekingInChapter?: boolean;
} & IRewindProviderContext;

function getLiveChapter(episode: Episode, chapters: Array<Chapter>) {
  let startOffset;
  if (chapters.length > 0) {
    const lastElement = chapters[chapters.length - 1];
    startOffset = lastElement.data.startOffset + lastElement.data.duration + 10; // NOTICE: added 10 seconds for the difference between chapters for correct switching
  } else {
    startOffset = episode.getStartTimeSeconds();
  }

  return new Chapter({ startOffset, title: `Part ${chapters.length + 1}` });
}

// NOTE We no longer need this ssrUtils file, we can move it into RewindStationMachine
export const serverSideRewindProvider: IRewindProvider<IServerSideRewindContext> = {
  streamType: undefined as unknown as StreamType,

  isPlayingRewindContent: ({ metadata }) => {
    return !metadata?.isLive;
  },

  isRewindable: ({ episode }) => episode?.isRewindable() ?? true,

  // Used on legacy rewind
  canSeekToTimeWithoutLoading: () => false,

  loadSources: async function (rewindContext, ctx) {
    const source = (
      await loadSourcesForItem({
        ...ctx,
        // There is an edge case when playing a past BROADCAST rewind episode
        // that episode has a null streamUrl so use the station's streamUrl
        item: this.streamType === 'BROADCAST' ? (ctx.station as TPlayableObject) : ctx.item,
      })
    )?.find((p) => p.type === 'm3u8');

    return {
      ...rewindContext,
      source,
    };
  },
  getChapters: async function (_, ctx) {
    const { episode } = ctx;
    if (!episode) {
      return ctx;
    }

    const chapters =
      (await episode.getChapters({
        broadcastChapters: ctx.featureFlags?.broadcastChapters,
      })) ?? [];
    chapters.push(getLiveChapter(episode, chapters));

    ctx.chapters = chapters;
    return ctx;
  },
  getSchedule: async function (rewindContext, ctx) {
    const { playLive } = ctx.rewindProvider;
    let playbacks = await ctx.personalizationProvider.getPlaybacks();

    // 'Back to live' feature (BROADCAST only)
    if (playLive) {
      ctx.requestedOffset = undefined;
      playbacks = {};
    }

    // Get the episode (BROADCAST only)
    if (this.streamType === 'BROADCAST') {
      const schedules = await ctx.station!.getSchedules();

      // Case 1: requestedOffset trumps episode, override the episode
      if (ctx.requestedOffset) {
        ctx.episode = await schedules.getEpisode(ctx.requestedOffset * 1000);
      }

      // Case 2: we don't have an episode, default to live episode
      if (!ctx.episode) {
        ctx.episode = await schedules.getEpisode(Date.now());
      }

      // Case 3: we already have an episode but no requestedOffset, do nothing
      // no-op
    }

    const isStandaloneChapter = this.streamType === 'STANDALONE_CHAPTER';

    const entity = isStandaloneChapter ? ctx.standaloneChapter : ctx.episode;

    // We should always have an episode now, this is just for typechecking (no-op)
    if (!entity) {
      ctx.ddLogger?.error(
        `BUG: No ${isStandaloneChapter ? 'standaloneChapter' : 'episode'} found while playing SSR`,
      );
      return ctx;
    }

    // Normalize the resume point to something we can use
    ctx.requestedOffset = normalizeResumePoint({
      resumePoint: ctx.requestedOffset ?? playbacks[entity.getId()],
      entity,
      streamType: this.streamType,
    });

    ctx.ddLogger?.info(
      `Resume point for entity ${entity.getId()} for SSR is ${ctx.requestedOffset}`,
    );

    // Throw an error if trying to play non-rewindable, non-live content (like a game)
    // TODO: [CCS-1762] this check should be part of loadAudio but that is currently an action not a service. We should refactor that to be a service and update the machine accordingly.
    if (!entity.isRewindable() && entity.getEndTimeMillis() < Date.now()) {
      ctx.ddLogger?.error(NON_REWINDABLE_ERROR, ctx.episode);
      throw new Error(NON_REWINDABLE_ERROR);
    }

    // Update episode chapters
    if (ctx.episode) {
      const playback = playbacks[entity.getId()];
      const chapters =
        (await ctx.episode.getChapters({
          playback,
          broadcastChapters: ctx.featureFlags?.broadcastChapters,
        })) ?? [];
      if (!chapters.length) {
        const chapter = new Chapter({
          duration: ctx.episode.getDuration(),
          startOffset: ctx.episode.getStartTimeSeconds(),
          title: 'Part 1',
        });
        chapters.push(chapter);
      }
      ctx.chapters = chapters;
    }

    return ctx;
  },
  exitRewindMode: (ctx) => ctx,
  getCurrentChapterIndex: (
    {
      chapters,
      episode,
      elapsed,
      currentChapterIndex: oldCurrentChapterIndex,
      logger,
      metadata,
      requestedOffset,
    },
    rewindContext,
    withOffset = false,
  ) => {
    let newIndex: number | undefined;
    let seekingInChapter = rewindContext?.seekingInChapter;

    if (metadata?.isLive && chapters) {
      newIndex = chapters.length - 1;
    } else if (withOffset) {
      newIndex = findCurrentChapterFromOffset(requestedOffset, chapters);

      if (
        (newIndex !== oldCurrentChapterIndex && oldCurrentChapterIndex !== undefined) ||
        isContentTypeAd(metadata?.contentType)
      ) {
        seekingInChapter = false;
      } else {
        seekingInChapter = true;
      }
    } else {
      newIndex = findCurrentChapter(chapters, episode, elapsed ?? 0);
    }

    if (newIndex !== oldCurrentChapterIndex) {
      logger?.info(`Current chapter index changed from ${oldCurrentChapterIndex} to ${newIndex}`);
    }

    return {
      currentChapterIndex: newIndex,
      seekingInChapter,
    };
  },
  mapPlayerTime: (rewindContext, ctx) => {
    const { time, metadata, episode, standaloneChapter } = ctx;

    const entity = standaloneChapter || episode;

    const entityStartTime = timeFromMs(entity?.getStartTimeMillis() ?? 0);

    // ATL is StreamGuys which has no datumTime, so use entityStartTime
    // datumTime is essentially the startTs / resume point when playing a rewind stream relative to unix epoch in seconds
    // datumTime is usually equal to startTs so that's the best way to think of it
    // time is the elasped time since we started playing the stream
    // so datumTime + time = the current playback timestamp relative to unix epoch
    const datumTime = metadata?.datumTime ? timeFromS(metadata.datumTime) : entityStartTime;

    const elapsed = sFromTime(addTimes(subtractTimes(datumTime, entityStartTime), timeFromS(time)));

    return { elapsed };
  },
  mapBufferedSeekTime: () => undefined,
  saveResumePoint: (ctx, resumePoint) => {
    return {
      ...ctx,
      requestedOffset:
        ctx.player.vodFeatureEnabled && isContentTypeAd(ctx.metadata?.contentType)
          ? ctx.requestedOffset
          : resumePoint,
    };
  },
  performSeek: function (
    rewindContext,
    { time, currentChapterIndex, metadata, chapters, episode, standaloneChapter },
  ) {
    const entity = standaloneChapter ?? episode;
    // StreamGuys uses 'seconds since episode start'
    const requestedOffset = normalizeResumePoint({
      resumePoint: time,
      entity,
      streamType: this.streamType,
    });

    const isStandaloneChapter = this.streamType === 'STANDALONE_CHAPTER';

    const episodeSeekingInChapter =
      currentChapterIndex !== undefined &&
      findCurrentChapter(chapters, episode, time) === currentChapterIndex;

    return [
      {
        ...rewindContext,
        seekingInChapter: isStandaloneChapter ? true : episodeSeekingInChapter,
      },
      {
        requestedOffset,
        elapsed: time,
        metadata: {
          ...metadata,
          // ATL is StreamGuys, so keep datumTime undefined
          datumTime: metadata?.datumTime ? requestedOffset : undefined,
        },
      },
    ];
  },
  getLoadParams: function (rewindContext, ctx) {
    const { source, seekingInChapter } = rewindContext;
    const { logger, ddLogger, requestedOffset, episode, standaloneChapter, rewindProvider } = ctx;

    if (!source) {
      ddLogger?.error('No playable streamUrl found (SSR)');
      throw new Error('No playable streamUrl found (SSR)');
    }

    const url = new URI(source.url);
    const isStandaloneChapter = this.streamType === 'STANDALONE_CHAPTER';

    let isInChapter = seekingInChapter;

    // NOTE:  No requestOffset signifies a non-live rewind stream
    if (requestedOffset !== undefined && !rewindProvider.playLive) {
      url.setQuery('startTs', requestedOffset);

      if (isStandaloneChapter) {
        const endTs = standaloneChapter?.getEndTimeSeconds();
        url.addQuery('endTs', endTs);
      }

      // NOTE: [CCS-2636] - VOD - only add mode=vod if the episode is rewindable and we are playing a VOD stream/not live
      if (!!ctx.player.vodFeatureEnabled && episode?.isRewindable() && !rewindProvider.playLive) {
        url.addQuery('mode', 'vod');
      }

      logger?.debug(
        `Requesting SSR stream with wall clock offset ${requestedOffset}, ${
          seekingInChapter ? 'within the current chapter' : 'across a chapter boundary'
        }`,
      );

      if (ctx.retryCount && ctx.retryCount > 0) {
        // inChapter is used to prevent ads from playing
        // We can use this when trying to reconnect so a user doesn't immediately get an ad when they reconnect
        url.addQuery('inChapter', true);
        isInChapter = true;
      } else if (isStandaloneChapter) {
        if (seekingInChapter) {
          url.addQuery('inChapter', true);
          isInChapter = true;
        } else {
          url.addQuery('inChapter', false);
          isInChapter = true;
        }
      } else {
        url.addQuery('inChapter', seekingInChapter !== false);
        isInChapter = seekingInChapter !== false;
      }
    }

    url.addQuery('newMeta', 'true');

    logger?.info(`Requesting SSR stream with URL ${url}`);
    ddLogger?.info(`Requesting SSR stream with URL ${url}`);

    return {
      url: url.valueOf(),
      isPodcast: source.type !== 'm3u8',
      isSuperHifi: false,
      seekingInChapter: isInChapter,
    };
  },
  handleRewindContentEnded(rewindContext, ctx) {
    return {
      rewindContext: this.exitRewindMode(rewindContext),
      requestedOffset: ctx.player.vodFeatureEnabled ? ctx.requestedOffset : undefined,
    };
  },
  saveResumePoints(context: IRewindStationMachineContext, shouldSaveOnServer = true) {
    const { rewindProvider, elapsed, personalizationProvider, episode, standaloneChapter, item } =
      context;
    const entity = episode ?? standaloneChapter;
    if (!entity || elapsed === undefined) {
      return context;
    }

    context.timeSinceLastSavedResumePoint = Date.now();

    const resumePoint = entity.getStartTimeSeconds() + elapsed;

    personalizationProvider.setPlaybacks(
      [
        {
          contentId: entity.getId(),
          playbackOffset: resumePoint,
        },
        { contentId: item.data.id, playbackOffset: resumePoint },
      ],
      shouldSaveOnServer,
    );

    return rewindProvider.saveResumePoint(context, resumePoint);
  },
};
