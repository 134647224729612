import { isAdState, playerControlsState, playerTimeState } from '@audacy-clients/core/atoms/player';
import { isPlayerButtonVisible } from '@audacy-clients/core/utils/player';
import { useRecoilValue } from 'recoil';

import usePlayerControls from '~/components/Player/hooks/usePlayerControls';
import PlayerConstants from '~/constants/player';
import { type TStyles } from '~/types/emotion-styles';

import { useScrubberProps } from './hooks/useScrubberProps';
import Scrubber from './Scrubber';

interface IScrubberWithState {
  inputCss?: TStyles;
  isFullPlayer?: boolean;
  shouldDisplayTime?: boolean;
  bufferProgress?: number; // 0 to 1
  playProgress?: number; // 0 to 1
  shouldDisplayScrubber?: boolean;
}

const ScrubberWithState = ({
  bufferProgress,
  inputCss,
  isFullPlayer,
  playProgress,
  shouldDisplayTime,
  shouldDisplayScrubber = true,
}: IScrubberWithState): JSX.Element => {
  const { onSkip, onSkipToLive } = usePlayerControls();

  const {
    customEnd,
    onScrubActionEnd,
    onScrubActionStart,
    onScrubAction,
    playProgressValue,
    scrubToTime,
    currentTime,
    isScrubbing,
  } = useScrubberProps({ playProgress });

  const isAd = useRecoilValue(isAdState);

  const {
    backToLiveButton,
    ff15sButton,
    rw15sButton,
    scrubberIsUserControlled,
    shouldDisplayLiveIndicator,
  } = useRecoilValue(playerControlsState) ?? {};

  const canScrub =
    shouldDisplayScrubber &&
    isPlayerButtonVisible(ff15sButton) &&
    isPlayerButtonVisible(rw15sButton) &&
    !isAd;

  const shouldDisplayBackToLive = isPlayerButtonVisible(backToLiveButton, true) && !isAd;

  const noop = () => {
    // do nothing
  };

  const { duration = 0, offset = 0, playbackLiveFraction = 0 } = useRecoilValue(playerTimeState);

  const bufferProgressValue = bufferProgress ?? playbackLiveFraction;

  return (
    <Scrubber
      bufferedValue={bufferProgressValue}
      currentTime={isScrubbing ? offset : currentTime}
      customEnd={customEnd}
      duration={duration}
      inputCss={inputCss}
      isFullPlayer={isFullPlayer}
      playProgressValue={playProgressValue}
      onSkipForward={() => onSkip(PlayerConstants.skip15)}
      onSkipBack={() => onSkip(PlayerConstants.skip15 * -1)}
      onSkipToLive={onSkipToLive}
      onScrubActionEnd={canScrub ? onScrubActionEnd : noop}
      onScrubActionStart={canScrub ? onScrubActionStart : noop}
      onScrubAction={onScrubAction}
      scrubberIsUserControlled={scrubberIsUserControlled}
      shouldDisplayTime={shouldDisplayTime && !isAd}
      shouldDisplayBackToLive={shouldDisplayBackToLive}
      shouldDisplayLiveIndicator={shouldDisplayLiveIndicator && !isAd}
      scrubToTime={scrubToTime}
      shouldDisplayScrubber={canScrub}
    />
  );
};

export default ScrubberWithState;
