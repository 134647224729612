import {
  IPreferences,
  TPreferencesPatch,
} from '@audacy-clients/client-services/src/personalizationServices/types';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import clientServices from '@audacy-clients/core/utils/clientServices';

export enum BrazeNotificationStationsEvents {
  STATION_NOTIFICATIONS = "stationNotifications",
  SET_STATION_NOTIFICATION_ON = "SET_STATION_NOTIFICATION_ON",
  SET_STATION_NOTIFICATION_OFF = "SET_STATION_NOTIFICATION_OFF",
}

export enum BrazeNotificationPodcastsAndShowsEvents {
  PODCAST_SHOW_NOTIFICATIONS = "podcastNotifications"
}


export const preferencesState = atom<IPreferences>({
  default: undefined,
  key: 'Preferences',
});

export enum AutoplayConnectorStates {
  Idle = 'Idle',
  Ready = 'Ready',
  Initiated = 'Initiated',
}

export const autoplayConnectorState = atom<AutoplayConnectorStates>({
  default: AutoplayConnectorStates.Idle,
  key: 'AutoplayConnectorState',
});

type TSetPreferencesProps = {
  preferences: TPreferencesPatch;
  callbacks?: {
    onSuccess?: () => void;
    onError?: () => void;
  };
};

type TSetPreferencesReturn = ({ preferences, callbacks }: TSetPreferencesProps) => Promise<void>;

export const useSetPreferences = (): TSetPreferencesReturn => {
  const { clientServices: cs } = useClientServices();
  const [preferences, setPreferences] = useRecoilState(preferencesState);

  return async ({ preferences: newPreferences, callbacks }): Promise<void> => {
    // handle local updates
    const { notifications: newNotifications, ...restNewPreferences } = newPreferences;
    const { notifications: currentNotifications, ...restCurrentPreferences } = preferences;

    setPreferences({
      ...restCurrentPreferences,
      ...restNewPreferences,
      notifications: {
        ...currentNotifications,
        ...newNotifications,
      },
    });

    await cs
      .getPersonalizationServices()
      .setPreferences(newPreferences)
      .then(() => {
        callbacks?.onSuccess?.();
      })
      .catch(() => {
        callbacks?.onError?.();
      });
  };
};

// Variables to store removed IDs to support undo functionality
const removedIds: { stationIds: Array<string>, podcastIds: Array<string> } = { stationIds: [], podcastIds: [] };

// remove id from Braze podcast/show notifications array
const removeFromBrazePodcastArray = (idToRemove: string) => {
	const { removeFromBrazeAttributeArray } = clientServices.getPersonalizationServices()
    if (!removeFromBrazeAttributeArray) return;
    removeFromBrazeAttributeArray(BrazeNotificationPodcastsAndShowsEvents.PODCAST_SHOW_NOTIFICATIONS, idToRemove)
  }

// remove id from Braze station notifications array 
const removeFromBrazeStationArray = (idToRemove: string) => {
	const { removeFromBrazeAttributeArray } = clientServices.getPersonalizationServices()
    if (!removeFromBrazeAttributeArray) return;
    removeFromBrazeAttributeArray(BrazeNotificationStationsEvents.STATION_NOTIFICATIONS, idToRemove)
  }

  // add id to Braze podcast/show notifications array (used for undo actions)
const addToBrazePodcastArray = (idToRemove: string) => {
	const { addToBrazeAttributeArray } = clientServices.getPersonalizationServices()
    if (!addToBrazeAttributeArray) return;
    addToBrazeAttributeArray(BrazeNotificationPodcastsAndShowsEvents.PODCAST_SHOW_NOTIFICATIONS, idToRemove)
  }

// add id to Braze station notifications array (used for undo actions)
const addToBrazeStationArray = (idToRemove: string) => {
	const { addToBrazeAttributeArray } = clientServices.getPersonalizationServices()
    if (!addToBrazeAttributeArray) return;
    addToBrazeAttributeArray(BrazeNotificationStationsEvents.STATION_NOTIFICATIONS, idToRemove)
  }


export const useUpdateNotificationPreferences = () => {
	const setPreferences = useSetPreferences();
	const preferences = useRecoilValue(preferencesState);

	// Function to remove IDs from an array and return the new array and removed IDs
	const removeIdsFromArray = (
		existingIds: Array<string>,
		idsToRemove: Array<string>
	): [Array<string>, Array<string>] => {
		const updatedIds = existingIds.filter(id => !idsToRemove.includes(id));
		const removedIds = existingIds.filter(id => idsToRemove.includes(id));
		return [updatedIds, removedIds];
	};

	// Function to add IDs back to an array
	const addIdsToArray = (
		existingIds: Array<string> | undefined,
		idsToRemove: Array<string>
	) => {
		if (!existingIds) {
			return idsToRemove;
		}
		return [...existingIds, ...idsToRemove.filter(id => !existingIds.includes(id))];
	};

	// Function to update preferences state
	const updatePreferences = (newValues: { stationIds?: Array<string>, podcastIds?: Array<string> }) => {
		const newPreferences = {
			notifications: {
				...newValues
			}
		};
		void setPreferences({ preferences: newPreferences });
	};

	// Function to handle unfollow action
	const onUnfollow = useCallback((id: string | Array<string>) => {
		const { stationIds, podcastIds } = preferences.notifications;
		const idsArray = Array.isArray(id) ? id : [id];

		if (stationIds?.some(stationId => idsArray.includes(stationId)) && 
		podcastIds?.some(podcastId => idsArray.includes(podcastId))){
			const [newStationIds, removedStationIds] = removeIdsFromArray(stationIds, idsArray);
			const [newPodcastIds, removedPodcastIds] = removeIdsFromArray(podcastIds, idsArray);
			removedIds.stationIds = removedStationIds;
      		removedIds.stationIds.forEach((id) => removeFromBrazeStationArray(id));
			removedIds.podcastIds = removedPodcastIds;
      		removedIds.podcastIds.forEach((id) => removeFromBrazePodcastArray(id))
			updatePreferences({ stationIds: newStationIds, podcastIds: newPodcastIds });
		} else if (stationIds?.some(stationId => idsArray.includes(stationId))) {
			const [newStationIds, removedStationIds] = removeIdsFromArray(stationIds, idsArray);
			removedIds.stationIds = removedStationIds;
      		removedIds.stationIds.forEach((id) => removeFromBrazeStationArray(id));
			removedIds.podcastIds = [];
			updatePreferences({ stationIds: newStationIds });
		} else if (podcastIds?.some(podcastId => idsArray.includes(podcastId))) {
			const [newPodcastIds, removedPodcastIds] = removeIdsFromArray(podcastIds, idsArray);
			removedIds.podcastIds = removedPodcastIds;
      		removedIds.podcastIds.forEach((id) => removeFromBrazePodcastArray(id));
			removedIds.stationIds = [];
			updatePreferences({ podcastIds: newPodcastIds });
		}
	}, [setPreferences, preferences]);

	// Function to handle undo action
	const onUndo = useCallback(() => {
		const { stationIds, podcastIds } = preferences.notifications;

		if (removedIds.stationIds.length > 0 && removedIds.podcastIds.length > 0){
			const newStationIds = addIdsToArray(stationIds, removedIds.stationIds);
			const newPodcastIds = addIdsToArray(podcastIds, removedIds.podcastIds);
			removedIds.stationIds.forEach((id) => addToBrazeStationArray(id));
			removedIds.podcastIds.forEach((id) => addToBrazePodcastArray(id));
			removedIds.stationIds = [];
			removedIds.podcastIds = [];
			updatePreferences({ stationIds: newStationIds, podcastIds: newPodcastIds });
		} else if (removedIds.stationIds.length > 0) {
			const newStationIds = addIdsToArray(stationIds, removedIds.stationIds);
			removedIds.stationIds.forEach((id) => addToBrazeStationArray(id))
			removedIds.stationIds = [];
			updatePreferences({ stationIds: newStationIds });
		} else if (removedIds.podcastIds.length > 0) {
			const newPodcastIds = addIdsToArray(podcastIds, removedIds.podcastIds);
			removedIds.podcastIds.forEach((id) => addToBrazePodcastArray(id))
			removedIds.podcastIds = [];
			updatePreferences({ podcastIds: newPodcastIds });
		}
		
	}, [setPreferences, preferences]);

	return {
		onUnfollow,
		onUndo
	};
};

export default useUpdateNotificationPreferences;