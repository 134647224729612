import { formatSecondsToTime } from '@audacy-clients/core/utils/time';
import { css } from '@emotion/react';
import { forwardRef, memo } from 'react';
import styles from './styles';

type IProps = {
  index: number;
  isActive: boolean;
  startTime: number;
  speaker: string;
  text: string;
  handleTranscriptPress: (startTime: number, index: number) => () => void;
};

const ItemCell = forwardRef<HTMLDivElement, IProps>(
  ({ index, isActive, startTime, speaker, text, handleTranscriptPress }, ref) => {
    return (
      <div ref={ref} css={styles.item} onClick={handleTranscriptPress(startTime, index)}>
        <p css={css(styles.speaker, isActive && styles.highlightedItem)}>
          {formatSecondsToTime(startTime / 1000)} - {speaker}
        </p>
        <p css={css(styles.text, isActive && styles.highlightedItem)}>{text}</p>
      </div>
    );
  },
);

export default memo(ItemCell);
