import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

const gtmParams = {
  id: process.env.GTM_ID || '',
  environment: {
    gtm_auth: process.env.GTM_AUTH || '',
    gtm_preview: process.env.GTM_PREVIEW || '',
  },
};

export { GTMProvider, gtmParams };
