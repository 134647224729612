import { Characters } from '@audacy-clients/core/utils/strings';
import { formatSecondsToTime } from '@audacy-clients/core/utils/time';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import Link from '~/components/Link';
import { usePreventFocusOnVisibilityChange } from '~/components/Player/Scrubber/hooks/usePreventFocusOnVisibilityChange';
import Keys from '~/constants/keys';
import { PlayerStyles } from '~/styles';
import { type TStyles } from '~/types/emotion-styles';

import styles from './styles';
import { getTimeLabel } from './utils';

export interface IScrubberProps {
  bufferedValue?: number;
  currentTime?: number;
  customEnd?: string;
  scrubToTime?: string;
  duration?: number;
  inputCss?: TStyles;
  isFullPlayer?: boolean;
  onScrubAction: (value: number) => void;
  onScrubActionEnd: (value: number) => void;
  onScrubActionStart: () => void;
  onSkipBack: () => void;
  onSkipForward: () => void;
  onSkipToLive: () => void;
  playProgressValue?: number;
  scrubberIsUserControlled?: boolean;
  shouldDisplayTime?: boolean;
  shouldDisplayBackToLive?: boolean;
  shouldDisplayLiveIndicator?: boolean;
  isScrubbing?: boolean;
  shouldDisplayScrubber?: boolean;
}

const Constants = {
  timeMin: 0,
  timeMax: 1,
  timeStep: 0.001,
};

const Scrubber = (props: IScrubberProps): JSX.Element => {
  const {
    bufferedValue = 0,
    currentTime = 0,
    customEnd = '',
    duration = 0,
    inputCss,
    isFullPlayer,
    onScrubAction,
    onScrubActionEnd,
    onScrubActionStart,
    onSkipBack,
    onSkipForward,
    onSkipToLive,
    playProgressValue = 1,
    scrubberIsUserControlled = false,
    shouldDisplayTime = false,
    shouldDisplayBackToLive = false,
    shouldDisplayLiveIndicator,
    scrubToTime,
    shouldDisplayScrubber,
  } = props;

  const inputEl = useRef<HTMLInputElement>(null);
  const [shouldPreventFocusRef, setShouldPreventFocus] = usePreventFocusOnVisibilityChange();

  const handleFocus = () => {
    if (shouldPreventFocusRef.current) {
      return setShouldPreventFocus(false);
    }

    setScrubPillVisible(true);
  };

  const { t } = useTranslation();

  const [scrubPillVisible, setScrubPillVisible] = useState<boolean>(false);
  const scrubEndValue = useRef<number>(0);

  const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    switch (key) {
      case Keys.ArrowDown:
      case Keys.ArrowLeft:
        onSkipBack();
        break;
      case Keys.ArrowUp:
      case Keys.ArrowRight:
        onSkipForward();
        break;
      default:
        break;
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(evt.target.value);
    scrubEndValue.current = val;
    onScrubAction(val);

    if (inputEl.current) {
      inputEl.current.focus();
    }
  };

  const getProgressPct = (val: number): string => `${(val / Constants.timeMax) * 100}%`;

  const roundedCurrentTime = Math.round(currentTime);
  const roundedDuration = Math.round(duration);
  const legibleLabel = getTimeLabel(roundedCurrentTime, roundedDuration);
  const skipToLiveLabel = `${t('playerControls.accessibilityLabels.skipToLive')} ${
    Characters.rightAngleQuotation
  }`;
  const liveLabel = `${t('playerControls.accessibilityLabels.liveLabel')}`;

  const formattedCurrentTime = formatSecondsToTime(currentTime);

  return (
    <div css={styles.root}>
      <div css={styles.inputWrapper}>
        <input
          aria-label={t('playerControls.labels.scrubber.changeProgress')}
          aria-valuemin={0}
          aria-valuemax={roundedDuration}
          aria-valuenow={roundedCurrentTime}
          aria-valuetext={legibleLabel}
          css={[styles.scrubBar, inputCss]}
          disabled={!scrubberIsUserControlled}
          type="range"
          min={Constants.timeMin}
          max={Constants.timeMax}
          step={Constants.timeStep}
          value={playProgressValue}
          onKeyDown={handleKeyDown}
          onMouseDown={onScrubActionStart}
          onMouseUp={() => {
            onScrubActionEnd(scrubEndValue.current);
          }}
          onMouseOver={() => setScrubPillVisible(true)}
          onMouseOut={() => setScrubPillVisible(false)}
          onFocus={handleFocus}
          onBlur={() => setScrubPillVisible(false)}
          onTouchStart={onScrubActionStart}
          onTouchEnd={() => {
            onScrubActionEnd(scrubEndValue.current);
          }}
          onChange={handleChange}
          ref={inputEl}
        />
        {inputEl?.current && (
          <div
            css={[
              styles.scrubPillContainer,
              {
                bottom: inputEl.current.offsetHeight + PlayerStyles.THUMB_WIDTH / 2,
                transform: `translateX(${
                  playProgressValue * inputEl.current.offsetWidth -
                  playProgressValue * PlayerStyles.THUMB_WIDTH
                }px)`,
              },
            ]}
          >
            <span
              css={[
                styles.scrubPill,
                scrubPillVisible && shouldDisplayScrubber && styles.scrubPillVisible,
              ]}
            >
              {scrubToTime}
            </span>
          </div>
        )}
        <div css={styles.bar}>
          <div css={styles.barInner}>
            <span style={{ width: getProgressPct(bufferedValue) }} />
            <span style={{ width: getProgressPct(playProgressValue) }} />
          </div>
        </div>
      </div>
      {shouldDisplayTime && (
        <>
          <span css={styles.time}>{formattedCurrentTime}</span>
          <span css={styles.time}>{customEnd}</span>
        </>
      )}
      {shouldDisplayBackToLive && isFullPlayer && (
        <Link
          css={[
            styles.skipButton,
            styles.liveContent,
            scrubPillVisible && shouldDisplayScrubber && styles.darken,
          ]}
          label={skipToLiveLabel}
          onClick={onSkipToLive}
          isTiny
        />
      )}
      {!shouldDisplayBackToLive && isFullPlayer && shouldDisplayLiveIndicator && (
        <div
          css={[
            styles.liveIndicator,
            styles.liveContent,
            !customEnd && styles.liveIndicatorCenter,
            scrubPillVisible && shouldDisplayScrubber && styles.darken,
          ]}
        >
          <Icon name={Icons.LiveSM} sizeInPx={16} />
          <span>{liveLabel}</span>
        </div>
      )}
    </div>
  );
};

export default Scrubber;
