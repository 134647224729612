import { type ISongHistoryItem } from '@audacy-clients/client-services/src/Constants';
import { RefreshTimes } from '@audacy-clients/core/atoms/helpers/constants';
import audacyLogger, { LoggerTopic } from '@audacy-clients/client-services/src/AudacyLogger';
import { getSongHistory } from '@audacy-clients/core/utils/songHistory';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { atomFamily, useRecoilState } from 'recoil';

export type TSongHistoryProps = {
  stationId: string;
  limit?: number;
};

// Average delay before displaying the next song in history
// Typically, songs are added to history 1 minute before they are played
const HISTORY_SONG_DELAY = 60000;

// Atom with Query Refresh. If the cached value and the new value are equal, prevents the state from being updated and prevents unnecessary re-rendering.
export const songHistoryAtom = atomFamily<Array<ISongHistoryItem>, TSongHistoryProps>({
  key: 'SongHistory',
  default: async ({
    stationId,
    limit,
  }: TSongHistoryProps): Promise<ISongHistoryItem[]> => {
    try {
      return await getSongHistory({ stationId, limit });
    } catch (err) {
      audacyLogger.error(
        `[${LoggerTopic.Analytics}] Station: songHistory atom fetching failed event:\n${JSON.stringify(
          err,
          null,
          2,
        )}`,
      );
      return [];
    }
  }
});

export const useSongHistory = ({ stationId, limit }: TSongHistoryProps): Array<ISongHistoryItem> => {
  const [history, setHistory] = useRecoilState(songHistoryAtom({ stationId, limit }));
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const getSongHistoryList = async (limit?: number) => {
      try {
        const data = await getSongHistory({ stationId, limit });
        return data.filter(({ time }) => new Date(time).getTime() + HISTORY_SONG_DELAY < Date.now());
      } catch (err) {
        audacyLogger.error(
          `[${LoggerTopic.Analytics}] Station: songHistory fetching failed event:\n${JSON.stringify(
            err,
            null,
            2,
          )}`,
        );
        return history || [];
      }
    };

    const fetchHistory = async () => {
      let songHistoryList = await getSongHistoryList(limit);
      const songHistoryLength = songHistoryList.length;
  
      // Upload additional songs if any have been removed from history
      if (limit && songHistoryLength < limit) {
        const incresedLimit = limit + (limit - songHistoryLength);
        songHistoryList = await getSongHistoryList(incresedLimit);
      }
      setHistory(songHistoryList);
    };

    fetchHistory();

    const intervalId = setInterval(fetchHistory, RefreshTimes.every15s);
    intervalRef.current = intervalId;

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, [limit, stationId]);

  return history;
};