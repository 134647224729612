import { Colors, ZIndex } from '~/styles';
import { type TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  transcriptContainer: {
    backgroundColor: Colors.secondaryDeepBlue,
    zIndex: ZIndex.fullPlayerPanel,
    overflow: 'hidden',
    transition: 'height 0.3s ease',
    margin: '0 60px',
    borderRadius: '5px',
  },
  collapsedContainer: {
    height: 'fit-content',
  },
  transcriptContent: {
    padding: '20px 10px', // Increased padding left and right
    overflowY: 'auto',
    height: 'calc(85vh - 50px)',
  },
  transcriptHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 40px',
    backgroundColor: Colors.secondaryDeepBlue,
    color: Colors.white,
  },

  transcriptTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: Colors.white,
  },

  toggleButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    color: Colors.white,
    padding: '6px 14px',
    borderRadius: '100px',
    fontSize: '12px',
    fontWeight: '700',
    textAlign: 'center',
    fontFamily: 'Maison Neue',
    lineHeight: '135%',
    letterSpacing: '0.24px',
    cursor: 'pointer',
    border: 'none',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
  },
};

export default styles;
