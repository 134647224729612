import { BP, Colors, mq, Typography } from '~/styles';
import { type TStyleSheet } from '~/types/emotion-styles';

// TODOL= - Transcripts: Still need to work on this component, please ignore commented code
export const Constants = {
  estimatedItemSize: 120,
  flashListviewOffset: 0,
  flashListviewPosition: 0,
  indSize: 48,
  startOffset: 0,
  resumeBtnOpacityDuration: 500,
  resumeBtnOpacityStartVal: 0,
  resumeBtnOpacityEndVal: 1,
  resumeBtnRotationStartValue: 180,
  resumeBtnRotationEndValue: 0,
  scrollTimeoutMs: 10000,
  noIndexFounded: -1,
  // itemPaddingTop: Sizes.size_08,
  // itemSeparatorHeight: Sizes.size_22,
};

const defaultTextStyle: React.CSSProperties = {
  color: Colors.secondaryLilac2,
};

const styles: TStyleSheet = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
    height: '100%',
  },
  scrollContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    paddingTop: '8px',
  },
  item: {
    cursor: 'pointer',
    padding: '15px 0px',
    [mq(BP.Large)]: {
      padding: '15px 30px',
    },
  },
  highlightedItem: {
    color: Colors.white,
  },
  resumeButton: {
    backgroundColor: Colors.white,
    width: '70px',
    borderRadius: '20px',
  },
  resumeButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: '100%',
    justifyItems: 'center',
    bottom: '80px',
    [mq(BP.Large)]: {
      bottom: '40px',
    },
  },
  speaker: {
    ...Typography.bodySmall,
    lineHeight: '18px',
    letterSpacing: '0.15px',
    ...defaultTextStyle,
  },
  text: {
    ...Typography.bodyLarge,
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    ...defaultTextStyle,
  },
  loadContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
};

export default styles;
