import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router';

import Animate from '~/components/Animate';
import Button from '~/components/Button';
import { Icons } from '~/components/Icon/constants';
import MediaLink from '~/components/Link/MediaLink';
import NavLink from '~/components/Link/NavLink';
import { animationStatusName } from '~/constants/animation';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import { MediaQueries } from '~/styles/breakpoints';
import { TStyles } from '~/types/emotion-styles';
import { LinkType, TButtonRef } from '~/types/links';

import { innerContainerVariants, navTransition } from './animation';
import AudacyLogoButton from './AudacyLogoButton';
import MenuButton from './MenuButton';
import styles from './styles';
import { IPrimaryNav, IPrimaryNavMainLink } from './types';

export interface INavigationProps {
  closeButtonRef?: TButtonRef;
  containerCss?: TStyles;
  innerContainerCss?: TStyles;
  isLoggedIn: boolean;
  isMenuOpen?: boolean;
  logIn: () => void;
  sections: IPrimaryNav;
  onClose: () => void;
  navigate: NavigateFunction;
  isHome?: boolean;
}

const Navigation = ({
  containerCss,
  innerContainerCss,
  closeButtonRef,
  isLoggedIn,
  logIn,
  onClose,
  sections,
  isMenuOpen,
  navigate,
  isHome,
}: INavigationProps): JSX.Element => {
  const { t } = useTranslation();
  const { greaterThan, is } = useBreakpoints();
  const isProd = process.env.NODE_ENV === 'production';

  const onLogInClick = () => {
    logIn();
    onClose();
  };

  return (
    <Animate.FadeInOut
      isVisible={isMenuOpen || greaterThan.MD}
      motionCss={[styles.container, containerCss || {}]}
      transition={navTransition}
    >
      <div>
        {is.MD && (
          <MenuButton
            containerRef={closeButtonRef}
            containerCss={styles.close}
            isMenuOpen={isMenuOpen}
            onClick={onClose}
          />
        )}

        <motion.div
          css={[styles.innerContainer, innerContainerCss]}
          variants={is.SM ? innerContainerVariants : {}}
          {...animationStatusName}
        >
          <AudacyLogoButton
            navigate={navigate}
            isHome={isHome}
            containerCss={MediaQueries.mediumAndAbove}
            logoCss={styles.logo}
            onClose={onClose}
          />

          <nav css={styles.navLinks} aria-label={t('navigation.ariaLabels.main')}>
            <ul css={[styles.linksList, styles.bordered]}>
              {sections.main.map((mainLink: IPrimaryNavMainLink) => {
                return (
                  <li key={mainLink.id} css={styles.listItem}>
                    {/* TODO: [A2-1510] Integrate secondary panel */}
                    <NavLink
                      icon={Icons[mainLink.iconRef as Icons]}
                      href={mainLink.href}
                      label={mainLink.label}
                      onClick={onClose}
                    />
                  </li>
                );
              })}
            </ul>

            {isLoggedIn && (
              <ul css={[styles.linksList, styles.bordered]}>
                <li css={styles.listItem}>
                  <NavLink
                    href={sections.myAudio.title.href}
                    icon={Icons.Profile}
                    label={sections.myAudio.title.label}
                    onClick={onClose}
                  />
                </li>
                <li css={styles.listItem}>
                  <NavLink
                    href={sections.settings.title.href}
                    icon={Icons.Gear}
                    label={sections.settings.title.label}
                    onClick={onClose}
                  />
                </li>
                {sections.myAudio.links &&
                  sections.myAudio.links.map((item) => {
                  return (
                    <li css={styles.listItem} key={item.id}>
                      <MediaLink
                        media={item.media}
                        href={item.href}
                        label={item.label}
                        onClick={onClose}
                      />
                    </li>
                  );
                })}
              </ul>
            )}

            <ul css={[styles.linksList, !isLoggedIn && styles.bordered]}>
              {sections.all.map((item) => {
                return (
                  <li css={styles.listItem} key={item.id}>
                    <NavLink hasArrow href={item.href} label={item.label} onClick={onClose} />
                  </li>
                );
              })}
            </ul>
          </nav>
          {!isProd && (
            <div css={styles.loggedInContainer}>
              <NavLink
                hasArrow
                href={sections.devMenu.href}
                label={sections.devMenu.label}
                onClick={onClose}
              />
            </div>
          )}
          {!isLoggedIn && (
            <>
              <div css={styles.loggedInContainer}>
                <MediaLink
                  buttonCss={styles.signInButton}
                  media={Icons.Account}
                  label={t('navigation.signIn')}
                  as={LinkType.Button}
                  onClick={onLogInClick}
                />
              </div>

              <div css={styles.bottomContainer}>
                <Button
                  buttonCss={[styles.joinButton, MediaQueries.smallOnly]}
                  label={t('navigation.join.buttonSm')}
                  size="Extended"
                  theme="White"
                  onClick={onLogInClick}
                />

                <div css={MediaQueries.mediumAndAbove}>
                  <p css={styles.joinText}>{t('navigation.join.description')}</p>
                  <Button
                    buttonCss={styles.joinButton}
                    label={t('navigation.join.button')}
                    onClick={onLogInClick}
                    size="Small"
                    theme="White"
                  />
                </div>
              </div>
            </>
          )}
        </motion.div>
      </div>
    </Animate.FadeInOut>
  );
};

export default Navigation;
