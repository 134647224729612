import { ObjectType } from '../Constants';
import SimpleObject from './SimpleObject';

/**
 * @module Chapter
 */
export default class Chapter extends SimpleObject {
  /**
   * Creates a new instance of a Chapter.
   * @param {Object} data
   * @param {Number} [data.duration] - Optional parameter
   * @param {Number} data.startOffset
   * @param {String} data.title
   */
  constructor({ duration = 0, startOffset, title }) {
    super(ObjectType.CHAPTER);
    this.data = { duration, startOffset, title };
  }

  /**
   * The chapter duration
   * @returns {Number}
   */
  getDuration() {
    return this?.data?.duration || 0;
  }

  /**
   * The chapter startOffset
   * @returns {Number}
   */
  getStartOffset() {
    return this.data.startOffset;
  }

  /**
   * The chapter title
   * @returns {String}
   */
  getTitle() {
    return this.data.title;
  }
}
