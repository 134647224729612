import { ModuleType } from '@audacy-clients/client-services';
import { AdTargetingStatusParams } from '@audacy-clients/client-services/src/personalizationServices/types';

const AD_SOURCE = '21674100491/NTL.AUDACY.WEB';
let TEST_BANNER_AD_SOURCE: string;
let TEST_SQUARE_AD_SOURCE: string;
const INLINE_AD = 'google-ad-manager___inline-1';
const RIGHT_RAIL_AD = 'google-ad-manager___right-rail-1';

if (process.env.API_ENV === 'STG') {
  TEST_BANNER_AD_SOURCE = '/6355419/Travel/Asia';
  TEST_SQUARE_AD_SOURCE = '/6355419/Travel/Europe';
}

export const setGoogleAdPreferences = (adTargeting: AdTargetingStatusParams): void => {
  if (!window.googletag) {
    return;
  }
  // default settings for OPT_IN for personalized Google Ads
  // https://developers.google.com/publisher-tag/reference#googletag.privacysettingsconfig
  let privacySettings = {
    restrictDataProcessing: false,
    childDirectedTreatment: false,
    underAgeOfConsent: false,
    limitedAds: false,
    nonPersonalizedAds: false,
  };

  // OPT_OUT and INELIGIBLE are to be treated the same by Google Ads
  if (adTargeting !== AdTargetingStatusParams.OPT_IN) {
    privacySettings = {
      ...privacySettings,
      restrictDataProcessing: true,
      limitedAds: true,
      nonPersonalizedAds: true,
    };
  }

  /// set Google Ads privacy settings
  window.googletag.cmd.push(function () {
    window.googletag.pubads().setPrivacySettings(privacySettings);
  });

  // only refreshes slots if slots are already present
  const adSlotArray = window.googletag.pubads().getSlots();

  if (adSlotArray.length) {
    window.googletag.cmd.push(function () {
      window.googletag.pubads().refresh();
    });
  }
};

// refreshes google ad slots, taking optional array of Slot objects
// if no slots specified, refreshes all ads
// https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_refresh
// eslint-disable-next-line
export const refreshAds = (adSlot?: any[]): void => {
  if (window.googletag) {
    window.googletag.cmd.push(function () {
      window.googletag.pubads().refresh(adSlot);
    });
  }
};

// destroys google ad slots, taking optional array of Slot objects
// if no slots specified, destroys all ads
// https://developers.google.com/publisher-tag/reference#googletag.destroySlots
// eslint-disable-next-line
export const destroyAdSlots = (adSlot?: any[]): void => {
  if (window.googletag)
    window.googletag.cmd.push(function () {
      window.googletag.destroySlots(adSlot);
    });
};

// defines google ad slot in the document head (required for Google Console)
// https://developers.google.com/publisher-tag/reference#googletag.defineSlot
export const defineAdSlot = (adModule: ModuleType.BANNER_AD | ModuleType.SQUARE_AD): void => {
  if (!window.googletag) {
    return;
  }

  const googleAdScript = document.createElement('script');
  let innerScript = `googletag.cmd.push(function() {

    googletag.pubads().collapseEmptyDivs();`;

  // defines ad slot for banner ad / right-rail ad
  if (adModule === ModuleType.SQUARE_AD) {
    innerScript += `var rightRailAd = googletag
    .defineSlot(
        '${TEST_SQUARE_AD_SOURCE || AD_SOURCE}', [300, 250], '${RIGHT_RAIL_AD}')
    .addService(googletag.pubads());

  var mapping = googletag.sizeMapping()
     .addSize([1120, 0], [300, 250])
     .addSize([0, 0], [])
     .build();

  rightRailAd.defineSizeMapping(mapping);

  `;

    // defines ad slot for square ad / inline ad
  } else if (adModule === ModuleType.BANNER_AD) {
    innerScript += `var inlineAd = googletag
    .defineSlot(
      '${TEST_BANNER_AD_SOURCE || AD_SOURCE}', [[728,90],[300,250]], '${INLINE_AD}')
    .addService(googletag.pubads());

  var mapping = googletag.sizeMapping()
     .addSize([1120, 0], [728, 90])
     .addSize([0, 0], [300, 250])
     .build();

  inlineAd.defineSizeMapping(mapping);

`;
  }
  innerScript += `googletag.enableServices() })`;

  googleAdScript.innerHTML = innerScript;

  document.head.appendChild(googleAdScript);
};

// gets google ad Slot object, needed for destroying and refreshing
// refactor when we have more sophisticated ad slots and ids
// eslint-disable-next-line
export const getAdSlot = (adModule: ModuleType.BANNER_AD | ModuleType.SQUARE_AD): any | null => {
  if (!window.googletag) {
    return;
  }

  const allSlots = window.googletag.pubads().getSlots();
  if (!allSlots[0]) return null;

  const firstElementId = allSlots[0].getSlotElementId();
  let squareAdIndex = null;
  let bannerAdIndex = null;
  if (firstElementId === 'google-ad-manager___inline-1') {
    bannerAdIndex = 0;
  } else if (firstElementId === 'google-ad-manager___right-rail-1') {
    squareAdIndex = 0;
  }

  if (allSlots[1]) {
    squareAdIndex === null ? (squareAdIndex = 1) : (bannerAdIndex = 1);
  }

  if (adModule === ModuleType.SQUARE_AD && squareAdIndex !== null) {
    return allSlots[squareAdIndex];
  } else if (adModule === ModuleType.BANNER_AD && bannerAdIndex !== null) {
    return allSlots[bannerAdIndex];
  }
  return null;
};

export const setAdRefreshRate = (adRefreshRateSecs: string): void => {
  if (!window.googletag) {
    return;
  }
  const refreshRateMs = Number(adRefreshRateSecs) * 1000;

  setInterval(
    () =>
      window.googletag.cmd.push(function () {
        const adSlotArray = window.googletag.pubads().getSlots();
        if (adSlotArray.length) {
          window.googletag.pubads().refresh();
        }
      }),
    refreshRateMs,
  );
};
