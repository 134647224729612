import {
  type EntitySubtype,
  EntityType,
  EpisodeSubType,
  ModuleType,
  StationSubType,
} from '@audacy-clients/client-services/core';
import {
  isConsideredPlayed,
  getFixedResumePoint,
  isConsideredStarted,
} from '@audacy-clients/client-services/src/utils/playbackResumePoints';

import { wrapContentSummary } from '@audacy-clients/core/atoms/wrappers/content';
import {
  type IContentHashMap,
  type IContentSummary,
} from '@audacy-clients/core/atoms/wrappers/types';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { isBefore, differenceInHours, parseISO, subHours } from 'date-fns';
import { useEffect, useState } from 'react';
import { type RecoilValue, selector, useRecoilValueLoadable } from 'recoil';

import refreshableSelector from './helpers/refreshable';
import { liveEpisodeFromSchedule } from './liveNow';
import { playbackResumePointsState } from './playbackResumePoints';
import { type IModuleViewComponent, wrapModule } from './wrappers/modules';

export type IHistoryItem = {
  contentId: string;
  timestamp: string;
};

type IListenHistoryResponse = {
  lastItemTimestamp?: string;
  recents: Array<IContentSummary>;
};

let currentListenHistoryPromise: Promise<IListenHistoryResponse> | undefined;

const getListenHistory = async (): Promise<IListenHistoryResponse> => {
  let lastItemTimestamp;
  const recents = await clientServices
    .getPersonalizationServices()
    .getHistoryWithSummaries()
    .then((entries) => {
      lastItemTimestamp = entries[entries.length - 1]?.timestamp;

      return entries.map((entry) => ({
        ...wrapContentSummary(entry.content),
        lastListenDateTime: entry.timestamp,
      }));
    });
  return {
    lastItemTimestamp,
    recents,
  };
};

export const {
  useRefreshCache: useRefreshRecents,
  useWrappedCachedValue: useCachedRecents,
  selector: recentsSelector,
  forceRefresh: forceRefreshRecents,
} = refreshableSelector<IListenHistoryResponse>({
  get: async () => {
    try {
      if (!currentListenHistoryPromise) {
        currentListenHistoryPromise = getListenHistory();
      }
      const response = await currentListenHistoryPromise;
      return response;
    } finally {
      currentListenHistoryPromise = undefined;
    }
  },
  key: 'ListenHistory',
});

const Constants = {
  maxContinueListening: 15,
  maxRecentlyPlayedStations: 15,
};

export const {
  useWrappedCachedValue: useCachedRecentlyPlayedStations,
  selector: recentlyPlayedStationsSelector,
} = refreshableSelector<IModuleViewComponent>({
  get: ({ get }) => {
    const { recents } = get(recentsSelector);

    const content: IContentHashMap = {};

    const carouselModules = recents
      .filter((recent) => recent.entityType === EntityType.STATION)
      .slice(0, Constants.maxRecentlyPlayedStations)
      .map((stationSummary) => {
        content[stationSummary.id] = stationSummary;

        return {
          config: { contentId: stationSummary.id },
          moduleId: `CLIENT--ENTITY_CARD_HORIZONTAL--RECENTLY_PLAYED_STATIONS--${stationSummary.id}`,
          moduleType: ModuleType.ENTITY_CARD_VERTICAL,
        };
      });

    let module;

    if (carouselModules.length) {
      module = wrapModule({
        config: {
          title: {
            // This is a temporary module, so the title is not translated
            label: 'Recently Played Stations',
          },
        },
        moduleId: 'CLIENT--SECTION_WRAPPER--RECENTLY_PLAYED_STATIONS',
        moduleType: ModuleType.SECTION_WRAPPER,
        modules: [
          {
            moduleId: 'CLIENT--CAROUSEL--RECENTLY_PLAYED_STATIONS',
            moduleType: ModuleType.CAROUSEL,
            modules: carouselModules,
          },
        ],
      });
    }

    return { getContent: (id) => (id ? content[id] : undefined), module };
  },
  key: 'RecentlyPlayedStations',
});

const isReplayable = ({
  lastListenDateTime,
  replayableUntilDateTime,
}: {
  lastListenDateTime?: string;
  replayableUntilDateTime?: string;
}): boolean => {
  if (!lastListenDateTime) {
    return false;
  }

  const now = new Date();
  const compareDate = new Date(replayableUntilDateTime ?? lastListenDateTime);

  // NOTICE:
  // At the moment, checking the lastListenDateTime field is not necessary, the main condition is checking the replayableUntilDateTime field
  // But
  // not all content has a value in the replayableUntilDateTime field there is an additional check for the lastListenDateTime field
  return replayableUntilDateTime
    ? isBefore(now, compareDate)
    : differenceInHours(now, compareDate) < 72;
};

export const isWithinLast24Hrs = (dateTime?: Date): boolean => {
  if (!dateTime) {
    return false;
  }
  const now = new Date();

  return isBefore(dateTime, now) && differenceInHours(now, dateTime) < 24;
};

export const isWithinLast6Hrs = (dateTime?: string): boolean => {
  if (!dateTime) {
    return false;
  }
  const now = new Date();
  const compareDate = new Date(dateTime);
  return isBefore(compareDate, now) && differenceInHours(now, compareDate) < 6;
};

const isEpisodeOrClipResumeable = (episodeOrClip: IContentSummary, resumePoint?: number) => {
  const { duration, entitySubtype, startDateTime, endDateTime } = episodeOrClip;
  // TODO: [A2-3714] https://entercomdigitalservices.atlassian.net/browse/A2-3714
  // ATL that is no longer live should be on-demand for 24 hours.

  const allowedSubtypes: Array<EntitySubtype> = [EpisodeSubType.HOST_CREATED_EPISODE];
  const isAnytimeLive = allowedSubtypes.includes(entitySubtype);

  if (isAnytimeLive && !isWithinLast24Hrs(endDateTime)) {
    return false;
  }

  const fixedResumePoint = getFixedResumePoint(resumePoint, startDateTime);

  return isConsideredStarted(fixedResumePoint) && !isConsideredPlayed(duration, fixedResumePoint);
};

export const {
  useWrappedCachedValue: useCachedContinueListening,
  selector: continueListeningSelector,
} = refreshableSelector<IModuleViewComponent>({
  get: ({ get }) => {
    const { recents } = get?.(recentsSelector) || { recents: [] };
    const playbackResumePoints = get(playbackResumePointsState);

    const content: IContentHashMap = {};

    const carouselModules = recents
      .filter((recent) => {
        const { id, entityType, replayableUntilDateTime, lastListenDateTime } = recent;

        const isCorrectEntityType = [
          EntityType.EPISODE,
          EntityType.CLIP,
          EntityType.STANDALONE_CHAPTER,
        ].includes(entityType);

        const canContinuePlayback =
          playbackResumePoints[id] && isReplayable({ lastListenDateTime, replayableUntilDateTime });

        if (!isCorrectEntityType || !canContinuePlayback) {
          return false;
        }

        return isEpisodeOrClipResumeable(recent, playbackResumePoints[id]);
      })
      .slice(0, Constants.maxContinueListening)
      .map((episodeSummary) => {
        content[episodeSummary.id] = episodeSummary;

        return {
          config: { contentId: episodeSummary.id },
          moduleId: `CLIENT--${ModuleType.ENTITY_CARD_HORIZONTAL}--${ModuleType.CONTINUE_LISTENING}--${episodeSummary.id}`,
          moduleType: ModuleType.ENTITY_CARD_HORIZONTAL,
        };
      });

    let module;

    if (carouselModules.length) {
      module = wrapModule({
        config: {
          title: {
            // NOTICE: This is a temporary module, so the title is not translated
            label: 'Continue Listening',
          },
        },
        moduleId: `CLIENT--${ModuleType.SECTION_WRAPPER}--${ModuleType.CONTINUE_LISTENING}`,
        moduleType: ModuleType.SECTION_WRAPPER,
        modules: [
          {
            config: { itemsPerColumn: 3 },
            moduleId: `CLIENT--${ModuleType.CAROUSEL}--${ModuleType.CONTINUE_LISTENING}`,
            moduleType: ModuleType.CAROUSEL,
            modules: carouselModules,
          },
        ],
      });
    }

    return { getContent: (id) => (id ? content[id] : undefined), module };
  },
  key: 'ContinueListening',
});

export type IMostRecentlyPlayedItem = {
  item: IContentSummary | undefined;
  resumePoint: number | undefined;
};

function shouldPlayLive(data: IContentSummary) {
  const { replayableUntilDateTime, lastListenDateTime } = data;

  if (!lastListenDateTime || !replayableUntilDateTime) {
    return true;
  }

  const lastListenTime = parseISO(lastListenDateTime);
  const replayUntilTime = parseISO(replayableUntilDateTime);
  const currentTime = new Date();

  // sixHoursAgo represents the time exactly 6 hours ago from the current time
  const sixHoursAgo = subHours(currentTime, 6);

  // Check if the last listen time was more than 6 hours ago
  if (isBefore(lastListenTime, sixHoursAgo)) {
    return true; // Play live
  }

  // If less than 6 hours have passed since the last listen, check replayableUntilDateTime
  return isBefore(replayUntilTime, currentTime);
}

// NOTICE: Used to resume where user left off on app load
export const mostRecentlyPlayedState = selector<IMostRecentlyPlayedItem>({
  get: async ({ get }) => {
    const { recents } = get(recentsSelector);

    if (!recents || recents.length === 0) {
      return { item: undefined, resumePoint: undefined };
    }

    let mostRecentItem = recents[0];

    if (mostRecentItem.entitySubtype === StationSubType.BROADCAST) {
      const dateFirstElement = mostRecentItem.lastListenDateTime
        ? new Date(mostRecentItem.lastListenDateTime).getTime()
        : 0;

      const dateSecondElement = recents[1].lastListenDateTime
        ? new Date(recents[1].lastListenDateTime).getTime()
        : 0;

      if (dateFirstElement > dateSecondElement || shouldPlayLive(recents[1])) {
        const liveScheduleItem = get(
          liveEpisodeFromSchedule({ contentId: mostRecentItem.id, isStation: true }),
        );

        return { item: liveScheduleItem, resumePoint: undefined };
      } else {
        mostRecentItem = recents[1];
      }
    }

    const playbackResumePoints = await clientServices.getPersonalizationServices().getPlaybacks();
    let resumePoint: number | undefined;

    if (mostRecentItem) {
      const { id, entityType, replayableUntilDateTime } = mostRecentItem;
      resumePoint = playbackResumePoints[id];

      const cantBeResumed =
        [EntityType.EPISODE, EntityType.CLIP].includes(entityType) &&
        !isEpisodeOrClipResumeable(mostRecentItem, resumePoint);

      if (cantBeResumed || isReplayable({ replayableUntilDateTime })) {
        return { item: undefined, resumePoint: undefined };
      }
    }

    return { item: mostRecentItem, resumePoint };
  },
  key: 'MostRecentlyPlayed',
});

export const useMostRecentlyPlayed = () => {
  return useRecoilValueLoadable(mostRecentlyPlayedState).valueMaybe();
};

export const useWrappedRecents = (
  moduleSelector: RecoilValue<unknown>,
): IModuleViewComponent | undefined => {
  const [moduleData, setModuleData] = useState<IModuleViewComponent | undefined>(undefined);

  const value = useRecoilValueLoadable(moduleSelector).valueMaybe();
  useEffect(() => {
    if (value) {
      setModuleData(value as IModuleViewComponent);
    }
  }, [value]);

  return moduleData;
};

export const useRecentsWithoutSuspense = (): Array<IContentSummary> | undefined => {
  const [recents, setRecents] = useState<Array<IContentSummary> | undefined>(undefined);

  // Use `useRecoilValueLoadable` for async data fetching from `recentsSelector` to manage state without suspensions.
  // This method prevents the whole screen from disruptive suspending on data updates, enhancing user experience. When `recentsLoadable`
  // indicates new data ('hasValue'), and the current `recents` state differs from the fetched data, we update `recents`
  // to keep the component synchronized with the latest content without unnecessary disruptions.
  const recentsLoadable = useRecoilValueLoadable(recentsSelector);
  if (recentsLoadable.state === 'hasValue' && recents !== recentsLoadable.contents.recents) {
    setRecents(recentsLoadable.contents.recents);
  }

  return recents;
};
