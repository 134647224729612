import { AuthState, ClickFeature, EntitiesType } from '@audacy-clients/client-services';
import { checkPlayingById } from '@audacy-clients/core/atoms/player';
import { EntityImageShape } from '@audacy-clients/core/atoms/wrappers/modules';

import clientServices from '@audacy-clients/core/utils/clientServices';
import { useRecoilValue } from 'recoil';
import BaseLink from '~/components/BaseLink';
import EntityImage from '~/components/Entity/components/EntityImage';
import { type IEntityBaseProps, type IEntitySummaryProps } from '~/components/Entity/types';

import Icon from '~/components/Icon';
import { Icons } from '~/components/Icon/constants';
import useGetControlActions from '~/components/Player/hooks/useGetControlActions';
import PlayStateArea from '~/components/PlayStateArea';
import { useFollowEntity } from '~/hooks/use-follow-entity';
import { Colors } from '~/styles';
import { type TStyles } from '~/types/emotion-styles';
import { usePlayOrPause } from '~/utils/hooks/usePlayOrPause';
import { useTitleContent } from './hooks/use-title-content';
import styles from './styles';

const stylesForTag: TStyles = {
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
};

type IVerticalEntityCard = {
  itemsCount?: number;
  onClick?: () => void;
  contentId?: string;
} & IEntityBaseProps & Omit<IEntitySummaryProps, 'title'>

const VerticalEntityCard = ({
  aspectRatio = 1,
  ctaColor,
  duration,
  entityImageShape = EntityImageShape.Square,
  href,
  imageAlt,
  imageURIBase,
  isLive,
  isPlayed,
  liveUntil,
  onClick,
  publishDate,
  resumePoint,
  sizes = [{ maxWidth: 600, size: 125 }, { maxWidth: 1120, size: 250 }, { size: 400 }],
  subtitle,
  title,
  entitySubtype,
  genres,
  marketTitle,
  author,
  itemsCount,
  contentId,
  isPlayable,
  entityType,
}: IVerticalEntityCard): JSX.Element => {
  const titleContent = useTitleContent(
    title,
    entitySubtype,
    genres,
    marketTitle,
    author,
    itemsCount,
    duration,
  );
  const authState = clientServices.getPersonalizationServices().getAuthState();
  const isAuthenticated = authState === AuthState.AUTH;
  const { primaryControl } = useGetControlActions();
  const isPlaying = useRecoilValue(checkPlayingById({ contentId: contentId }));
  const playContext = {
    contentId: contentId,
    feature: ClickFeature.MINI_PLAYER,
  };

  const titleChildren = (
    <>
      {titleContent.headerTitle && <span css={styles.contentType}>{titleContent.headerTitle}</span>}
      {!!title && (
        <span css={styles.title} className="title">
          {title}
        </span>
      )}
    </>
  );

  const playOrPause = usePlayOrPause(contentId);

  const onPlay = () => {
    playOrPause?.(playContext, {
      startOffset: resumePoint,
    });
  };

  const { toggleFollow, isFollowing } = useFollowEntity({ contentId: contentId ?? '', title: title ?? '' });

  const onLike = () => {
    toggleFollow && toggleFollow();
  };

  const goToTitle = 'Go to station >';
  const isTag = entityType === EntitiesType.TAG;
  const isStation = entityType === EntitiesType.STATION;

  const disabledClickOnLink = (e: React.MouseEvent<Element, MouseEvent>) => {
    isStation && e.preventDefault();
  };

  return (
    <BaseLink
      href={isStation ? ' ' : href}
      onClick={disabledClickOnLink}
      css={[isStation && styles.containerWrapper]}
    >
      <div css={[styles.container, !!href && styles.linkContainer]}>
        <div css={styles.imageWrapper} className="imageWrapper" tabIndex={0} onClick={onClick}>
          <div className="imageOpacityLayout">
            <EntityImage
              alt={imageAlt ?? title ?? ''}
              aspectRatio={aspectRatio}
              entityImageShape={entityImageShape}
              imageURIBase={imageURIBase}
              sizes={sizes}
            />
          </div>
          {isStation && (
            <div css={styles.hoverCardLayout} className="hoverCardLayout">
              <div
                css={[
                  styles.hoverCardWrapper,
                  !isPlayable && styles.hoverCardWrapperExtraPadding,
                ]}
              >
                {isAuthenticated ? (
                  <div css={styles.likeButtonLayout}>
                    <button
                      onClick={onLike}
                      css={styles.likeButtonWrapper}
                      className="likeButtonWrapper"
                    >
                      <Icon
                        name={Icons.HeartFilled}
                        iconColor={isFollowing ? Colors.liked : Colors.white}
                        ariaHidden={false}
                        sizeInPx={24}
                      />
                    </button>
                  </div>
                ) : (
                  <div />
                )}

                {isPlayable && (
                  <button
                    css={styles.playIconContainer}
                    className="playIconContainer"
                    onClick={onPlay}
                  >
                    <Icon
                      name={isPlaying ? primaryControl.icon : Icons.Play}
                      iconColor={Colors.greyA6}
                      ariaHidden={false}
                      sizeInPx={52}
                    />
                  </button>
                )}
                <BaseLink href={href} linkCss={styles.linkInImage} onClick={onClick}>
                  {goToTitle}
                </BaseLink>
              </div>
            </div>
          )}
        </div>
        <div css={styles.cardContent}>
          {href ? (
            <BaseLink
              href={href}
              linkCss={[styles.link, isTag ? stylesForTag : {}]}
              onClick={onClick}
            >
              {titleChildren}
            </BaseLink>
          ) : (
            titleChildren
          )}
          {titleContent.subtitle && <span css={styles.subtitle}>{titleContent.subtitle}</span>}
          {!subtitle && (
            <PlayStateArea
              ctaColor={ctaColor}
              duration={duration}
              isLive={isLive}
              isPlayed={isPlayed}
              liveUntil={liveUntil}
              publishDate={publishDate}
              resumePoint={resumePoint}
            />
          )}
        </div>
      </div>
    </BaseLink>
  );
};

export default VerticalEntityCard;
