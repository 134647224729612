import { useCallback, useEffect, useMemo, useState } from 'react';

import { EntityType } from '@audacy-clients/client-services/core';
import { isLoggedIn } from '@audacy-clients/core/atoms/auth';
import { followOrUnfollowWithDataEvents } from '@audacy-clients/core/atoms/helpers/follows';
import { wrapContentSummary } from '@audacy-clients/core/atoms/wrappers/content';
import { IContentSummary } from '@audacy-clients/core/atoms/wrappers/types';
import { simpleArrayToObject, stringArrayToObject } from '@audacy-clients/core/utils/array';
import clientServices from '@audacy-clients/core/utils/clientServices';
import { IViewContext } from '@audacy-clients/core/utils/viewContext';
import { isArray, reduce } from 'lodash';
import includes from 'lodash/includes';
import mapValues from 'lodash/mapValues';
import { atom, useSetRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import refreshableSelector from './helpers/refreshable';
import useUpdateNotificationPreferences from './preferences';

const {
  useRefreshCache: useRefreshRawFollows,
  useWrappedCachedValue: useCachedRawFollows,
  selector: rawFollowsSelector,
} = refreshableSelector<IContentSummary[]>({
  get: async ({ get }) => {
    const loggedIn = get(isLoggedIn);

    if (!loggedIn) {
      return [];
    }

    const summaries = await clientServices
      .getPersonalizationServices()
      .getFollowsWithSummaries()
      .then((rawSummaries) => rawSummaries.map(wrapContentSummary));

    return summaries;
  },
  key: 'RawFollows',
});

export interface ICategorizedFollows {
  onDemands: IContentSummary[];
  stations: IContentSummary[];
  topics: IContentSummary[];
  playlists: IContentSummary[];
}

interface IFollowsResponse {
  isMoreThanOneTab: boolean;
  follows: IContentSummary[];
  categorizedFollows: ICategorizedFollows;
  followsMap: Record<string, IContentSummary>;
}

interface IFollowListResponse {
  isMoreThanOneTab: boolean;
  follows: IContentSummary[] | [];
  isLoading: boolean;
}

const followsMapping = {
  onDemands: [EntityType.SHOW],
  stations: [EntityType.STATION],
  topics: [
    EntityType.TAG,
    EntityType.PERSON,
    EntityType.ORGANIZATION,
    EntityType.GEOGRAPHY,
    EntityType.TOPIC,
  ],
  playlists: [EntityType.COLLECTION],
};

const followsOverrideState = atom<Record<string, boolean>>({
  default: {},
  key: 'FollowsOverrideValues',
});

const filterFollows = (
  rawFollows: IContentSummary[],
  followsOverride: Record<string, boolean>,
): IContentSummary[] => rawFollows?.filter((follow) => followsOverride[follow.id] ?? true) || [];

const categorizeFollows = (follows: IContentSummary[]): ICategorizedFollows =>
  mapValues(followsMapping, (allowedTypes) =>
    follows.filter((s) => includes(allowedTypes, s.entityType)),
  );

export const useFollowList = (): IFollowListResponse => {
  const [cachedFollows, setCachedFollows] = useState<IContentSummary[] | []>([]);

  const loadableFollows = useRecoilValueLoadable(rawFollowsSelector);
  const rawFollows = loadableFollows.valueMaybe();
  const followsOverride = useRecoilValue(followsOverrideState);

  useEffect(() => {
    if (rawFollows !== undefined && rawFollows !== null) {
      setCachedFollows(rawFollows);
    }
  }, [rawFollows]);

  const follows = filterFollows(cachedFollows, followsOverride);
  const categorizedFollows = categorizeFollows(follows);

  const isMoreThanOneTab =
    reduce(categorizedFollows, (acc, value) => acc + (value.length ? 1 : 0), 0) > 1;

  return {
    isMoreThanOneTab,
    follows,
    isLoading: loadableFollows.state === 'loading',
  };
};

export const useCachedFollows = (): IFollowsResponse => {
  const rawFollows = useCachedRawFollows();
  const followsOverride = useRecoilValue(followsOverrideState);

  const follows = filterFollows(rawFollows ?? [], followsOverride);
  const categorizedFollows = categorizeFollows(follows);

  const isMoreThanOneTab =
    reduce(categorizedFollows, (acc, value) => acc + (value.length ? 1 : 0), 0) > 1;

  const followsMap = simpleArrayToObject(follows, (summary) => summary.id);

  return {
    isMoreThanOneTab,
    follows,
    categorizedFollows,
    followsMap,
  };
};

const requests: Record<string, Array<Promise<void>>> = {};

const getAllRequestByIds = (ids: string[]): Promise<void[]> => {
  return Promise.all(
    ids.reduce(
      (allRequests, id) => [...allRequests, ...(requests[id] || [])],
      [] as Array<Promise<void>>,
    ),
  );
};

const awaitRequestByIds = async (ids: string[], request: Promise<void>): Promise<void> => {
  ids.forEach((id) => {
    requests[id] = requests[id] ? [...requests[id], request] : [request];
  });

  await request;

  ids.forEach((id) => {
    if (requests[id]) {
      const index = requests[id].indexOf(request);

      if (index !== -1) {
        requests[id].splice(index, 1);
      }
    }
  });
};

type TFollowAndUnfollowCallbacks = {
  beforeAnyAction?: () => void;
  onError?: (retryCallback: () => void) => void;
  onSuccess?: (undoCallback: () => void) => void;
  onUndo?: (ids: string[]) => void;
  onUndoError?: (retryCallback: () => void) => void;
};

interface IUseFollows {
  categorizedFollows: ICategorizedFollows;
  follows: IContentSummary[];
  checkIsFollowing: (id: string) => boolean;
  follow: (idOrIds: string | string[], callbacks?: TFollowAndUnfollowCallbacks) => void;
  followsMap: Record<string, IContentSummary>;
  toggleFollowing: (id: string, callbacks?: TFollowAndUnfollowCallbacks) => boolean;
  unfollow: (idOrIds: string | string[], callbacks?: TFollowAndUnfollowCallbacks) => void;
  refreshFollows: (value: boolean) => void;
}

export const useFollows = (
  getViewContext: () => IViewContext,
  isOnboarding = false,
): IUseFollows => {
  const refreshFollows = useRefreshRawFollows();
  const { categorizedFollows, followsMap, follows } = useCachedFollows();
  const setFollowsOverride = useSetRecoilState(followsOverrideState);
  const followsIds = useRecoilValue(followsOverrideState);
  const viewContext = getViewContext();
  const { onUnfollow, onUndo: undoHandler } = useUpdateNotificationPreferences();

  const setOverrides = useCallback(
    (ids: string[], isFollow: boolean, isClean?: boolean) => {
      setFollowsOverride((oldFollowsOverride) => {
        if (isClean) {
          const newFollowsOverride = { ...oldFollowsOverride };
          ids.forEach((id) => delete newFollowsOverride[id]);
          return newFollowsOverride;
        }

        const updates = stringArrayToObject(ids, isFollow);
        return { ...oldFollowsOverride, ...updates };
      });
    },
    [setFollowsOverride],
  );

  const checkIsFollowing = useCallback(
    (id: string) => !!followsMap[id] || !!followsIds[id],
    [followsMap, followsIds],
  );

  const undo = useCallback(
    async (ids: string[], callbacks: TFollowAndUnfollowCallbacks, isFollow: boolean) => {
      const { beforeAnyAction, onUndo, onUndoError } = callbacks;

      beforeAnyAction?.();
      setOverrides(ids, isFollow);

      try {
        onUndo?.(ids);
        undoHandler()
        // If there's a current request, await for it before moving on
        await getAllRequestByIds(ids).catch(() => {});

        await awaitRequestByIds(
          ids,
          followOrUnfollowWithDataEvents(ids, viewContext, isFollow, isOnboarding),
        );
      } catch {
        beforeAnyAction?.();

        setOverrides(ids, !isFollow, true);

        onUndoError?.(() => undo(ids, callbacks, isFollow));
      } finally {
        if (isFollow) {
          refreshFollows(true);
        }
     
      }
    },
    [viewContext, isOnboarding, refreshFollows, setOverrides],
  );

  const followOrUnfollow = useCallback(
    async (
      idOrIds: string | string[],
      callbacks: TFollowAndUnfollowCallbacks = {},
      isFollow: boolean,
    ) => {
      const { beforeAnyAction, onSuccess, onError } = callbacks;

      let isHandlingByUndo = false;

      const ids = isArray(idOrIds) ? idOrIds : [idOrIds];

      beforeAnyAction?.();
      setOverrides(ids, isFollow);

      try {
        // If there's a current request, await for it before moving on
        await getAllRequestByIds(ids).catch(() => {});

        await awaitRequestByIds(
          ids,
          followOrUnfollowWithDataEvents(ids, viewContext, isFollow, isOnboarding),
        );

        onSuccess?.(() => {
          isHandlingByUndo = true;

          undo(ids, callbacks, !isFollow);
        });
      } catch {
        if (!isHandlingByUndo) {
          beforeAnyAction?.();

          setOverrides(ids, !isFollow, true);

          onError?.(() => followOrUnfollow(ids, callbacks, isFollow));
        }
      } finally {
        if (isFollow) {
          refreshFollows(true);
        } else if (!isFollow){
          onUnfollow(ids)
        }
      }
    },
    [isOnboarding, viewContext, refreshFollows, setOverrides, undo],
  );

  const follow = useCallback(
    (idOrIds: string | string[], callbacks?: TFollowAndUnfollowCallbacks) =>
      followOrUnfollow(idOrIds, callbacks, true),
    [followOrUnfollow],
  );

  const unfollow = useCallback(
    (idOrIds: string | string[], callbacks?: TFollowAndUnfollowCallbacks) =>
      followOrUnfollow(idOrIds, callbacks, false),
    [followOrUnfollow],
  );

  const toggleFollowing = useCallback(
    (id: string, callbacks?: TFollowAndUnfollowCallbacks) => {
      const isFollowing = checkIsFollowing(id);

      if (isFollowing) {
        unfollow(id, callbacks);
      } else {
        follow(id, callbacks);
      }

      return !isFollowing;
    },
    [checkIsFollowing, follow, unfollow],
  );

  return useMemo(
    () => ({
      followsMap,
      categorizedFollows,
      follows,
      checkIsFollowing,
      follow,
      toggleFollowing,
      unfollow,
      refreshFollows,
    }),
    [
      followsMap,
      categorizedFollows,
      follows,
      checkIsFollowing,
      follow,
      toggleFollowing,
      unfollow,
      refreshFollows,
    ],
  );
};
