import { EpisodeSubType } from '@audacy-clients/client-services';
import { ViewIDKey } from '@audacy-clients/core/atoms/config/constants';

import { useFeatureFlag, useViewId } from '@audacy-clients/core/atoms/config/settings';
import { RequestType } from '@audacy-clients/core/atoms/modules/requests';
import { nowPlaying } from '@audacy-clients/core/atoms/player';
import { Suspense } from 'react';

import { useRecoilValue } from 'recoil';
import About from '~/components/About';
import ChaptersWithState from '~/components/Chapters';
import CenteredLoading from '~/components/Loading/CenteredLoading';
import ModuleRenderer from '~/components/ModuleRenderer';
import TranscriptPanel from '~/components/Player/FullscreenPlayer/Components/TranscriptPanel';
import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import PlaylistBar from '~/components/Player/PlaylistBar';
import { usePlaylistBarProps } from '~/components/Player/PlaylistBar/hooks';
import StandaloneChapterEpisodeCard from '~/components/StandaloneChapterEpisodeCard';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import { Mixins } from '~/styles';

import styles from './styles';

type IInfoProps = {
  isVisible: boolean;
};

const InfoPanel = ({ isVisible }: IInfoProps): JSX.Element => {
  const isPodcastTranscriptEnabled = useFeatureFlag('podcastTranscript');
  const nowPlayingItem = useRecoilValue(nowPlaying);
  const isPodcast = nowPlayingItem?.episodeDataObject?.subtype === EpisodeSubType.PODCAST_EPISODE;
  const isNavigableTranscript =
    nowPlayingItem?.episodeDataObject?.transcript?.isNavigableTranscript;

  const viewId = useViewId(ViewIDKey.Player);
  const { contentId, description, episode, standaloneChapter } = usePlayerProps();
  const {
    nextUpTitle,
    playlistTitle,
    playlistImage,
    playlistImageAlt,
    navigateToPlaylistUrl,
    showPlaylistBar,
  } = usePlaylistBarProps();

  const { greaterThan } = useBreakpoints();

  if (!contentId || !viewId || !isVisible) {
    return <CenteredLoading />;
  }

  return (
    <Suspense fallback={<CenteredLoading />}>
      <div css={styles.container}>
        {showPlaylistBar && (
          <PlaylistBar
            title={playlistTitle}
            nextUpTitle={nextUpTitle}
            navigateToPlaylistUrl={navigateToPlaylistUrl}
            image={playlistImage}
            imageAlt={playlistImageAlt}
            containerCss={Mixins.horizontalModulePadding(true)}
            showSectionWrapper={greaterThan.MD}
          />
        )}
        {episode && episode.isRewind !== false && !standaloneChapter && (
          <ChaptersWithState
            episode={episode}
            live={true}
            containerCss={[styles.chapters, Mixins.horizontalModulePadding(true)]}
          />
        )}

        {greaterThan.MD && isPodcast && isPodcastTranscriptEnabled && isNavigableTranscript && (
          <div css={styles.module}>
            <TranscriptPanel />
          </div>
        )}

        {standaloneChapter && (
          <div css={styles.module}>
            <StandaloneChapterEpisodeCard
              standaloneChapter={standaloneChapter}
              containerCss={Mixins.horizontalModulePadding(true)}
            />
          </div>
        )}
        {description && (
          <div css={styles.module}>
            <About
              text={description}
              textCss={styles.text}
              containerCss={Mixins.horizontalModulePadding(true)}
            />
          </div>
        )}
        {/* TODO: [CCS-2922]: Remove standaloneChapter from the
            condition when product decides to include modules for standalone
            chapters */}
        {!standaloneChapter && (
          <ModuleRenderer
            request={{
              contentId,
              type: RequestType.ContentPage,
              viewId,
            }}
          />
        )}
      </div>
    </Suspense>
  );
};

export default InfoPanel;
