import { ClickFeature } from '@audacy-clients/client-services';
import { checkPlayingById, nowPlaying } from '@audacy-clients/core/atoms/player';
import { type TTranscript } from '@audacy-clients/core/atoms/transcripts';
import { isDefined } from '@audacy-clients/core/components/StateConnectors/utils';
import useTranscript from '@audacy-clients/core/hooks/useTranscript';
import useTranscriptDataEvents from '@audacy-clients/core/hooks/useTranscriptDataEvents';
import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { scrollableClassState } from '~/atoms/scrollClass';
import IconButton from '~/components/Button/IconButton';
import Error from '~/components/Error';
import { Icons } from '~/components/Icon/constants';
import Loading from '~/components/Loading';
import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';
import useBodyScrollLock, { allowTouchMoveBasedOnClass } from '~/hooks/use-body-scroll-lock';
import { useGetViewContextFn } from '~/state/dataEvents';
import { Colors } from '~/styles';
import ItemCell from './ItemCell';
import styles, { Constants } from './styles';

type TranscriptContentProps = {
  transcript: Array<TTranscript> | undefined;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  duration: number;
  offsetEqualToZero: boolean;
};

const scrollClass = 'transcriptScrollableContent';
const allowTouchMove = allowTouchMoveBasedOnClass(scrollClass);

const Transcript: React.FC<TranscriptContentProps> = ({
  transcript,
  currentIndex,
  setCurrentIndex,
  duration,
  offsetEqualToZero,
}) => {
  const audioHelpers = useAudioHelpersWeb();
  const playerItem = useRecoilValue(nowPlaying);
  const { id: contentId } = playerItem ?? {};
  const isPlaying = useRecoilValue(checkPlayingById({ contentId }));
  const getViewContext = useGetViewContextFn();
  const { sendTranscriptPlayClickEvent, getAnalyticPlayContext } =
    useTranscriptDataEvents(getViewContext);

  const [isAutoScrolling, setIsAutoScrolling] = useState(true);
  const transcriptListRef = useRef<Array<HTMLDivElement | null>>([]);
  const isAutoScrollingRef = useRef(false); // Ref to track programmatic scrolling
  const [beforeCurrentTimestamp, setBeforeCurrentTimestamp] = useState(false);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useBodyScrollLock(true, scrollContainerRef.current, allowTouchMove);

  const setScrollableClass = useSetRecoilState(scrollableClassState);

  useEffect(() => {
    setScrollableClass(scrollClass);
  }, [setScrollableClass]);

  // Function to scroll to the desired index
  const scrollToIndex = useCallback((index: number) => {
    if (!transcriptListRef.current?.[index]) {
      return;
    }

    // Set the ref to indicate the scroll is programmatic
    isAutoScrollingRef.current = true;
    transcriptListRef.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    // Reset the flag after a 1.3-second delay
    setTimeout(() => {
      isAutoScrollingRef.current = false;
    }, 1300); // 1.3-second delay
  }, []);

  const measureActiveItemPosition = useCallback(() => {
    const activeElement = transcriptListRef.current[currentIndex];
    const scrollContainer = scrollContainerRef.current;

    if (activeElement && scrollContainer) {
      const activeElementRect = activeElement.getBoundingClientRect();
      const scrollContainerRect = scrollContainer.getBoundingClientRect();

      const isBelow = activeElementRect.top >= scrollContainerRect.bottom;

      setBeforeCurrentTimestamp(isBelow);
    }
  }, [currentIndex]);

  // Function to handle user-initiated scrolling
  const handleUserScroll = useCallback(() => {
    // Only trigger the manual scroll logic if not auto-scrolling
    if (!isAutoScrollingRef.current) {
      setIsAutoScrolling(false); // Disable auto-scrolling if the user scrolls
    }
    measureActiveItemPosition(); // Measure position on user scroll
  }, [measureActiveItemPosition]);

  // Resume auto-scrolling when the user clicks the resume button
  const handleResumeAutoScroll = useCallback(() => {
    setIsAutoScrolling(true);
    scrollToIndex(currentIndex);
  }, [currentIndex, scrollToIndex]);

  // Automatically scroll to the current index when necessary
  useEffect(() => {
    if (currentIndex < 0 || !isAutoScrolling) {
      return;
    }
    scrollToIndex(currentIndex);
  }, [currentIndex, isAutoScrolling, scrollToIndex]);

  // Measure position when currentIndex changes and auto-scrolling is off
  useEffect(() => {
    if (!isAutoScrolling) {
      measureActiveItemPosition();
    }
  }, [currentIndex, isAutoScrolling, measureActiveItemPosition]);

  const playContext = useMemo(
    () => ({
      contentId,
      feature: ClickFeature.TRANSCRIPTION,
    }),
    [contentId],
  );

  const handleTranscriptPress = useCallback(
    (startTimeInMs: number, index: number) => () => {
      setIsAutoScrolling(true);
      setCurrentIndex(index);
      const offset = startTimeInMs / 1000;
      let progress = isDefined(duration) && isDefined(offset) ? offset / duration : undefined;

      if (progress && progress > 1) {
        progress = 1;
      }

      // Notice: uncomment it when back-end analytics will be ready [CCS-3436]
      sendTranscriptPlayClickEvent(getAnalyticPlayContext(playContext, transcript?.[index]));
      if (isPlaying) {
        return audioHelpers.seekToProgress(progress ?? 0);
      }
      if (offsetEqualToZero) {
        return audioHelpers.playFromId(
          contentId ?? '',
          { feature: ClickFeature.FULL_PLAYER },
          {
            autoPlay: true,
            startOffset: offset,
          },
        );
      }
      audioHelpers.seekToProgress(progress ?? 0);
      audioHelpers.play(undefined, {
        contentId,
        feature: ClickFeature.FULL_PLAYER,
      });
    },
    [
      duration,
      audioHelpers,
      isPlaying,
      contentId,
      offsetEqualToZero,
      setCurrentIndex,
      getAnalyticPlayContext,
      sendTranscriptPlayClickEvent,
      transcript,
      playContext,
    ],
  );

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  useEffect(() => {
    if (currentIndex < 0 || !isAutoScrolling) {
      return;
    }

    const currentItem = transcriptListRef.current[currentIndex];
    const scrollContainer = scrollContainerRef.current;

    if (currentItem && scrollContainer) {
      const itemOffsetTop = currentItem.offsetTop - scrollContainer.offsetTop;

      scrollContainer.scrollTo({
        top: itemOffsetTop,
        behavior: 'smooth',
      });
    }
  }, [currentIndex, isAutoScrolling]);

  // Prevent scroll events from propagating to outer containers
  const stopScrollPropagation = (e: React.WheelEvent<HTMLDivElement>) => {
    if (isHovering) {
      e.stopPropagation(); // Prevent parent scroll when hovering over `TranscriptContent`
    }
  };

  const renderItem = useCallback(
    (transcript: TTranscript, index: number) => {
      const { start_time, speaker, text } = transcript;
      const isActiveItem = index <= currentIndex && currentIndex >= Constants.noIndexFounded;

      return (
        <ItemCell
          ref={(el) => {
            if (el) {
              transcriptListRef.current[index] = el;
            }
          }}
          index={index}
          startTime={start_time}
          isActive={isActiveItem}
          speaker={speaker}
          text={text}
          handleTranscriptPress={handleTranscriptPress}
        />
      );
    },
    [handleTranscriptPress, currentIndex],
  );

  return (
    <div css={styles.container}>
      <div
        css={styles.scrollContainer}
        ref={scrollContainerRef}
        onScroll={handleUserScroll}
        onWheel={stopScrollPropagation} // Prevent scroll event propagation
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {transcript?.map((item, index) => (
          <Fragment key={`${item.start_time}-${index}`}>{renderItem(item, index)}</Fragment>
        ))}
        {!isAutoScrolling && (
          <div css={styles.resumeButtonContainer}>
            <IconButton
              icon={beforeCurrentTimestamp ? Icons.CaretDown : Icons.CaretUp}
              onClick={handleResumeAutoScroll}
              buttonCss={styles.resumeButton}
              color={Colors.black}
              buttonSizeInPx={40}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const MemoizedTranscriptContent = memo(Transcript);

const TranscriptContent = () => {
  const {
    transcript,
    currentIndex,
    setCurrentIndex,
    duration,
    offsetEqualToZero,
    loading,
    hasError,
  } = useTranscript();
  const getViewContext = useGetViewContextFn();
  const { sendTranscriptScreenViewEvent } = useTranscriptDataEvents(getViewContext);

  useEffect(() => {
    sendTranscriptScreenViewEvent();
  }, [sendTranscriptScreenViewEvent]);

  if (loading) {
    return <div css={styles.loadContainer}>{loading && <Loading />}</div>;
  }
  if (hasError) {
    return <div css={styles.loadContainer}>{hasError && <Error />}</div>;
  }

  return (
    <MemoizedTranscriptContent
      transcript={transcript}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      duration={duration}
      offsetEqualToZero={offsetEqualToZero}
    />
  );
};

export default TranscriptContent;
