import { assign, createMachine } from 'xstate';

import {
  EntityType,
  StationSubType,
  PlayerAction,
  StreamProvider,
  PlayerMetric,
} from '../../Constants';
import Station from '../../dataServices/Station';
import { handleTimeoutAction } from '../../utils';
import { TStreamerMachineEvent, EContentType, IFailure } from '../types';
import {
  AUTO_TRANSITION,
  COMMON_ACTIONS,
  LOADING_TIMEOUT,
  SILENCE_TIMEOUT,
  MAX_RETRY_ATTEMPTS,
} from './constants';
import { mergeMetadata } from './RewindStationMachine/sharedUtils';
import { IStreamerMachineContext, TStreamSelector } from './types';
import {
  DEFAULT_PLAYER_ACTIONS,
  appendSuperHifiParameters,
  getExponentialBackoffDelay,
  getFirstAudioSourceUrl,
} from './utils';

interface ISuperHifiMachineContext extends IStreamerMachineContext<Station> {
  hifiResumePoint: number;
  streamUrl: string;
  shouldPauseAfterSkip: boolean;
}

const superHifiMachine = createMachine(
  {
    predictableActionArguments: true,
    id: 'superHifiMachine',
    initial: 'HIFIINIT',
    tsTypes: {} as import('./superHifiMachine.typegen').Typegen0,
    schema: {
      context: {} as ISuperHifiMachineContext,
      events: {} as TStreamerMachineEvent,
    },
    states: {
      HIFIINIT: {
        entry: ['assignDefaults'],
        after: {
          [0]: {
            actions: ['assignStreamUrl'],
            target: 'LOADING',
          },
        },
      },
      LOADING: {
        entry: ['loadAudio'],
        on: {
          REPORT_LOADED: {
            target: 'LOADED',
          },
          REPORT_FAILURE: {
            target: '#failureWithRetry',
            actions: 'logPlaybackFailure',
          },
          REPORT_METADATA: {
            actions: ['assignMetadata'],
          },
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          ...COMMON_ACTIONS,
        },
        after: {
          [LOADING_TIMEOUT]: {
            target: '#failureWithRetry',
            actions: handleTimeoutAction('LOADING', 'superHifiMachine'),
          },
        },
      },
      LOADED: {
        entry: 'autoplay',
        on: {
          PLAY: {
            target: 'PLAY_REQUESTED',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          ...COMMON_ACTIONS,
        },
      },
      PLAY_REQUESTED: {
        entry: 'playSuperHiFi',
        on: {
          REPORT_FAILURE: {
            actions: ['logPlaybackError', 'sendPlaybackErrorEvent', 'assignTrackError'],
            target: '#failureWithRetry',
          },
          REPORT_PLAYING: {
            actions: ['sendPlayEvent', 'addToHistory'],
            target: 'PLAYING',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          REPORT_AUTOPLAY_POLICY: {
            target: 'PAUSED',
          },
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          ...COMMON_ACTIONS,
        },
        after: {
          [LOADING_TIMEOUT]: {
            target: '#failureWithRetry',
            actions: handleTimeoutAction('LOADING', 'superHifiMachine'),
          },
        },
      },
      PLAYING: {
        initial: 'IS_PLAYING',
        entry: 'assignRetryCountToZero',
        states: {
          IS_PLAYING: {
            on: {
              REPORT_FAILURE: {
                target: 'IS_ERROR',
                actions: ['logPlaybackError', 'sendPlaybackErrorEvent'],
              },
              REPORT_TIME_UPDATE: {
                target: 'IS_PLAYING',
                actions: ['assignTime'],
              },
            },
            after: [
              {
                delay: SILENCE_TIMEOUT,
                target: '#failureWithRetry',
              },
            ],
          },
          IS_ERROR: {
            entry: ['logPlaybackError', 'sendPlaybackErrorEvent', 'assignTrackError'],
            on: {
              REPORT_FAILURE: {
                target: 'IS_FATAL_ERROR',
              },
              REPORT_TIME_UPDATE: {
                target: 'IS_PLAYING',
                actions: ['assignTime'],
              },
            },
            after: [
              {
                delay: SILENCE_TIMEOUT,
                target: 'IS_FATAL_ERROR',
              },
            ],
          },
          IS_FATAL_ERROR: {
            entry: 'logPlaybackFailure',
            after: [
              {
                delay: AUTO_TRANSITION,
                target: '#failureWithRetry',
              },
            ],
          },
        },
        on: {
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          REPORT_METADATA: [
            {
              cond: ({ shouldPauseAfterSkip }) => shouldPauseAfterSkip,
              actions: [
                assign({
                  shouldPauseAfterSkip: false,
                }),
                'assignMetadata',
              ],
              target: 'PAUSE_REQUESTED',
            },
            {
              actions: ['sendSuperHifiSongListenedEvent', 'assignMetadata'],
            },
          ],
          REPORT_PAUSED: {
            target: 'PAUSED',
          },
          SKIP: {
            target: 'SKIP_REQUESTED',
          },
          SEND_PREFERENCE: {
            actions: 'sendPreference',
          },
          ...COMMON_ACTIONS,
        },
      },
      SKIP_REQUESTED: {
        entry: 'assignSkipUrl',
        after: {
          [0]: {
            target: 'LOADING',
          },
        },
        on: {
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          ...COMMON_ACTIONS,
        },
      },
      PAUSE_REQUESTED: {
        entry: ['assignHiFiResumePoint', 'stop', 'sendStopEvent', 'assignRetryCountToZero'],
        on: {
          ...COMMON_ACTIONS,
          REPORT_PAUSED: {
            target: 'PAUSED',
          },
          REPORT_TIME_UPDATE: {
            actions: 'assignTime',
          },
        },
        after: {
          [LOADING_TIMEOUT]: {
            target: 'FAILURE',
          },
        },
      },
      PAUSED: {
        on: {
          PLAY: {
            actions: ['assignResumeUrl'],
            target: 'LOADING',
          },
          SKIP: {
            target: 'SKIP_REQUESTED',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          REPORT_PLAYING: {
            target: 'PLAYING',
          },
          ...COMMON_ACTIONS,
        },
      },
      FAILURE: {
        id: 'failure',
        on: COMMON_ACTIONS,
        entry: ['stop', 'sendPlaybackFailedEvent'],
        after: [{ delay: 500, target: 'PAUSED' }],
      },
      FAILURE_WITH_RETRY: {
        id: 'failureWithRetry',
        entry: [
          'assignHiFiResumePoint',
          'assignResumeUrl',
          'assignIncrementRetryCount',
          'logRetry',
        ],
        on: {
          PLAY: {
            target: 'LOADING',
          },
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          ...COMMON_ACTIONS,
        },
        after: [
          {
            target: 'FAILURE',
            delay: (context) => getExponentialBackoffDelay(context.retryCount),
            cond: ({ retryCount = 0 }) => retryCount >= MAX_RETRY_ATTEMPTS,
          },
          {
            target: 'LOADING',
            delay: (context) => getExponentialBackoffDelay(context.retryCount),
            cond: ({ retryCount = 0 }) => retryCount < MAX_RETRY_ATTEMPTS,
          },
        ],
      },
      DESTROYED: {
        entry: ['stop', 'sendStopEvent'],
        type: 'final',
      },
    },
  },
  {
    actions: {
      sendSuperHifiSongListenedEvent: (ctx, e) => {
        const existingMetadata = ctx.metadata;
        const newMetadata = e.metadata;
        const isNewSong =
          existingMetadata && existingMetadata?.songOrShow !== newMetadata.songOrShow;
        const isContent = existingMetadata?.contentType === 'content';
        const audioRoute = ctx.deviceInfoProvider?.getAudioRoute();

        if (existingMetadata && newMetadata && isNewSong && isContent) {
          ctx.analyticsProvider.sendPlayerEvent({
            streamUrl: getFirstAudioSourceUrl(ctx.item.data.streamUrl),
            type: PlayerAction.SONG_LISTENED,
            contentId: ctx.item.data.id,
            connectionType: audioRoute
          });
        }
      },
      addToHistory: (ctx) => {
        const id = ctx.item.getId();
        ctx.personalizationProvider.addToHistory([id]);
      },
      assignDefaults: assign(({ item, metadata, firstLoadTime, analyticsProvider }) => {
        if (!metadata) {
          // Initial load
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_LOAD,
            eventDetails: {
              stationId: item.getId(),
              streamerType: EContentType.ExclusiveStation,
            },
          });
        }
        return {
          contentType: EContentType.ExclusiveStation,
          firstLoadTime: firstLoadTime || Date.now(),
          metadata: metadata ?? {
            image: item.getImageSquare(),
            station: item.getTitle(),
          },
        };
      }),
      assignRetryCountToZero: assign({
        retryCount: 0,
      }),
      assignIncrementRetryCount: assign({
        retryCount: ({ retryCount = 0 }) => {
          return retryCount + 1;
        },
      }),
      assignStreamUrl: assign({
        hifiResumePoint: () => 0,
        streamUrl: ({ credentialsProvider, item, marketingDataProvider, platform }, _) => {
          const stationStreamUrl = item.data.streamUrl.m3u8;
          const uri = appendSuperHifiParameters(stationStreamUrl, {
            action: 'load',
            continuity: 0,
            platform,
            marketingDataProvider,
            credentialsProvider,
          });

          return uri.normalize().valueOf();
        },
      }),
      assignResumeUrl: assign({
        streamUrl: (
          { streamUrl, hifiResumePoint, credentialsProvider, marketingDataProvider, platform },
          _,
        ) => {
          const uri = appendSuperHifiParameters(streamUrl, {
            action: 'refresh',
            continuity: hifiResumePoint,
            platform,
            marketingDataProvider,
            credentialsProvider,
          });
          return uri.normalize().valueOf();
        },
      }),
      assignHiFiResumePoint: assign({
        hifiResumePoint: ({ metadata }) => {
          const { continuity } = metadata ?? {};
          return continuity == undefined ? 0 : continuity;
        },
      }),
      assignSkipUrl: assign({
        streamUrl: ({ streamUrl, credentialsProvider, platform, marketingDataProvider }, _) => {
          const uri = appendSuperHifiParameters(streamUrl, {
            action: 'skip',
            continuity: 0,
            platform,
            marketingDataProvider,
            credentialsProvider,
          });
          return uri.normalize().valueOf();
        },
      }),
      assignTrackError: assign(({ errors }, event) => {
        const { error, errorMessage } = (event as IFailure) || {};
        const extractedError = error ? error.raw || error : errorMessage;

        return {
          errors: [
            // Save the 5 most recent errors to send to Datadog
            { errorMessage, context: extractedError },
            ...(errors || []),
          ].slice(0, 5),
        };
      }),
      loadAudio: ({ player, streamUrl, autoplay }) => {
        player?.load({
          url: streamUrl,
          isPodcast: false,
          isSuperHifi: true,
          isAudioPreroll: false,
          liveContentUrl: '',
          autoplay,
        });
      },
      assignMetadata: assign((ctx, event) => {
        const { metadata: existingMetadata, item, logger } = ctx;
        const retVal: Partial<ISuperHifiMachineContext> = {};

        const meta = 'metadata' in event ? event.metadata : {};

        retVal.metadata = mergeMetadata({
          existingMetadata,
          newMetadata: meta,
          logger,
          item,
        });
        return retVal;
      }),
      assignTime: assign({
        elapsed: (_, { time }) => {
          return time;
        },
      }),
      sendPlayEvent: ({
        analyticsProvider,
        item,
        firstLoadTime,
        retryCount,
        errors,
        contentType,
        deviceInfoProvider
      }) => {
        if (!item.data.id) {
          return;
        }
        const audioRoute = deviceInfoProvider?.getAudioRoute()

        analyticsProvider.sendPlayerEvent({
          type: PlayerAction.PLAY,
          contentId: item.data.id,
          streamUrl: getFirstAudioSourceUrl(item.data.streamUrl),
          connectionType: audioRoute
        });

        analyticsProvider.sendEventToListener({
          type: PlayerMetric.STREAM_PLAY,
          eventDetails: {
            stationId: item.data.id,
            streamerType: contentType,
            uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
            retryCount,
          },
        });
        if (retryCount) {
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_RECONNECT_SUCCESSFUL,
            eventDetails: {
              stationId: item.data.id,
              streamerType: contentType,
              uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
              retryCount,
              mostRecentError: errors?.[0],
            },
          });
        }
      },
      sendStopEvent: ({
        analyticsProvider,
        item,
        firstLoadTime,
        retryCount,
        errors,
        contentType,
        deviceInfoProvider
      }) => {
        if (!item.data.id) {
          return;
        }

        const audioRoute = deviceInfoProvider?.getAudioRoute()

        analyticsProvider.sendPlayerEvent({
          type: PlayerAction.STOP,
          contentId: item.data.id,
          streamUrl: getFirstAudioSourceUrl(item.data.streamUrl),
          connectionType: audioRoute
        });

        analyticsProvider.sendEventToListener({
          type: PlayerMetric.STREAM_PAUSE,
          eventDetails: {
            stationId: item.data.id,
            streamerType: contentType,
            uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
            retryCount,
          },
        });
        if (retryCount) {
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_RECONNECT_ABORTED,
            eventDetails: {
              stationId: item.data.id,
              streamerType: contentType,
              uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
              retryCount,
              mostRecentError: errors?.[0],
            },
          });
        }
      },
      ...DEFAULT_PLAYER_ACTIONS,
    },
  },
);

// Selects the SHF streamer if an exclusive station has SUPERHIFI as streamProviderName
const selector: TStreamSelector<typeof superHifiMachine> = async (dataObject) => {
  if (
    dataObject instanceof Station &&
    dataObject.getEntityType() === EntityType.STATION &&
    dataObject.getEntitySubtype() === StationSubType.EXCLUSIVE &&
    dataObject.getStreamProvider() === StreamProvider.SUPERHIFI
  ) {
    return superHifiMachine.withContext({
      ...superHifiMachine.context,
      item: dataObject,
      shouldPauseAfterSkip: false,
    });
  }
};

export default selector;
