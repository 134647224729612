import { ModuleType } from '@audacy-clients/client-services/core';
import { getSchedules } from '@audacy-clients/core/atoms/schedules';
import { type IModuleViewComponent, wrapModule } from '@audacy-clients/core/atoms/wrappers/modules';
import { type IEpisode } from '@audacy-clients/core/atoms/wrappers/types';
import { type SerializableParam, useRecoilValueLoadable } from 'recoil';

import { RefreshTimes, nullResponse } from './helpers/constants';
import { refreshableSelectorFamily } from './helpers/refreshable';
import { wrapEpisode } from './wrappers/episode';

type IEarlierTodayParams = {
  stationId?: string;
};

type TEarlierTodayModule = Omit<IModuleViewComponent, 'getContent'> & {
  getContent(id?: string): IEpisode | undefined;
};

type TEarlierTodayReturn = {
  earlierToday: TEarlierTodayModule | undefined;
  isLoading: boolean;
};

export const { useWrappedCachedValue: useCachedEarlierToday, selector: earlierTodayState } =
  refreshableSelectorFamily<TEarlierTodayModule, IEarlierTodayParams & SerializableParam>({
    get:
      ({ stationId }) =>
      ({ get }) => {
        if (!stationId) {
          return nullResponse;
        }

        const episodeList = get(getSchedules(stationId));

        if (!episodeList) {
          return nullResponse;
        }

        const endDateTime = Date.now();
        const startDateTime = new Date().setHours(0, 0, 0, 0);

        const earlierTodayEpisodes = episodeList
          .getAll()
          .filter(
            (episode) =>
              episode.isReplayable &&
              episode.startTime >= startDateTime &&
              episode.endTime < endDateTime,
          );

        // TODO: [BOOST-5763] When no episodes present, get the last four episodes from yesterday
        if (earlierTodayEpisodes.length === 0) {
          return nullResponse;
        }

        const content: Record<string, IEpisode> = {};

        const carouselModules = earlierTodayEpisodes.map((episode) => {
          content[episode.id] = wrapEpisode(episode.getDataObject());

          return {
            config: { contentId: episode.id },
            moduleId: `CLIENT--ENTITY_CARD_HORIZONTAL--EARLIER_TODAY--${episode.id}`,
            moduleType: ModuleType.ENTITY_CARD_HORIZONTAL,
          };
        });

        const module = wrapModule({
          moduleId: 'CLIENT--SECTION_WRAPPER--EARLIER_TODAY',
          moduleType: ModuleType.SECTION_WRAPPER,
          modules: [
            {
              config: { itemsPerColumn: 3 },
              moduleId: 'CLIENT--CAROUSEL--EARLIER_TODAY',
              moduleType: ModuleType.CAROUSEL,
              modules: carouselModules,
            },
          ],
        });

        return { getContent: (id) => (id ? content[id] : undefined), module };
      },
    key: 'EarlierToday',
    refreshEverySeconds: RefreshTimes.every30s,
  });

export const useEarlierToday = ({ stationId }: IEarlierTodayParams): TEarlierTodayReturn => {
  const earlierTodayLoadable = useRecoilValueLoadable(earlierTodayState({ stationId }));

  const earlierToday = earlierTodayLoadable.valueMaybe();
  const state = earlierTodayLoadable.state;

  return { earlierToday, isLoading: state === 'loading' };
};
