/* eslint-disable @typescript-eslint/ban-ts-comment */
import Player from 'video.js/dist/types/player';

import silentMP4 from '../assets/500ms-silence.mp4';
import clientServicesConfig from '../Config';
import { VideoAdConstants } from '../Constants';
import DataServices from '../dataServices/DataServices';
import { stripSpacesAndUncaptialize } from '../Utilities';
import { getGppString } from '../utils/gpp-util';

const { IMA3_LIBRARY_URL, VIDEO_URL, AD_LOAD_TIMEOUT, VIDEO_AD_COOLDOWN_MS } = VideoAdConstants;

// ima comes from videojs-ima, it is untyped
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type VideoJS = (el: HTMLElement) => Player & { ima: any };

declare global {
  // google comes from the IMA3_LIBRARY_URL, it is untyped
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const google: any;
}

let videojsPromise = null as unknown as Promise<VideoJS>;

/**
 * Loads the VideoJS JS and CSS resources on demand
 */
const requireVideojs = () => {
  if (!videojsPromise) {
    videojsPromise = Promise.all([
      import('video.js'),
      //@ts-ignore
      import('videojs-contrib-ads'),
      //@ts-ignore
      import('videojs-ima'),
      import('video.js/dist/video-js.min.css'),
      import('videojs-contrib-ads/dist/videojs.ads.css'),
      import('videojs-ima/dist/videojs.ima.css'),
      DataServices.loadScript(IMA3_LIBRARY_URL),
    ]).then(([videojs]) => {
      return videojs.default as unknown as VideoJS;
    });
  }

  return videojsPromise;
};

// Preload VideoJS on page load
if (typeof document !== 'undefined') {
  requireVideojs();
}

/**
 * Creates the video-js element
 */
const createVideoPlayer = async (parentElement: HTMLElement, aspectRatio: string) => {
  const videojs = await requireVideojs();

  const videoEl = document.createElement('video-js');

  parentElement.appendChild(videoEl);

  const player = videojs(videoEl);

  // Responsiveness
  player.fluid(true);
  player.aspectRatio(aspectRatio);

  // Play without fullscreen on iOS Safari
  player.playsinline(true);

  return player;
};

/**
 * Gets the video ad url which is not an MP4 but a VAST XML file
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getVideoAdUrl = (dataObj: any): string => {
  const adTargets = {
    station: stripSpacesAndUncaptialize(dataObj.data.callsign),
    market: stripSpacesAndUncaptialize(dataObj.data.marketTitle),
    category: stripSpacesAndUncaptialize(dataObj.data.category),
    genre: stripSpacesAndUncaptialize(dataObj.data.genres[0]),
  };

  let custParams = encodeURI(
    `&tag=livestreamplayer,${adTargets.station},${adTargets.market},${adTargets.genre}&player=livestreamplayer` +
      `&station=${adTargets.station}&market=${adTargets.market}&genre=${adTargets.genre}`,
  );

  // Add GPP query parameter
  custParams += `&gpp=${getGppString({
    optIn: !clientServicesConfig.disableTargetedAds,
  })}`;

  const currentUrl = VIDEO_URL.replace('[timestamp]', Date.now().toString())
    .replace('[description_url]', window.location.href)
    .replace('[referrer_url]', window.location.href)
    .replace('[doubleclick_prerolltag]', dataObj.data.doubleclickPrerolltag || 'NTL.RADIO')
    .replace('[cust_params]', custParams);
  return currentUrl;
};

// Last played timestamp to cooldown ads
let lastPlayedAtMs = 0;

type PlayVideoAdConfig = {
  containerId?: string;
  eventListener?: (type: 'VIDEO_AD_LOAD' | 'VIDEO_AD_START' | 'VIDEO_AD_END') => void;
  dataObj?: unknown;
};

/**
 * Quick and easy method to use video pre-roll ads on web. All of the
 * helper methods are exposed if a custom implementation is required.
 */
export const playVideoAd = async (config: PlayVideoAdConfig = {}): Promise<void> => {
  const { logger } = clientServicesConfig;

  // No-op, not implemented for mobile
  if (typeof document === 'undefined') {
    return;
  }

  // Don't play ad if already played recently
  if (lastPlayedAtMs + VIDEO_AD_COOLDOWN_MS > Date.now()) {
    return;
  }

  // AdBlock prevents resource from downloading, so skip ad
  try {
    await requireVideojs();
  } catch (e) {
    return;
  }

  // Allow the app to render the MiniPlayer with videoAdContainer after videoAdLoad hook
  // You can also set this timeout to 1000 or so to reliably trigger iOS Safari permission issues
  config.eventListener?.('VIDEO_AD_LOAD');
  await new Promise<void>((resolve) => setTimeout(() => resolve(), 0));

  // Get video ad container div
  const containerId = config.containerId || 'videoAdContainer';
  const container = document.getElementById(containerId);

  // Skip to content by resolving a promise
  let skipToContent = null as unknown as (error?: unknown) => void;

  let isResolved = false;

  const url = new URL(VIDEO_URL);
  const params = new URLSearchParams(url.search);
  const [width, height] = params.get('sz')?.split('|')[0].split('x') as string[];

  const promise = new Promise<void>((resolve) => {
    skipToContent = (error?: unknown) => {
      if (!isResolved) {
        // Log if caused by error
        if (error) {
          logger?.error(error);
        }

        // Clean up player and skip to content
        clearTimeout(adTimeout);

        // We need a fallback way to dispose the player in addition to the
        // other player.dispose call, to remove the black box that is
        // left behind after the ad. Timeout is 5000ms to wait for the
        // other dispose call but to dispose before we get another ad.
        setTimeout(() => player?.dispose(), 5000);

        if (container) {
          container.style.opacity = '0';
          container.style.pointerEvents = 'none';
        }
        config.eventListener?.('VIDEO_AD_END');

        // Resolve the promise
        isResolved = true;
        resolve();
      }
    };
  });

  // Get video ad container div
  if (!container) {
    logger?.warn(`Failed to play video ad: no container #${containerId}`);
    return skipToContent();
  }

  const player = await createVideoPlayer(container, `${width}:${height}`);

  // This is a catch-all to skip the ad unless we receive a STARTED event within a few seconds
  const adTimeout = setTimeout(() => {
    logger?.warn('IMA ad did not start in time, skipping to content.');
    skipToContent();
  }, AD_LOAD_TIMEOUT);

  // Try to play an ad
  try {
    // videojs-ima requires a video to be loaded before we try to play an ad
    player.src({
      src: silentMP4,
      type: 'video/mp4',
    });

    // ads-loader event is undocumented and returns a IMA SDK AdsLoader which also isn't fully documented
    // Search this source code for ads-loader: https://github.com/googleads/videojs-ima/blob/bb3a8e0eb0f65f7c5d3958f514f36dffda54b4b3/dist/videojs.ima.es.js
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    player.on('ads-loader', ({ adsLoader }: any) => {
      adsLoader.addEventListener(
        google.ima.AdErrorEvent.Type.AD_ERROR,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          const { errorCode, errorMessage } = error?.error?.data || {};
          const msg = errorCode ? `${errorCode} ${errorMessage}` : '';

          if (msg.includes('1009')) {
            logger?.error(`IMA load fatal error, skipping to content: ${msg}`);
            skipToContent();
          } else {
            logger?.warn('IMA load possible fatal error: ', msg || error);
          }
        },
      );
    });

    // Load the ad
    player.ima({
      // See https://github.com/googleads/videojs-ima
      adTagUrl: getVideoAdUrl(config.dataObj),
      vastLoadTimeout: 3500, // Default is 5000 ms
      adsRenderingSettings: {
        // See https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdsRenderingSettings
        loadVideoTimeout: AD_LOAD_TIMEOUT, // Default is 8000 ms
        bitrate: 1024,
      },
      // adsManagerLoadedCallback is not documented but is part of videojs-ima
      adsManagerLoadedCallback: () => {
        // See https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdEvent
        // Cancel skipping the ad
        player.ima.addEventListener(google.ima.AdEvent.Type.STARTED, () => {
          // We need to dispose here because there's a bug in videojs-ima
          // where if we dispose before the ad starts, the ad might still play.
          // We also need a setTimeout to let videojs-ima finish setting up
          // Because event at this point dispose doesn't work!
          if (isResolved) {
            setTimeout(() => player?.dispose(), 0);
            return;
          }
          container.style.opacity = '1';
          container.style.pointerEvents = 'auto';
          container.style.width = `${width}px`;
          container.style.height = `${height}px`;
          container.style.aspectRatio = `${width} / ${height}`;

          clearTimeout(adTimeout);
          config.eventListener?.('VIDEO_AD_START');
        });
        // adserror is sometimes but not always fatal, skip to content early if fatal
        // adserror is documented here https://www.npmjs.com/package/videojs-contrib-ads/v/4.2.7
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        player.on('adserror', (error: any) => {
          // innerError can be a string or an object or undefined
          // eslint-disable-next-line prettier/prettier
          const msg = JSON.stringify(error?.data?.AdError?.data?.innerError) || '';

          // For 301 and 303, see https://support.google.com/authorizedbuyers/answer/9032019
          // 1205 is the iOS Safari 'playback initiated without user interaction' error
          if (msg.includes('301') || msg.includes('303') || msg.includes('1205')) {
            logger?.error(`IMA ad fatal error, skipping to content: ${msg}`);
            skipToContent();
          } else {
            logger?.warn('IMA ad possible fatal error: ', msg || error);
          }
        });
        // Skip to content once ads are done
        player.ima.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, () =>
          skipToContent(),
        );
      },
    });

    // Play the ad
    player.play()?.catch((error) => {
      const msg = error.name ? `${error.name}: ${error.message}` : '';

      // NotAllowedError is another iOS Safari error 'possibly because the user denied permission'
      if (msg.includes('NotAllowedError')) {
        logger?.error(`IMA play fatal error, skipping to content: ${msg}`);
        skipToContent();
      } else {
        logger?.warn('IMA play possible fatal error: ', msg || error);
      }
    });
  } catch (error) {
    // Catch any fatal errors and skip to content
    skipToContent(error);
  }

  lastPlayedAtMs = Date.now();

  // Resolve once ad is finished
  await promise;
};
