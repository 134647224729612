import axios from 'axios';
import URI from 'urijs';
import { assign, createMachine } from 'xstate';
import { choose } from 'xstate/lib/actions';

import clientServicesConfig from '../../Config';
import {
  EntityType,
  Preference,
  StationSubType,
  PlayerAction,
  SaveResumePointTimings,
  StreamProvider,
} from '../../Constants';
import { ICredentialsProvider } from '../../Container';
import { PlaybacksHashMap } from '../../dataServices/DataServices';
import Station from '../../dataServices/Station';
import { handleErrorAction, handleTimeoutAction } from '../../utils';
import { getGppString } from '../../utils/gpp-util';
import { IPlayerMetadata } from '../players/types';
import { TStreamerMachineEvent, EContentType, IPersonalizationProvider } from '../types';
import {
  AUTO_TRANSITION,
  COMMON_ACTIONS,
  LOADING_TIMEOUT,
  MAX_RETRY_ATTEMPTS,
  SILENCE_TIMEOUT,
} from './constants';
import { mergeMetadata } from './RewindStationMachine/sharedUtils';
import { IStreamerMachineContext, TStreamSelector } from './types';
import { DEFAULT_PLAYER_ACTIONS, getExponentialBackoffDelay, getFirstAudioSourceUrl } from './utils';

// Test station slugs, e.g. /stations/the80s, on STG backend:
// the80s, princeandfriends, etc.

interface IExclusiveStationClientCallParams {
  playId?: string;
  stationId?: string;
  trackId?: string;
}

interface IExclusiveStationClient {
  getStreamInfo: () => Promise<[string, PlaybacksHashMap]>;
  skip(params: IExclusiveStationClientCallParams): Promise<string>;
  sendPreference(
    params: IExclusiveStationClientCallParams,
    preference: Preference,
  ): Promise<unknown>;
}

// TODO: [CCS-2784] ensure that all assigns are cleaned up

const { RESUME_POINT_INTERVAL_MSEC } = SaveResumePointTimings;

const saveResumePoints = (context: IStreamerMachineContext<Station>, shouldSaveOnServer = true) => {
  const { personalizationProvider, item, metadata, player } = context;
  const { duration, datumTime, segmentStartTime } = metadata ?? {};

  if (duration == undefined || datumTime == undefined || segmentStartTime == undefined) {
    return;
  }

  const correctedTime = datumTime + player.getCurrentTime();

  personalizationProvider.setPlayback(item.getId(), correctedTime, shouldSaveOnServer);
};

const createClient = (
  station: Station,
  personalizationProvider: IPersonalizationProvider,
  credentialsProvider?: ICredentialsProvider,
): IExclusiveStationClient => {
  if (!credentialsProvider?.userToken) {
    throw new Error(
      'Credentials provider with user token is required to play an exclusive station',
    );
  }

  const hlsUri = new URI(station.getHlsStream());
  const baseURL = new URI({
    protocol: hlsUri.protocol(),
    hostname: hlsUri.hostname(),
  })
    .normalize()
    .valueOf();

  const client = axios.create({
    baseURL,
  });

  const getPlayId = (): Promise<string> => {
    return client
      .get(`/session/playId/${credentialsProvider?.userToken}`)
      .then((response) => response.data?.playId);
  };

  return {
    getStreamInfo: () => Promise.all([getPlayId(), personalizationProvider.getPlaybacks()]),
    skip: ({ playId, stationId, trackId }) =>
      client
        .get(`/session/${playId}/${stationId}/skip`, {
          params: {
            trackId,
          },
        })
        .then((response) => response.data?.newPlaybackUrl),
    sendPreference: ({ playId, stationId, trackId }, preference) =>
      client.get(`/session/${playId}/${stationId}/feedback/${preference}`, {
        params: {
          trackId,
        },
      }),
  };
};

interface IExclusiveStationMachineContext extends IStreamerMachineContext<Station> {
  client: IExclusiveStationClient;
  playId?: string;
  streamUrl: string;
  shouldPauseAfterSkip: boolean;
}

type TExclusiveMachineServices = {
  getPlayId: {
    data: string;
  };
  getStreamInfo: {
    data: [string, PlaybacksHashMap];
  };
  skip: {
    data: string;
  };
};

const exclusiveStationMachine = createMachine(
  {
    predictableActionArguments: true,
    id: 'exclusiveStationMachine',
    initial: 'LOADING',
    tsTypes: {} as import('./ExclusiveStationMachine.typegen').Typegen0,
    schema: {
      context: {} as IExclusiveStationMachineContext,
      events: {} as TStreamerMachineEvent,
      services: {} as TExclusiveMachineServices,
    },
    states: {
      LOADING: {
        entry: ['assignDefaults'],
        invoke: {
          id: 'getStreamInfo',
          src: 'getStreamInfo',
          onDone: {
            actions: ['assignStreamUrl', 'loadAudio'],
          },
          onError: {
            target: '#failureWithRetry',
            actions: handleErrorAction('LOADING', 'exclusiveStationMachine'),
          },
        },
        on: {
          REPORT_LOADED: {
            target: 'LOADED',
          },
          REPORT_FAILURE: {
            actions: 'logPlaybackFailure',
            target: '#failureWithRetry',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          ...COMMON_ACTIONS,
        },
        after: {
          [LOADING_TIMEOUT]: {
            target: '#failureWithRetry',
            actions: handleTimeoutAction('LOADING', 'exclusiveStationMachine'),
          },
        },
      },
      LOADED: {
        entry: 'autoplay',
        on: {
          PLAY: {
            target: 'PLAY_REQUESTED',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          ...COMMON_ACTIONS,
        },
      },
      PLAY_REQUESTED: {
        entry: 'play',
        on: {
          REPORT_PLAYING: {
            actions: ['sendPlayEvent', 'addToHistory'],
            target: 'PLAYING',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          REPORT_AUTOPLAY_POLICY: {
            target: 'PAUSED',
          },
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          ...COMMON_ACTIONS,
        },
        after: {
          [LOADING_TIMEOUT]: {
            target: '#failureWithRetry',
          },
        },
      },
      PLAYING: {
        initial: 'IS_PLAYING',
        entry: 'assignRetryCountToZero',
        after: {
          [RESUME_POINT_INTERVAL_MSEC]: {
            target: 'PLAYING',
            actions: 'saveLocalResumePoint',
          },
        },
        states: {
          IS_PLAYING: {
            on: {
              REPORT_FAILURE: {
                actions: ['logPlaybackError', 'sendPlaybackErrorEvent'],
                target: '#failureWithRetry',
              },
              REPORT_TIME_UPDATE: {
                target: 'IS_PLAYING',
                actions: choose([
                  {
                    cond: 'canAssignTime',
                    actions: ['assignTime'],
                  },
                ]),
              },
            },
            after: [
              {
                delay: SILENCE_TIMEOUT,
                target: '#failureWithRetry',
              },
            ],
          },
          IS_ERROR: {
            entry: 'logPlaybackError',
            on: {
              REPORT_FAILURE: {
                actions: ['logPlaybackError', 'sendPlaybackErrorEvent'],
              },
              REPORT_TIME_UPDATE: {
                target: 'IS_PLAYING',
                actions: choose([
                  {
                    cond: 'canAssignTime',
                    actions: ['assignTime'],
                  },
                ]),
              },
            },
            after: [
              {
                delay: SILENCE_TIMEOUT,
                target: '#failureWithRetry',
              },
            ],
          },
          IS_FATAL_ERROR: {
            entry: 'logPlaybackFailure',
            after: [
              {
                delay: AUTO_TRANSITION,
                target: '#failureWithRetry',
              },
            ],
          },
        },
        on: {
          PAUSE: {
            target: 'PAUSE_REQUESTED',
          },
          REPORT_METADATA: [
            {
              cond: ({ shouldPauseAfterSkip }) => shouldPauseAfterSkip,
              actions: [
                assign({
                  shouldPauseAfterSkip: false,
                }),
                'assignMetadata',
              ],
              target: 'PAUSE_REQUESTED',
            },
            {
              actions: ['assignMetadata', 'updateSkipsCount'],
            },
          ],
          REPORT_PAUSED: {
            target: 'PAUSED',
          },
          SKIP: {
            target: 'SKIP_REQUESTED',
          },
          SEND_PREFERENCE: {
            actions: 'sendPreference',
          },
          STOP: {
            actions: ['stop', 'sendStopEvent', 'saveResumePoint'],
          },
          ...COMMON_ACTIONS,
        },
      },
      SKIP_REQUESTED: {
        entry: 'stop',
        invoke: {
          src: 'skip',
          id: 'skip',
          onDone: {
            actions: ['handleSkip', 'loadAudio'],
            target: 'PLAY_REQUESTED',
          },
          onError: {
            target: 'FAILURE',
            actions: handleErrorAction('SKIP_REQUESTED', 'exclusiveStationMachine'),
          },
        },
        after: {
          [LOADING_TIMEOUT]: {
            target: 'FAILURE',
            actions: handleTimeoutAction('SKIP_REQUESTED', 'exclusiveStationMachine'),
          },
        },
        on: COMMON_ACTIONS,
      },
      PAUSE_REQUESTED: {
        entry: 'pause',
        on: {
          ...COMMON_ACTIONS,
          REPORT_PAUSED: {
            target: 'PAUSED',
            actions: ['saveResumePoint'],
          },
          REPORT_TIME_UPDATE: {
            actions: 'assignTime',
          },
        },
        after: {
          [LOADING_TIMEOUT]: {
            target: 'FAILURE',
          },
        },
      },
      PAUSED: {
        entry: ['saveResumePoint', 'pause', 'sendStopEvent', 'assignRetryCountToZero'],
        on: {
          PLAY: {
            target: 'LOADING',
          },
          SKIP: {
            actions: assign({ shouldPauseAfterSkip: true }),
            target: 'SKIP_REQUESTED',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          ...COMMON_ACTIONS,
        },
      },
      FAILURE_WITH_RETRY: {
        id: 'failureWithRetry',
        entry: ['assignIncrementRetryCount', 'logRetry'],
        on: {
          PLAY: {
            target: 'LOADING',
          },
          PAUSE: {
            target: 'PAUSED',
            actions: 'pause',
          },
          ...COMMON_ACTIONS,
        },
        after: [
          {
            target: 'FAILURE',
            delay: (context) => getExponentialBackoffDelay(context.retryCount),
            cond: ({ retryCount = 0 }) => retryCount >= MAX_RETRY_ATTEMPTS,
          },
          {
            target: 'LOADING',
            delay: (context) => getExponentialBackoffDelay(context.retryCount),
            cond: ({ retryCount = 0 }) => retryCount < MAX_RETRY_ATTEMPTS,
          },
        ],
      },
      FAILURE: {
        id: 'failure',
        on: COMMON_ACTIONS,
        entry: ['saveResumePoint', 'stop', 'sendPlaybackFailedEvent'],
        after: [{ delay: 500, target: 'PAUSED' }],
      },
      DESTROYED: {
        entry: ['saveResumePoint', 'stop', 'sendStopEvent'],
        type: 'final',
      },
    },
  },
  {
    services: {
      getStreamInfo: ({ client }) => client.getStreamInfo(),
      skip: ({ client, playId, metadata }) => client.skip({ playId, ...metadata }),
    },
    actions: {
      addToHistory: (ctx) => {
        const id = ctx.item.getId();
        ctx.personalizationProvider.addToHistory([id]);
      },
      assignDefaults: assign({
        contentType: () => EContentType.ExclusiveStation,
        metadata: ({ item, metadata }): IPlayerMetadata =>
          metadata ?? {
            image: item.getImageSquare(),
            station: item.getTitle(),
          },
        client: ({
          item,
          personalizationProvider,
          credentialsProvider,
          client,
        }): IExclusiveStationClient =>
          client ?? createClient(item, personalizationProvider, credentialsProvider),
      }),
      assignRetryCountToZero: assign({
        retryCount: 0,
      }),
      assignIncrementRetryCount: assign({
        retryCount: ({ retryCount = 0 }) => retryCount + 1,
      }),
      assignStreamUrl: assign({
        playId: (_, event) => event.data[0],
        streamUrl: ({ item, credentialsProvider }, event) => {
          // Use HLS.js or fallback to native HLS
          const offset = event.data[1][item.getId()];

          const uri = new URI(item.getHlsStream())
            .setQuery('udid', credentialsProvider?.userToken)
            .setQuery('playId', event.data[0]);

          if (offset) {
            uri.setQuery('playlistOffset', offset);
          }

          return uri.normalize().valueOf();
        },
      }),
      handleSkip: assign({
        streamUrl: (_, event) => event.data,
        skipsCount: (_) => _.skipsCount - 1,
      }),
      // NOTICE: "updateSkipsCount" is a function that updates the internal skip count.
      // It was introduced to maintain the current skip status while we await a response from the server.
      // Once we receive a response from the server, we will update the "skipsCount" value to reflect the current count accurately.
      updateSkipsCount: assign({
        skipsCount: (_, event) => event?.metadata?.skips ?? 0,
      }),
      loadAudio: ({ player, streamUrl, autoplay }) => {
        const url = (streamUrl += `&gpp=${getGppString({
          optIn: !clientServicesConfig.disableTargetedAds,
        })}`);
        player?.load({
          url,
          isPodcast: false,
          isSuperHifi: false,
          isAudioPreroll: false,
          liveContentUrl: '',
          autoplay,
        });
      },
      assignMetadata: assign((ctx, event) => {
        const { metadata: existingMetadata, item, logger } = ctx;
        const retVal: Partial<IExclusiveStationMachineContext> = {};
        retVal.metadata = mergeMetadata({
          existingMetadata,
          newMetadata: 'metadata' in event ? event.metadata : {},
          logger,
          item,
        });
        return retVal;
      }),
      sendPreference: ({ client, playId, metadata, logger }, { preference }) => {
        client?.sendPreference({ playId, ...metadata }, preference).catch(() => {
          // We don't really care if this fails, but we do want to log it
          logger?.error('Failed to send preference', {
            preference,
          });
        });
      },
      assignTime: assign({
        elapsed: ({ metadata, elapsed }, { time }) => {
          const { duration, datumTime, segmentStartTime, trackStart } = metadata ?? {};

          if (duration == undefined || datumTime == undefined || segmentStartTime == undefined) {
            return elapsed;
          }
          const newElapsed = datumTime + time - (trackStart ?? 0);
          return newElapsed;
        },
      }),
      sendPlayEvent: ({ analyticsProvider, item }) => {
        if (item.data.id) {
          analyticsProvider.sendPlayerEvent({ type: PlayerAction.PLAY, contentId: item.data.id, streamUrl: getFirstAudioSourceUrl(item.data.audioSources) });
        }
      },
      sendStopEvent: ({ analyticsProvider, item }) => {
        if (item.data.id) {
          analyticsProvider.sendPlayerEvent({ type: PlayerAction.STOP, contentId: item.data.id, streamUrl: getFirstAudioSourceUrl(item.data.audioSources) });
        }
      },
      saveResumePoint: (context) => {
        saveResumePoints(context);
      },
      // using ts-ignore because, otherwise it throws error on using constant RESUME_POINT_INTERVAL_MSEC from other file
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      saveLocalResumePoint: (context) => {
        saveResumePoints(context, false);
      },
      ...DEFAULT_PLAYER_ACTIONS,
    },
    guards: {
      canAssignTime: ({ metadata }) => {
        const { duration, datumTime, segmentStartTime } = metadata ?? {};

        return duration != undefined && datumTime != undefined && segmentStartTime != undefined;
      },
    },
  },
);

const selector: TStreamSelector<typeof exclusiveStationMachine> = async (dataObject) => {
  if (
    dataObject instanceof Station &&
    dataObject.getEntityType() === EntityType.STATION &&
    dataObject.getEntitySubtype() === StationSubType.EXCLUSIVE &&
    dataObject.getStreamProvider() !== StreamProvider.SUPERHIFI
  ) {
    return exclusiveStationMachine.withContext({
      ...exclusiveStationMachine.context,
      item: dataObject,
      shouldPauseAfterSkip: false,
    });
  }
};

export default selector;
