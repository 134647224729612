import { type IProfile } from '@audacy-clients/client-services/src/personalizationServices/types';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export const initDataDog = (): void => {
  const clientToken = 'pub2c398ff69e30098ff513b4f503917302';
  const site = 'datadoghq.com';

  const commonConfig = {
    clientToken,
    site,
    service: 'audacy-web-spa',
    env: process.env.DATA_DOG_ENV || 'nonprod-dev',
    version: process.env.APP_VERSION,
    sampleRate: 100,
  };

  datadogRum.init({
    ...commonConfig,
    applicationId: '7384b8d4-6813-4bdc-b6ac-351686efe9ef',
    premiumSampleRate: 0,
    replaySampleRate: 0,
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogLogs.init({
    ...commonConfig,
    forwardErrorsToLogs: true,
  });
};

export const setUserToDataDog = (
  { firstName, lastName, emailAddress: email = '' }: Partial<IProfile> = {},
  userId?: string,
  audSessionId?: string
): void => {
  if (!userId) {
    datadogRum.setUser({ audSessionId });
    datadogLogs.setUser({ audSessionId });
    return
  }

  const name = (firstName || '') + (firstName && lastName ? ' ' + lastName : '');
  const config = {
    id: userId,
    name,
    email,
    audSessionId
  };

  datadogRum.setUser(config);
  datadogLogs.setUser(config);
};
