import Chapter from '@audacy-clients/client-services/src/dataServices/Chapter';

export interface IChapter {
  index: number;
  duration: number;
  startOffset: number;
  title?: string;
}

export const wrapChapter = (chapter: Chapter, index: number): IChapter => ({
  index,
  duration: chapter?.getDuration(),
  startOffset: chapter.getStartOffset(),
  title: chapter.getTitle(),
});

export const wrapChapters = (chapters: Chapter[]): IChapter[] => chapters.map(wrapChapter);
