import { featureFlagMap } from '@audacy-clients/client-services/src/utils';
import { brazeFeatureFlagsState } from '@audacy-clients/core/atoms/braze';
import { isClientServiceOnlineState } from '@audacy-clients/core/atoms/clientServices';
import { noCacheDefaultSelector } from '@audacy-clients/core/atoms/helpers/noCacheDefaultSelector';
import { getClientServices } from '@audacy-clients/core/utils/clientServices';
import {
  atom,
  readOnlySelector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';

import { ViewIDKey } from './constants';

const AD_REFRESH_RATE = '180';

export const defaultSettings = {
  featureFlags: {
    broadcastChapters: true,
    chapters: true,
    follows: true,
    queue: true,
    recentHistory: true,
    search: true,
    settingsLinks: false,
    share: true,
    voicify: false,
    tapToRecord: true,
    sleepTimer: true,
    verboseDataDogLogging: false,
    verbosePlayerLogging: false,
    videoPreRoll: false,
    searchPlaylistsTab: false,
    searchEpisodesTab: false,
    searchTopicsTab: false,
    meteredContent: false,
    nativePlayerErrorHandlingPhaseTwoAndroid: false,
    vodSpeedControls: false,
    settingsOpenSource: false,
    settingsLegal: false,
    settingsContact: false,
    settingsSecurity: false,
    settingsPlayback: false,
    settingsNotifications: false,
    settingsLocation: false,
    settingsAccount: false,
    identityNotifications: false,
    podcastTranscript: false,
    useHem: false,
    usePickupWhereYouLeftOff: false,
    optimizedAdBreaks: false,
    enableTraceSession: false,
  },
  global: {
    [ViewIDKey.AccountReactivateRequest]: 'accountReactivateRequest',
    [ViewIDKey.AndroidAutoChapterList]: 'androidAutoChapterList',
    [ViewIDKey.AndroidAutoNowPlaying]: 'androidAutoNowPlaying',
    [ViewIDKey.AndroidAutoHomePage]: 'androidAutoHomePage',
    [ViewIDKey.AllEpisodes]: 'allEpisodes',
    [ViewIDKey.CarPlayLoggedOut]: 'carPlayLoggedOut',
    [ViewIDKey.CarPlayRecentsList]: 'carPlayRecentsList',
    [ViewIDKey.CarPlayHomePage]: 'carPlayHomePage',
    [ViewIDKey.CarPlayChapterList]: 'сarPlayChapterList',
    [ViewIDKey.CarPlayNestedList]: 'сarPlayNestedList',
    [ViewIDKey.CarPlayNowPlaying]: 'сarPlayNowPlaying',
    [ViewIDKey.CarPlayInternetConnection]: 'carPlayInternetConnection',
    [ViewIDKey.Contact]: 'contact',
    [ViewIDKey.SettingsDeleteConfirmation]: 'settingsDeleteConfirmation',
    [ViewIDKey.DeveloperScreen]: 'developerScreen',
    [ViewIDKey.Landing]: 'landing',
    [ViewIDKey.Migration]: 'migration',
    [ViewIDKey.MyAudioFollowed]: 'myAudioFollowed',
    [ViewIDKey.MyAudioPodcastsAndShows]: 'myAudioPodcastsAndShows',
    [ViewIDKey.MyAudioQueue]: 'myAudioQueue',
    [ViewIDKey.MyAudioRecent]: 'myAudioRecent',
    [ViewIDKey.MyAudioStations]: 'myAudioStations',
    [ViewIDKey.MyAudioTopics]: 'myAudioTopics',
    [ViewIDKey.OnboardingStations]: 'onboardingStations',
    [ViewIDKey.Player]: 'player',
    [ViewIDKey.PrivacyLegal]: 'privacyLegal',
    [ViewIDKey.PrivacyPolicy]: 'legalPrivacyPolicy',
    [ViewIDKey.ProfileAccount]: 'profileAccount',
    [ViewIDKey.ProfileContactSupport]: 'profileContactSupport',
    [ViewIDKey.ProfileDelete]: 'profileDelete',
    [ViewIDKey.ProfileLocation]: 'profileLocation',
    [ViewIDKey.ProfileNotification]: 'profileNotification',
    [ViewIDKey.ProfileOpenSource]: 'profileOpenSource',
    [ViewIDKey.ProfileOverview]: 'profileOverview',
    [ViewIDKey.ProfilePassword]: 'profilePassword',
    [ViewIDKey.ProfileSecurity]: 'profileSecurity',
    [ViewIDKey.Queue]: 'queueOverlay',
    [ViewIDKey.RegistrationAdditionalDetails]: 'registrationAdditionalDetails',
    [ViewIDKey.RegistrationName]: 'registrationName',
    [ViewIDKey.Schedule]: 'schedule',
    [ViewIDKey.SongHistory]: 'songHistory',
    [ViewIDKey.Search]: 'search',
    [ViewIDKey.TapToRecordScreen]: 'tapToRecordScreen',
    [ViewIDKey.TranscriptScreen]: 'transcriptScreen',
    [ViewIDKey.Terms]: 'legalTermsOfUse',
    [ViewIDKey.Playlist]: 'playlist',
  },
  configuration: {
    adRefreshInterval: AD_REFRESH_RATE,
  },
};

export type IClientSettings = typeof defaultSettings;

export type IFeatureFlags = IClientSettings['featureFlags'];
export const defaultFeatureFlags = defaultSettings.featureFlags;
featureFlagMap.set(featureFlagMap.defaultFlags, defaultFeatureFlags);

let remoteSettingsCache: IClientSettings | null = null;

export const loadRemoteSettings = async (): Promise<IClientSettings> => {
  if (remoteSettingsCache) {
    return remoteSettingsCache;
  }

  const response = await getClientServices().then((cs) => cs.getDataServices().getClientSettings());

  const remoteSettings: IClientSettings = {
    featureFlags: {
      ...defaultFeatureFlags,
      ...response.featureFlags,
    },
    global: {
      ...defaultSettings.global,
      ...response.global,
    },
    configuration: {
      ...defaultSettings.configuration,
      ...response.configuration,
    },
  };

  // this sets the initial data from the settings api call so client services can
  // read the "featureFlagMap" to support flags in the library
  featureFlagMap.set('remoteSettings', remoteSettings.featureFlags);

  remoteSettingsCache = remoteSettings;

  return remoteSettings;
};

const clientSettingsState = noCacheDefaultSelector<IClientSettings>({
  get: ({ get }) => {
    const isOnlineState = get(isClientServiceOnlineState);
    if (isOnlineState) {
      return loadRemoteSettings();
    }

    return defaultSettings;
  },
  key: 'ClientSettings',
});

// Utility to map known viewIds in our code to values defined in Experience API
export const getViewId = (
  clientSettingsGlobal: IClientSettings['global'],
  viewId: ViewIDKey,
): string => clientSettingsGlobal[viewId];

export const useViewId = (viewId: ViewIDKey): string =>
  getViewId(useRecoilValue(clientSettingsState).global, viewId);

export const featureFlag = selectorFamily({
  key: 'FeatureFlags',
  get:
    (flag: keyof IFeatureFlags) =>
    ({ get }) => {
      const overrideFeatureFlags = get(featureFlagOverridesState);
      const defaultFeatureFlagSetting = get(clientSettings).featureFlags[flag];
      const brazeFeatureFlags = get(brazeFeatureFlagsState);

      return overrideFeatureFlags[flag] !== undefined
        ? overrideFeatureFlags[flag]
        : brazeFeatureFlags[flag] !== undefined
        ? brazeFeatureFlags[flag]
        : defaultFeatureFlagSetting;
    },
});

export const useGetFeatureFlag = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      (flag: keyof IFeatureFlags) =>
        snapshot.getLoadable(featureFlag(flag)).valueMaybe() || false,
  );

export const useFeatureFlag = (flag: keyof IFeatureFlags): boolean =>
  useRecoilValue(featureFlag(flag));

export const featureFlagOverridesState = atom<IFeatureFlags>({
  default: {} as IFeatureFlags,
  key: 'FeatureFlagOverrides',
});

export const clientSettings = readOnlySelector(clientSettingsState);

/**
 * Use feature flags, but fall back to default flags while loading
 */
export const useFeatureFlags = (): IFeatureFlags => {
  const defaultFeatureFlags = defaultSettings.featureFlags;
  const clientSettingsLoadable = useRecoilValueLoadable(clientSettings);
  const featureFlagOverrides = useRecoilValue(featureFlagOverridesState);
  const brazeFeatureFlags = useRecoilValue(brazeFeatureFlagsState);

  const remoteFeatureFlags =
    clientSettingsLoadable.state === 'hasValue' ? clientSettingsLoadable.contents.featureFlags : {};

  return {
    ...defaultFeatureFlags,
    ...remoteFeatureFlags,
    ...brazeFeatureFlags,
    ...featureFlagOverrides,
  };
};
