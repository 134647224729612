import clamp from 'lodash/clamp';
import { interpret, SingleOrArray, Event, StateValueMap } from 'xstate';

import { type IFeatureFlags } from '@audacy-clients/core/atoms/config/settings';
import { AdvertisingIdFunction, IDeviceInfoProvider } from '../Config';
import { Platform, Preference } from '../Constants';
import { ICredentialsProvider, ILocationProvider, IMarketingDataProvider } from '../Container';
import { PlaybacksHashMap } from '../dataServices/DataServices';
import { enableObjectTracing, ILogger, IddError, IddLogger } from '../logger';
import { UnauthenticatedServices } from '../personalizationServices';
import { IPlayerSettingsProvider } from '../personalizationServices/types';
import { audioServicesMachine, ACTIVE_STREAMER_ID } from './audioServicesMachine';
import { IPlayer, TPlaybackRate } from './players/types';
import { IStreamerMachineContext } from './streamers/types';
import { timeFromMs } from './streamers/utils';
import {
  ECollectionPlaybackMode,
  ESleepTimerState,
  IAnalyticsProvider,
  IAudioServicesMachineContext,
  IAudioServicesState,
  IAudioServicesStateListenerParams,
  IDataProvider,
  IPersonalizationProvider,
  TAudioServicesListener,
  TAudioServicesMachineEvent,
  TAudioServicesSignalWireListener,
  TPlayableObject,
} from './types';
import { bindPlayer, extractRootState, startInspector } from './utils';
import AudacyLogger, { LoggerTopic } from '../AudacyLogger';

startInspector();

// TODO [CCS-1384] ANALYTICS ON ALL STREAMERS!
interface IAudioServicesConfig {
  analyticsProvider: IAnalyticsProvider;
  credentialsProvider?: ICredentialsProvider;
  dataProvider: IDataProvider;
  ddLogger?: IddLogger;
  domParser?: object;
  enableAmperWave?: boolean;
  enableAmperWaveRewind?: boolean;
  locationProvider?: ILocationProvider;
  logger: ILogger;
  marketingDataProvider?: IMarketingDataProvider;
  deviceInfoProvider?: IDeviceInfoProvider;
  personalizationProvider: IPersonalizationProvider;
  platform: Platform;
  player: IPlayer;
  playerSettingsProvider: IPlayerSettingsProvider;
  rewindAds?: boolean;
  unauthenticatedProvider?: UnauthenticatedServices;
  webcastMetricsStr: string;
  featureFlags?: IFeatureFlags;
  ddError: IddError;
  eventListener?: TAudioServicesListener;
  getAdvertisingId?: AdvertisingIdFunction;
}

export default class AudioServices {
  logger?: ILogger;
  ddLogger?: IddLogger;
  previousParentState = '';
  previousChildState = '';
  private eventListener?: TAudioServicesListener;
  private send: (event: SingleOrArray<Event<TAudioServicesMachineEvent>>) => unknown;
  private getRootMachineContext: () => IAudioServicesMachineContext;
  private getStreamerMachineContext: () => IStreamerMachineContext<TPlayableObject>;

  constructor(config: IAudioServicesConfig) {
    this.eventListener = config.eventListener;
    this.logger = config.logger;
    this.ddLogger = config.ddLogger;

    const machineContext: IAudioServicesMachineContext = {
      activeCollection: [],
      activePlayableObject: undefined,
      analyticsProvider: config.analyticsProvider,
      autoplay: false,
      collectionPlaybackMode: ECollectionPlaybackMode.SingleItem,
      credentialsProvider: config.credentialsProvider,
      dataProvider: config.dataProvider,
      ddError: config.ddError,
      ddLogger: config.ddLogger,
      isMuted: false,
      locationProvider: config.locationProvider,
      logger: config.logger,
      marketingDataProvider: config.marketingDataProvider,
      personalizationProvider: config.personalizationProvider,
      platform: config.platform,
      playbacks: {},
      player: config.player,
      playerSettingsProvider: config.playerSettingsProvider,
      deviceInfoProvider: config.deviceInfoProvider,
      playLive: false,
      rate: 1,
      skipsCount: 0,
      sleepTimerState: ESleepTimerState.Idle,
      unauthenticatedProvider: config.unauthenticatedProvider,
      volume: 1,
      playbackTimer: {
        accumulator: timeFromMs(0),
        elapsed: timeFromMs(0),
      },
      featureFlags: config.featureFlags,
    };

    const machine = audioServicesMachine.withContext(machineContext);

    const service = interpret(machine, { devTools: true });

    bindPlayer(config.player, service.send, config.logger, config.ddLogger);
    this.send = service.send;
    this.getRootMachineContext = () => service.getSnapshot().context;
    this.getStreamerMachineContext = () =>
      service.getSnapshot().children[ACTIVE_STREAMER_ID]?.getSnapshot().context;

    service.onTransition((state) => {
      // XState has a bug where it triggers onTransition on the parent machine just before the
      // child machine actually transitions. This causes a stale state in onTransition.
      // Adding setTimeout allows us to wait for that child machine to transition and fix the stale state.
      // Here's a link to the bugged code. It schedules the child transition for later then triggers onTransition
      // https://github.com/statelyai/xstate/blob/3b4b1305af6fa5db288ffdb3b4918a05048353e4/packages/core/src/interpreter.ts#L752-L758
      setTimeout(() => {
        const streamerSnapshot = state.children[ACTIVE_STREAMER_ID]?.getSnapshot();

        const {
          activePlayableObject,
          playbacks,
          sleepTimerState,
          rate,
          volume,
          isMuted,
          activeCollection,
          collectionPlaybackMode,
        } = state.context;

        const audioServicesState: IAudioServicesState = {
          value: state.value as StateValueMap,
          context: {
            activePlayableObject,
            playbacks,
            sleepTimerState,
            rate,
            volume,
            isMuted,
            activeCollection,
            collectionPlaybackMode,
          },
        };

        // streamerState.value is the child machine state e.g. LOADING
        // we also use streamerSnapshot.context e.g. requestedOffset
        const streamerState = streamerSnapshot
          ? {
              ...streamerSnapshot,
              value: extractRootState(streamerSnapshot.value),
            }
          : undefined;

        this.logger?.trace(
          `AudioServices machine state: ${Object.values(state.value)[0]}, ${
            streamerState
              ? `Child streamer machine state: ${JSON.stringify(
                  streamerState?.historyValue?.current || streamerState.value,
                  null,
                  2,
                )}`
              : ''
          }`,
        );

        if (Object.values(state.value)[0] != this.previousParentState) {
          this.previousParentState = Object.values(state.value)[0] as string;
          AudacyLogger.info(
            `[${LoggerTopic.Streaming}] AudioServices: Parent streamer machine state: ${
              Object.values(state.value)[0]
            }`,
          );
        }
        const childState = JSON.stringify(
          streamerState ? streamerState?.historyValue?.current || streamerState.value : undefined,
        );
        if (childState != this.previousChildState) {
          this.previousChildState = childState;
          AudacyLogger.info(
            `[${LoggerTopic.Streaming}] AudioServices: Child streamer machine state: ${childState}`,
          );
        }

        const payload: IAudioServicesStateListenerParams = {
          audioServicesState,
          streamerState,
        };

        this.eventListener?.(payload);
      }, 0);
    });

    service.start();
  }

  setMicMuted(isMuted: boolean) {
    this.send({ type: 'SET_MIC_MUTED', isMuted });
    this.ddLogger?.info('set mic mute called');
  }

  setSleepTimerDuration(durationInMin: number) {
    const durationInMsec = durationInMin * 60 * 1000;
    const timeToSleep = Date.now() + durationInMsec;
    this.send({ type: 'SET_SLEEP_TIMER', sleepTimer: timeToSleep });
    this.logDataDogInfo('Set sleep timer called');
  }

  setSleepTimerForCurrentEpisode() {
    this.send({
      type: 'SET_SLEEP_TIMER',
      sleepTimer: ESleepTimerState.EndOfEpisode,
    });
    this.logDataDogInfo('Set sleep timer called');
  }

  cancelSleepTimer() {
    this.send({ type: 'CANCEL_SLEEP_TIMER' });
    this.ddLogger?.info(`Cancel sleep timer called`);
  }

  setCollectionPlaybackMode = (mode: ECollectionPlaybackMode) =>
    this.send({ type: 'SET_COLLECTION_PLAYBACK_MODE', mode });

  getCollectionPlaybackMode = () => this.getRootMachineContext().collectionPlaybackMode;

  setEventListener = (eventListener?: TAudioServicesListener) =>
    (this.eventListener = eventListener);

  setSignalWireEventListener = (signalWireEventListener: TAudioServicesSignalWireListener) => {
    this.send({
      type: 'SET_SIGNAL_WIRE_EVENT_LISTENER',
      signalWireEventListener,
    });
    this.ddLogger?.info(`set signal wire event listner called`);
  };

  setVolume(volume: number) {
    this.send({ type: 'SET_MUTED', isMuted: volume === 0 });
    this.send({ type: 'SET_VOLUME', volume: clamp(volume, 0, 1) });
  }

  setMuted(isMuted: boolean) {
    this.send({ type: 'SET_MUTED', isMuted });
    this.logDataDogInfo(`mute event called`);
  }

  setPlaybackRate(rate: TPlaybackRate) {
    this.send({ type: 'SET_PLAYBACK_RATE', rate });
    this.logDataDogInfo(`Set playback rate with rate ${rate} event called`);
  }

  getPlayer() {
    return this.getRootMachineContext().player;
  }

  getVolume() {
    return this.getRootMachineContext().volume;
  }

  getMuted() {
    return this.getRootMachineContext().isMuted;
  }

  getIsLive() {
    return this.getStreamerMachineContext()?.metadata?.isLive ?? false;
  }

  seekToTime(timeInSecs: number) {
    this.send({ type: 'SEEK_TIME', time: timeInSecs });
    this.logDataDogInfo(`Seek time event called with time: ${timeInSecs}`);
  }

  seekToProgress(progress: number) {
    this.seekToTime(progress * (this.getStreamerMachineContext()?.metadata?.duration ?? 0));
    this.logDataDogInfo(`Seek to progress ${progress} called`);
  }

  scrubbed() {
    this.send({ type: 'SCRUB' });
  }

  next(shouldSkipCompletedItems = false) {
    this.send({ type: 'NEXT', shouldSkipCompletedItems });
    this.ddLogger?.info('Skip next event called');
  }

  previous(shouldSkipCompletedItems = false) {
    this.send({ type: 'PREVIOUS', shouldSkipCompletedItems });
    this.ddLogger?.info('Skip previous event called');
  }

  skip() {
    this.send({ type: 'SKIP' });
    this.ddLogger?.info('Skip event called');
  }

  skipByTime(timeInSecs: number, onCallback?: (value: number) => void) {
    const elapsed = this.getStreamerMachineContext()?.elapsed ?? 0;
    const seekTime = elapsed + timeInSecs;

    this.seekToTime(seekTime);
    this.logDataDogInfo(`seek time event called with seek time: ${seekTime}`);
    onCallback?.(seekTime);
  }

  sendPreference(preference: Preference) {
    this.send({ type: 'SEND_PREFERENCE', preference });
  }

  goToLive() {
    this.send({ type: 'GO_TO_LIVE' });
    this.logDataDogInfo('Go To Live event called');
  }

  play(dataObj?: TPlayableObject, autoplay = true, offset?: number, playLive = false) {
    if (dataObj) {
      this.send({ type: 'STOP' });
      this.send({
        type: 'LOAD_OBJECT',
        data: dataObj,
        autoplay,
        offset,
        playLive,
      });
    } else {
      this.send({ type: 'PLAY' });
    }
    this.ddLogger?.info(`play event called with content: ${dataObj?.getId()}`);
  }

  updatePlaybacks(playbacks: PlaybacksHashMap) {
    this.send({
      type: 'UPDATE_PLAYBACKS',
      data: playbacks,
    });
    this.ddLogger?.info(`playbacks updated`);
  }
  updateCollection(collection: TPlayableObject[]) {
    this.send({
      type: 'UPDATE_COLLECTION',
      data: collection,
    });
    this.ddLogger?.info('collection updated');
  }

  // TODO [CCS-1389] Do we need this convenience method? I think it should be eliminated
  playCollection(collection: TPlayableObject[], dataObj: TPlayableObject) {
    this.updateCollection(collection);
    this.play(dataObj);
  }

  pause() {
    this.send({ type: 'PAUSE' });
    this.logDataDogInfo(`Pause event called`);
  }

  stop() {
    this.send({ type: 'STOP' });
    this.logDataDogInfo(`Stop event called`);
  }

  incrementRate() {
    this.send({ type: 'INCREMENT_PLAYBACK_RATE' });
    this.logDataDogInfo(`Playback rate increased`);
  }

  private logDataDogInfo(message: string) {
    const contentId = this.getStreamerMachineContext()?.item.getId();
    this.ddLogger?.info(message + ` for content id:  ${contentId}`);
  }
}

enableObjectTracing(AudioServices);
