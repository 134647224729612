import {
  CollectionSubType,
  type EntitySubtype,
  EntityType,
  EpisodeSubType,
  ReactQueryStorageKey,
  ShowSubtype,
  ViewType,
} from '@audacy-clients/client-services/core';
import { getClientServices, useClientServices } from '@audacy-clients/core/utils/clientServices';
import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { queryKeys } from '../helpers/api';
import { staleTime } from '../helpers/constants';
import { type ViewIDKey } from './constants';

import { noCacheDefaultSelector } from '../helpers/noCacheDefaultSelector';

export type IViewInfo = {
  viewId: string | ViewIDKey;
  viewType: ViewType;
};

export type ITabNavigation = {
  iconRef: string;
  label: string;
  tabKey: string;
} & IViewInfo;

type IViewMap = Record<string, Record<string, IViewInfo>>;

export type IClientNavigation = {
  tabs: Array<ITabNavigation>;
  viewMap: IViewMap;
};

const defaultViewMap: IViewMap = {
  [EntityType.EPISODE]: {
    [EpisodeSubType.BROADCAST_SHOW_EPISODE]: {
      viewId: 'episode',
      viewType: ViewType.EPISODE,
    },
    [EpisodeSubType.HOST_CREATED_EPISODE]: {
      viewId: 'episode',
      viewType: ViewType.EPISODE,
    },
    [EpisodeSubType.PODCAST_EPISODE]: {
      viewId: 'episode',
      viewType: ViewType.EPISODE,
    },
  },
  [EntityType.SHOW]: {
    [ShowSubtype.BROADCAST]: {
      viewId: 'shows',
      viewType: ViewType.SHOW,
    },
    [ShowSubtype.DIGITAL]: {
      viewId: 'shows',
      viewType: ViewType.SHOW,
    },
    [ShowSubtype.HOST_CREATED]: {
      viewId: 'shows',
      viewType: ViewType.SHOW,
    },
    [ShowSubtype.PODCAST]: {
      viewId: 'podcasts',
      viewType: ViewType.SHOW,
    },
  },
  [EntityType.COLLECTION]: {
    [CollectionSubType.PLAYLIST]: {
      viewId: 'playlists',
      viewType: ViewType.PLAYLIST,
    },
  },
};

export const loadRemoteNavigation = async (): Promise<IClientNavigation> => {
  const response = await getClientServices().then((cs) =>
    cs.getDataServices().getClientNavigationTwo(),
  );

  const wrapped: IClientNavigation = {
    tabs: (response.tabs || []).map((t) => ({
      iconRef: t.iconRef,
      label: t.label,
      tabKey: `tab:${t.viewType}:${t.viewId}`,
      viewId: t.viewId,
      viewType: t.viewType,
    })),
    viewMap: {
      ...defaultViewMap,
      ...(response.viewMap || {}),
    },
  };

  return wrapped;
};

export const webClientNavigation = noCacheDefaultSelector<IClientNavigation>({
  get: () => loadRemoteNavigation(),
  key: 'WebClientNavigation',
});

export const useViewInfoByTypeAndSubtype = (
  type?: EntityType,
  subtype?: EntitySubtype,
): IViewInfo | undefined => {
  const { viewMap } = useClientNavigation();
  if (!type || !subtype) {
    return undefined;
  }
  return viewMap[type]?.[subtype] || viewMap[type]?.default;
};

export const mapEntityToParentTypes = (
  type?: EntityType,
  subtype?: EntitySubtype,
): [EntityType, EntitySubtype] | undefined => {
  if (type === EntityType.EPISODE) {
    if (
      subtype === EpisodeSubType.BROADCAST_SHOW_EPISODE ||
      subtype === EpisodeSubType.HOST_CREATED_EPISODE
    ) {
      return [EntityType.SHOW, ShowSubtype.BROADCAST];
    }

    if (subtype === EpisodeSubType.PODCAST_EPISODE) {
      return [EntityType.SHOW, ShowSubtype.PODCAST];
    }
  }

  if (type === EntityType.STANDALONE_CHAPTER) {
    return [EntityType.SHOW, ShowSubtype.BROADCAST];
  }

  return undefined;
};

type TGetViewInfoByTypeAndSubtype = (
  type?: EntityType,
  subtype?: EntitySubtype,
  options?: { shouldGetInfoForParent?: boolean },
) => IViewInfo | undefined;

export const useClientNavigation = (): IClientNavigation => {
  const { clientServices } = useClientServices();
  const isConnected = clientServices.getOnlineStatus();

  const handleRemoteNavigationSuccess = (data: IClientNavigation) => {
    clientServices
      .getPersonalizationServices()
      .dataStore.setDataSync(ReactQueryStorageKey.REMOTE_NAVIGATION, data);
  };

  const initialData = {
    tabs: [
      {
        iconRef: 'Logo',
        label: 'Home',
        tabKey: 'tab:MODULES:home',
        viewId: 'home',
        viewType: ViewType.MODULES,
      },
    ],
    viewMap: { ...defaultViewMap },
  };

  const { data = initialData } = useQuery(queryKeys.remoteNavigation(), loadRemoteNavigation, {
    cacheTime: staleTime.FOREVER,
    enabled: isConnected,
    onSuccess: handleRemoteNavigationSuccess,
  });

  return data;
};

export const useGetViewInfoByTypeAndSubtype = (): TGetViewInfoByTypeAndSubtype => {
  const { viewMap } = useClientNavigation();

  return useCallback(
    (type, subtype, { shouldGetInfoForParent } = {}) => {
      let [finalType, finalSubtype] = [type, subtype];

      if (shouldGetInfoForParent) {
        [finalType, finalSubtype] = mapEntityToParentTypes(type, subtype) || [];
      }

      if (!finalType || !finalSubtype) {
        return undefined;
      }

      return viewMap[finalType]?.[finalSubtype] || viewMap[finalType]?.default;
    },
    [viewMap],
  );
};
