import { BP, Colors, mqMax } from '~/styles';
import { type TStyleSheet } from '~/types/emotion-styles';

const styles: TStyleSheet = {
  container: { display: 'flex', justifyContent: 'center' },
  drawerContainer: {
    backgroundColor: Colors.secondaryDeepBlue,
    borderRadius: '15px 15px 0 0',
    padding: '30px 30px 50px',
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [mqMax(BP.Medium)]: {
      padding: '30px 30px 10px',
    },
  },
  headerSeperator: {
    height: 1,
    backgroundColor: Colors.white07,
    position: 'absolute',
    bottom: 0,
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    maxWidth: '100vw',
    width: '100vw',
  },
  contentContainer: {
    overflowY: 'auto',
    height: '100%',
  },
  topOverlay: {
    position: 'sticky',
    top: -1,
    left: 0,
    right: 0,
    height: '30px',
    background: `linear-gradient(to bottom, ${Colors.secondaryDeepBlue}, transparent)`,
    pointerEvents: 'none',
    zIndex: 1,
  },
  bottomOverlay: {
    position: 'sticky',
    bottom: -1,
    left: 0,
    right: 0,
    height: '30px',
    pointerEvents: 'none',
    background: `linear-gradient(to top, ${Colors.secondaryDeepBlue}, transparent)`,
    zIndex: 1,
  },
  transcriptPlayer: {
    backgroundColor: Colors.secondaryDeepBlue,
    padding: '40px 20px 20px',
  },
};

export default styles;
