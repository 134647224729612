import { playerTimeState } from '@audacy-clients/core/atoms/player';
import { type TTranscript, useTranscriptData } from '@audacy-clients/core/atoms/transcripts';
import { secondsToMilliseconds } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

type UseTranscriptReturnType = {
  loading: boolean;
  hasError: boolean;
  transcript: Array<TTranscript> | undefined;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  duration: number;
  offsetEqualToZero: boolean;
};

const Constants = {
  noIndexFounded: -1,
  startOffset: 0,
  defaultSpeakerName: 'Speaker',
};
/**
 * Handles the transcript data and the current index of the transcript
 */
const useTranscript = (): UseTranscriptReturnType => {
  const [currentIndex, setCurrentIndex] = useState(Constants.noIndexFounded);

  const { duration = 0, offset = Constants.startOffset } = useRecoilValue(playerTimeState);

  const prevIndexRef = useRef<number | null>(null);

  const offsetInMilliseconds = secondsToMilliseconds(offset);
  const offsetEqualToZero = offsetInMilliseconds === 0;

  const { transcript: transcriptResponse, loading, hasError } = useTranscriptData();
  const transcript = useMemo(
    () =>
      transcriptResponse?.transcript.map((item) => {
        const correctSpeakerName =
          item.speaker.split(' ')[0] === Constants.defaultSpeakerName &&
          item.speaker.split(' ').length === 2
            ? `${item.speaker.split(' ')[0]} ${Number(item.speaker.split(' ')[1]) + 1}`
            : item.speaker;
        return {
          ...item,
          speaker: correctSpeakerName,
        };
      }),
    [transcriptResponse?.transcript],
  );

  useEffect(() => {
    if (!transcript) {
      return;
    }

    const newIndex = transcript.findIndex(
      (item) =>
        offsetInMilliseconds >= getRoundedMs(item.start_time) &&
        offsetInMilliseconds < getRoundedMs(item.end_time),
    );

    if (newIndex === Constants.noIndexFounded && prevIndexRef.current !== null) {
      setCurrentIndex(prevIndexRef.current);
    } else {
      prevIndexRef.current = newIndex;
      setCurrentIndex(newIndex);
    }
  }, [transcript, offsetInMilliseconds]);

  return {
    loading,
    hasError,
    transcript,
    currentIndex,
    setCurrentIndex,
    duration,
    offsetEqualToZero,
  };
};

export default useTranscript;

const getRoundedMs = (time: number) => {
  return Math.floor(time / 1000) * 1000;
};
