import { EpisodeSubType } from '@audacy-clients/client-services';
import { useFeatureFlag } from '@audacy-clients/core/atoms/config/settings';
import { nowPlaying, playerCompanionAdIframeUrlState } from '@audacy-clients/core/atoms/player';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { scrollableClassState } from '~/atoms/scrollClass';
import AdBanner from '~/components/AdBanner';
import EntityImage from '~/components/Entity/components/EntityImage';
import Link from '~/components/Link';
import usePlayerProps from '~/components/Player/hooks/usePlayerProps';
import InfoPanel from '~/components/Player/Info';
import SkeletonText from '~/components/SkeletonText';
import TranscriptBottomSheet from '~/components/Transcript/TranscriptBottomSheet';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import styles from './styles';

type TContentOverviewProps = {
  isInfoVisible: boolean;
  isQueueVisible: boolean;
  onQueueClick: () => void;
};

const ContentOverview = ({
  isInfoVisible,
  isQueueVisible,
  onQueueClick,
}: TContentOverviewProps): JSX.Element => {
  const {
    imageUrl,
    imageAlt,
    hasMetaData,
    playerTitle,
    parentShowUrl,
    playerSubtitle,
    srcFallback,
  } = usePlayerProps();
  const adUrl = useRecoilValue(playerCompanionAdIframeUrlState);
  const scrollable = useRecoilValue(scrollableClassState);
  const { lessThan } = useBreakpoints();
  const nowPlayingItem = useRecoilValue(nowPlaying);
  const isPodcastTranscriptEnabled = useFeatureFlag('podcastTranscript');
  const isTranscriptNavigable =
    nowPlayingItem?.episodeDataObject?.transcript?.isNavigableTranscript;

  const isPodcast = nowPlayingItem?.episodeDataObject?.subtype === EpisodeSubType.PODCAST_EPISODE;

  const contentImage = () => {
    if (adUrl) {
      return <AdBanner containerCss={styles.adBanner} url={adUrl} title={playerTitle} />;
    }

    return (
      <EntityImage
        entityImageCss={styles.coverImg}
        alt={imageAlt}
        imageURIBase={imageUrl}
        isLoading={!hasMetaData}
        fallbackImage={srcFallback}
      />
    );
  };

  const subtitle = useMemo(
    () =>
      parentShowUrl ? (
        <Link label={playerSubtitle} labelCss={[styles.label, styles.link]} href={parentShowUrl} />
      ) : (
        <span css={styles.label}>{playerSubtitle}</span>
      ),
    [parentShowUrl, playerSubtitle],
  );

  return (
    <div css={styles.contentOverview}>
      <div css={styles.contentOverviewHeader}>
        {contentImage()}
        {!hasMetaData ? (
          <>
            <SkeletonText length={30} fontSize={15} />
            <SkeletonText length={20} fontSize={13} />
          </>
        ) : (
          <>
            <span css={styles.title}>{playerTitle}</span>
            <span css={styles.subtitle}>{subtitle}</span>
            {isPodcastTranscriptEnabled && isPodcast && isTranscriptNavigable && (
              <TranscriptBottomSheet isQueueVisible={isQueueVisible} onQueueClick={onQueueClick} />
            )}
          </>
        )}
      </div>
      {lessThan.LG && (
        <div className={scrollable} css={styles.content}>
          <InfoPanel isVisible={isInfoVisible} />
        </div>
      )}
    </div>
  );
};

export default ContentOverview;
