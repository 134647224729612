import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { EntityType, EpisodeSubType, StationSubType } from '../../../Constants';
import Container from '../../../Container';
import Chapter from '../../../dataServices/Chapter';
import Episode from '../../../dataServices/Episode';
import StandaloneChapter from '../../../dataServices/StandaloneChapter';
import Station from '../../../dataServices/Station';
import { ILogger } from '../../../logger';
import { IPlayerMetadata } from '../../players/types';
import { TPlayableObject } from '../../types';
import { addTimes, isContentTypeAd, sFromTime, timeFromMs, timeFromS } from '../utils';

const serviceBus = Container;

export type StreamType = 'BROADCAST' | 'STANDALONE_CHAPTER';

export type PlayableStreamObject =
  | {
      streamType: 'BROADCAST';
      station: Station;
      episode?: Episode;
      standaloneChapter: undefined;
    }
  | {
      streamType: 'STANDALONE_CHAPTER';
      station: undefined;
      episode: undefined;
      standaloneChapter: StandaloneChapter;
    };

/**
 * Convert a dataObject into a strongly typed object that we can use in RewindStationMachine
 */
export const getPlayableDataObject = async (
  dataObject: TPlayableObject,
): Promise<PlayableStreamObject | undefined> => {
  let station = dataObject instanceof Station ? dataObject : undefined;
  const episode = dataObject instanceof Episode ? dataObject : undefined;
  const standaloneChapter = dataObject instanceof StandaloneChapter ? dataObject : undefined;

  // Standalone chapter
  if (standaloneChapter) {
    return {
      streamType: 'STANDALONE_CHAPTER',
      station: undefined,
      episode: undefined,
      standaloneChapter,
    };
  }

  // Get parent station if playing AmperWave radio broadcast by episode
  const stationId = episode?.getParentStation()?.getId();
  if (stationId) {
    station = (await serviceBus.dataServices.getContentObject(stationId)) as Station;
  }

  // Play AmperWave radio broadcast
  if (
    station &&
    station.getEntityType() === EntityType.STATION &&
    station.getEntitySubtype() === StationSubType.BROADCAST &&
    station.isRewindable()
  ) {
    return {
      streamType: 'BROADCAST',
      station,
      episode,
      standaloneChapter: undefined,
    };
  }

  // No playable object
  return;
};

/**
 * Normalizes absolute (epoch) and relative (episode start) resume points for use
 * as elapsed player time in seconds. Converts as follows:
 * Broadcast - to absolute elapsed times, because we use AmperWave
 */
export const normalizeResumePoint = ({
  resumePoint,
  entity,
  streamType,
}: {
  resumePoint?: number;
  entity: Episode | StandaloneChapter | undefined;
  streamType: StreamType;
}): number | undefined => {
  // Resume points not allowed for non-rewindable episodes
  if (entity === undefined || !entity.isRewindable()) {
    return undefined;
  }

  const now = Date.now() / 1000;
  const year2000 = new Date('2000').getTime() / 1000;
  const entityStart = entity.getStartTimeSeconds();
  const entityEnd = entity.getEndTimeSeconds();

  // Reset corrupted resume points
  if (!resumePoint || resumePoint < 0 || resumePoint >= entityEnd || resumePoint > now) {
    // If the resume point is corrupted and the requested entity is LIVE, play LIVE
    if (streamType === 'BROADCAST' && entityEnd > now) {
      return undefined;
    }
    resumePoint = entityStart;
  }

  // Round to the second
  resumePoint = Math.floor(resumePoint);

  const absoluteResumePoint = resumePoint >= year2000 ? resumePoint : entityStart + resumePoint;

  if (streamType === 'BROADCAST') {
    return absoluteResumePoint;
  }

  if (streamType === 'STANDALONE_CHAPTER') {
    return absoluteResumePoint;
  }
};

export const findCurrentChapter = (
  chapters: Chapter[] | undefined,
  episode: Episode | undefined,
  time: number,
): number | undefined => {
  if (!chapters || !episode) {
    return;
  }

  const currentWallClockTime = addTimes(timeFromMs(episode.getStartTimeMillis()), timeFromS(time));

  for (let i = chapters.length - 1; i >= 0; i--) {
    const chapter = chapters[i];
    if (chapter.getStartOffset() <= sFromTime(currentWallClockTime)) {
      return i;
    }
  }
};

export const findCurrentChapterFromOffset = (
  requestedOffset: number | undefined,
  chapters?: Chapter[],
) => {
  if (!requestedOffset || !chapters) {
    return;
  }

  for (let i = chapters.length - 1; i >= 0; i--) {
    const chapter = chapters[i];
    if (chapter.getStartOffset() <= requestedOffset) {
      return i;
    }
  }
};

interface IMergeMetadataOptions {
  newMetadata?: IPlayerMetadata;
  existingMetadata?: IPlayerMetadata;
  entity?: Episode | StandaloneChapter;
  item: TPlayableObject;
  logger?: ILogger;
}

export const mergeMetadata = ({
  logger,
  newMetadata,
  existingMetadata,
  entity,
  item,
}: IMergeMetadataOptions): IPlayerMetadata => {
  if (newMetadata) {
    logger?.debug('Metadata assigner got input', newMetadata);
  }

  const retVal: IPlayerMetadata = {
    ...existingMetadata,
    ...omitBy(
      entity
        ? {
            image:
              entity.getImageSquare() || entity.getParentImageSquare() || item.getImageSquare(),
            songOrShow: entity.getTitle(),
            start: entity.getStartTimeMillis(),
            stop: entity.getEndTimeMillis(),
            station: item instanceof Station ? item.getTitle() : entity.getParentTitle(),
            duration: entity.getDuration(),
          }
        : {},
      isNil,
    ),
    ...newMetadata,
  };

  // Detect new ad or content to reset companionAdIframeUrl since
  // contentType/companionAdIframeUrl only exist on the first
  // fragment of a new ad or content
  const isNewAdOrContent = Boolean(newMetadata?.contentType);

  // Make sure ad image gets deleted after ad
  // TODO: [CCS-2788] make sure this is also fixed on on Live + Exclusive stations
  if (isNewAdOrContent && !newMetadata?.companionAdIframeUrl) {
    delete retVal.companionAdIframeUrl;
  }

  if (retVal.isLive === undefined && existingMetadata) {
    retVal.isLive = existingMetadata.isLive;
  }

  if (
    (!retVal.artist && !retVal.songOrShow) ||
    // use episode metadata for talk radio content
    (!isContentTypeAd(retVal.contentType) && item.data?.category !== 'Music')
  ) {
    const showImage = entity?.data?.parentImage?.square;
    retVal.songOrShow = (entity && entity.getTitle()) || '';
    retVal.artist = (entity && entity.getParentTitle()) || '';
    retVal.image = showImage || retVal.image;
  }

  return retVal;
};
