// styles.ts
const styles = {
  transcriptButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#383e56',
    borderRadius: '25px',
    height: '40px',
    padding: '0 16px',
    color: 'white',
    cursor: 'pointer',
    border: 'none',
  },
  icon: {
    fill: 'white',
    marginRight: '8px',
    width: '16px',
    height: '16px',
  },
};

export default styles;
