import React, { useEffect, useMemo, useState } from 'react';

import { IView } from '@audacy-clients/core/atoms/wrappers/modules';
import { ModuleProvider } from '@audacy-clients/core/components/ModuleRenderer/context';
import { useWindowVirtualizer } from '@tanstack/react-virtual';

import { IModuleFactoryOptions, useModuleFactory } from './hooks';

interface IProps<T> extends IModuleFactoryOptions<T> {
  onViewLoaded?: (view: IView) => void;
  render?: (view: IView, modules: JSX.Element) => JSX.Element;
  skipRendering?: boolean;
  virtual?: boolean;
}

const RootModule = <T,>({
  moduleMap,
  moduleWrapper,
  navigation,
  request,
  render,
  onNavigate,
  onViewLoaded,
  skipRendering,
  virtual,
}: IProps<T>): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(false);

  const factory = useModuleFactory({
    request,
    moduleMap,
    moduleWrapper,
    navigation,
    onNavigate,
  });

  /*
   * This useEffect avoids render loops when useState
   * is called from within the passed render prop
   */
  useEffect(() => {
    if (onViewLoaded && !isLoaded) {
      onViewLoaded(factory.view);
      setIsLoaded(true);
    }
  }, [factory, onViewLoaded, isLoaded]);

  let modules: JSX.Element = <></>;

  // Render virtual modules
  if (virtual) {
    // Window doesn't throw if we're just checking typeof
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof window === 'undefined') {
      throw new Error('Virtual modules are only implemented for the browser.');
    }

    // Rules of hooks are not violated if the condition is the platform
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const rowVirtualizer = useWindowVirtualizer({
      count: factory.modules.length,
      // Used to estimate when items need to be swapped out.
      // e.g. Window height is 1000, so 1000/300 = 3.3.
      // Then 3.3 + 3 overscan below = render 6.3 -> 7 items on first render.
      // Too many items = slow render, too few items or too fast scroll = empty page
      // For items that are rendered, this is overridden anyway.
      estimateSize: () => 300,
      overscan: 3,
    });

    modules = (
      <div
        // react-virtual is headless, so we implement the UI.
        // getTotalSize() simulates a full height scrolling page.
        // The visible items are rendered with absolute positioning and
        // translateY(virtualRow.start) so it scrolls with the parent.
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          height: rowVirtualizer.getTotalSize(),
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
          <div
            data-index={virtualRow.index}
            key={virtualRow.key}
            ref={rowVirtualizer.measureElement}
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${virtualRow.start}px)`,
              zIndex: 100 - virtualRow?.index,
            }}
          >
            {factory.modules[virtualRow.index].render()}
          </div>
        ))}
      </div>
    );
  }

  // Render all modules
  if (!virtual) {
    modules = <>{factory.modules.map((m) => m.render())}</>;
  }

  // Keeping previous behavior, but skipRendering is not even in use.
  if (skipRendering) {
    modules = <></>;
  }

  const providerValue = useMemo(
    () => ({ refreshTopLevelModules: factory.refresh }),
    [factory.refresh],
  );

  return (
    <ModuleProvider value={providerValue}>
      {render?.(factory.view, modules) ?? modules}
    </ModuleProvider>
  );
};

export default RootModule;
