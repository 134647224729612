import { PlaybacksHashMap } from '../dataServices/DataServices';
import { IHistoryEntry } from '../dataServices/types';

export const validateZipCode = (zipCode: string): boolean => /^\d{5}(-\d{4})?$/.test(zipCode);

/**
 * Create a new resume playback list by comparing localResumePoints and remoteResumePoints on the basis of greater value.
 *
 * @param {PlaybacksHashMap} localPlaybacks - The Playback resume points available on mobile local data.
 * @param {PlaybacksHashMap} remotePlaybacks - The Playback resume points resume from personalization API.
 * @returns {PlaybacksHashMap} The updated playback resume points list data.
 */

export const reconcilePlaybackResumePoints = (
  localResumePoints: PlaybacksHashMap,
  remoteResumePoints: PlaybacksHashMap,
) => {
  const newLocalResumePoints = Object.assign({}, localResumePoints);
  if (remoteResumePoints == undefined) {
    return localResumePoints;
  }
  Object.keys(remoteResumePoints).forEach((contentId) => {
    const localResumePoint = localResumePoints[contentId];
    const remoteResumePoint = remoteResumePoints[contentId];

    if (!localResumePoint || localResumePoint < remoteResumePoint) {
      newLocalResumePoints[contentId] = remoteResumePoint;
    }
  });

  return newLocalResumePoints;
};

export const reconcileHistory = (localHistory: IHistoryEntry[], itemsToAdd: IHistoryEntry[]) => {
  if (localHistory.length === 0) {
    return itemsToAdd;
  }

  const newLocalHistory = [...itemsToAdd] as IHistoryEntry[];
  const itemsToAddIds: Set<string> = new Set(itemsToAdd.map((item) => item.contentId));

  localHistory.forEach((item) => {
    if (!itemsToAddIds.has(item.contentId)) {
      newLocalHistory.push(item);
    }
  });

  return newLocalHistory;
};
