import { ObjectType } from '../Constants';
import DataObject from './DataObject';
import type Episode from './Episode';
import type Show from './Show';
import type Station from './Station';

/** @module StandaloneChapter */

export default class StandaloneChapter extends DataObject {
  /**
   * Creates a new instance of a StandaloneChapter.
   */
  constructor(data: {
    durationSeconds?: number;
    endDateTime?: string;
    endDateTimeSeconds?: number;
    entitySubtype: string;
    entityType: string;
    id: string;
    imageSquare?: string;
    parentEpisode?: Episode;
    parentImage?: {
      square?: string;
      alt?: string;
    };
    parentShow?: object;
    parentStation?: Station;
    parentTitle?: string;
    publishDate?: string;
    publishDateSeconds?: number;
    replayableUntilDateTime?: string;
    replayableUntilDateTimeSeconds?: number;
    startDateTime?: string;
    startDateTimeSeconds?: number;
    startOffset?: number;
    streamUrl?: {
      m3u8?: string;
    };
    isOnAirOnly?: boolean;
    title: string;
    url: string;
  }) {
    super(ObjectType.STANDALONE_CHAPTER, data);
    DataObject.cleanJsonObject(this.data);
  }

  /**
   * Get the audio url associated with the episode.
   */
  getAudioStream(): string {
    return this.data.streamUrl?.m3u8;
  }

  /**
   * Get the duration in seconds of the chapter.
   */
  getDuration(): number {
    return this.data.durationSeconds || 0;
  }

  /**
   * Get the end date and time of the chapter.
   */
  getEndTime(): string {
    return this.data.endDateTime;
  }

  /**
   * Get the end time of the chapter in milliseconds.
   */
  getEndTimeMillis(): number {
    return this.data.endDateTimeSeconds * 1000;
  }

  /**
   * Get the end date time in seconds of the chapter.
   */
  getEndTimeSeconds(): number {
    return this.data.endDateTimeSeconds;
  }

  /**
   * Get details of image square
   */
  getImageSquare(): string {
    return this.getParentImageSquare();
  }

  /**
   * Get details of the parent episode.
   */
  getParentEpisode(): Episode {
    return this.data.parentEpisode;
  }

  /**
   * Get details of the parent episode id.
   */
  getParentEpisodeId(): string {
    return this.data?.parentEpisode?.id ?? "";
  }

  /**
   * Get details of the parent episode url.
   */
  getParentEpisodeUrl(): string {
    return this.data?.parentEpisode?.url ?? "";
  }

  /**
   * Get the image alt of the parent.
   */
  getParentImageAlt(): string {
    return this.data.parentImage.alt;
  }

  /**
   * Get the image square url of the parent.
   */
  getParentImageSquare(): string {
    return this.data.parentImage.square;
  }

  /**
   * Get details of the parent show.
   */
  getParentShow(): Show {
    return this.data.parentShow;
  }

  /**
   * Get details of the parent show id.
   */
  getParentShowId(): string {
    return this.data.parentShow.id;
  }

  /**
   * Get parent show url.
   */
  getParentShowUrl(): string {
    return this.data.parentShow.url;
  }

  /**
   * Get details of the parent station.
   */
  getParentStation(): Station {
    return this.data.parentStation;
  }

  /**
   * Get details of the parent station.
   */
  getParentStationId(): string {
    return this.data.parentStation.id;
  }

  /**
   * Get the title of the parent.
   */
  getParentTitle(): string {
    return this.data.parentTitle;
  }

  /**
   * Get the publish date of the chapter.
   */
  getPublishDate(): string {
    return this.data.publishDate;
  }

  /**
   * Get the publish date in seconds of the chapter.
   */
  getPublishDateSeconds(): number {
    return this.data.publishDateSeconds;
  }

  /**
   * Get the date and time until which the chapter is replayable.
   */
  getReplayableUntil(): string {
    return this.data.replayableUntilDateTime;
  }

  /**
   * Get the replayable until date time in seconds.
   */
  getReplayableUntilDateTimeSeconds(): number {
    return this.data.replayableUntilDateTimeSeconds;
  }

  /**
   * Get the start date and time of the chapter.
   */
  getStartTime(): string {
    return this.data.startDateTime;
  }

  /**
   * Get the start date time in millis of the chapter.
   */
  getStartTimeMillis(): number {
    return this.data.startDateTimeSeconds * 1000;
  }
  /**
   * Get the start date time in seconds of the chapter.
   */
  getStartTimeSeconds(): number {
    return this.data.startDateTimeSeconds;
  }

  /**
   * Get the start offset of the chapter.
   */
  getStartOffset(): number {
    return this.data.startOffset;
  }

  /**
   * Get the stream URL of the chapter.
   */
  getStreamUrl(): string {
    return this.data.streamUrl?.m3u8;
  }

  isRewindable(): boolean {
    return true;
  }
}
