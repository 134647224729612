/** @typedef { import('../Constants').EntitySubtype     } EntitySubtype     */
/** @typedef { import('../Constants').Image             } Image             */
/** @typedef { import('../Constants').ISongHistoryItem   } ISongHistoryItem   */
import { get } from 'lodash';
import AudacyAuthError from '../AudacyAuthError';
import AudacyError from '../AudacyError';
import clientServicesConfig from '../Config';
import {
  ApiVersion,
  AuthState,
  ContentType,
  EntityType,
  Environment,
  EnvironmentHosts,
  EpisodeSubType,
  FOLLOWS,
  ModuleType,
  ObjectType,
  PLACEHOLDER_REGEX,
  PodcastSort,
  PodcastType,
  RefreshTokenStatus,
  StationSubType,
  StreamProvider,
  TWELVE_HOURS,
  ViewType,
  Vast,
} from '../Constants';
import Container from '../Container';
import Service from '../Service';
import { createUuid, fetchWithTimeout, removePlaceholderData, sha256 } from '../Utilities';
import { cache } from '../utils';
import { stationIdToPrtId } from '../utils/stationIdToPrtId';
import AtlHostsSearchResultList from './AtlHostsSearchResultList';
import Chapter from './Chapter';
import Clip from './Clip';
import Collection from './Collection';
import DataObject from './DataObject';
import Episode from './Episode';
import EpisodeList from './EpisodeList';
import SearchResultList from './SearchResultList';
import SegmentList from './SegmentList';
import Show from './Show';
import ShowSummary from './ShowSummary';
import SongList from './SongList';
import StandaloneChapter from './StandaloneChapter';
import Station from './Station';
import StationSummary from './StationSummary';
import Tag from './Tag';
import Topic from './Topic';
import { VASTClient } from '../adServices/vast';

const serviceBus = Container;
const logger = Container;
const onlineStatusProvider = Container;

/**
 * @typedef AuthParams
 * @property {String}  [accessToken]
 * @property {String}  [authorizationHeader]
 * @property {Boolean} isSoftDeleted
 * @property {String}  refreshToken
 * @property {String}  userId
 */

/**
 * @typedef {Object} Colors
 * @property {String} primary
 * @property {String} secondary
 */

/**
 * @typedef {Object} Host
 * @property {String} firstName
 * @property {String} id
 * @property {Image}  image
 * @property {String} lastName
 */

/**
 * @typedef {Object} AtlCallin
 * @property {String} dateCreated
 * @property {String} firstName
 * @property {String} id
 * @property {String} message
 * @property {String} memberId
 * @property {String} status
 */

/**
 * @typedef {Object} AtlLiveEpisode
 * @property {String}      dateCreated
 * @property {String}      dateStarted
 * @property {String}      description
 * @property {String}      hostChannel
 * @property {String}      hostNotificationChannel
 * @property {String}      hostNotificationToken
 * @property {Array<Host>} hosts
 * @property {String}      id
 * @property {Boolean}     isOnDemand
 * @property {String}      publicChannel
 * @property {Object}      publicRoom
 * @property {String}      publicRoom.hostToken
 * @property {Number}      publicRoom.id
 * @property {String}      publicRoom.signalWireRoomIdent
 * @property {String}      questionChannel
 * @property {AtlShow}     show
 * @property {String}      status
 * @property {String}      title
 */

/**
 * @typedef {Object} AtlShow
 * @property {String}      description
 * @property {Array<Host>} hosts
 * @property {String}      id
 * @property {Image}       image
 * @property {String}      title
 */

/**
 * @typedef {Object} CollectionJson
 * @property {String}        author
 * @property {String}        contentUpdated
 * @property {String}        description
 * @property {Number}        durationSeconds
 * @property {String}        entitySubtype
 * @property {String}        entityType
 * @property {String}        id
 * @property {Object}        images
 * @property {String}        images.alt
 * @property {String}        images.hero
 * @property {String}        images.heroAttribution
 * @property {String}        images.square
 * @property {String}        images.squareAttribution
 * @property {Array<String>} itemsIdList
 * @property {String}        title
 * @property {String}        url
 */

/**
 * @typedef {Object} HostShowDetails
 * @property {Host}           host
 * @property {Array<AtlShow>} shows
 */

/**
 * @typedef {Object} ShowSummary
 * @property {String}           entitySubtype
 * @property {String}           entityType
 * @property {Array<String>}    genres
 * @property {String}           id
 * @property {Object}           images
 * @property {Boolean}          isFav
 * @property {Array<String>}    parentGenres
 * @property {Object}           parentStation
 * @property {String}           parentTitle
 * @property {String}           podcastType
 * @property {String}           title
 * @property {String}           url
 */

/**
 * @typedef {Object} ShowJson
 * @property {String}       author
 * @property {String}       description
 * @property {String}       entitySubtype
 * @property {String}       entityType
 * @property {Boolean}      followable
 * @property {String}       id
 * @property {Object}       images
 * @property {String}       images.alt
 * @property {String}       images.square
 * @property {String}       metaDescription
 * @property {String}       metaTitle
 * @property {PodcastType}  podcastType
 * @property {Object}       parentStation
 * @property {String}       subtitle
 * @property {String}       title
 * @property {String}      [url]
 */

/**
 * @typedef {Object} StationSummary
 * @property {String}           entitySubtype
 * @property {String}           entityType
 * @property {Array<String>}    genres
 * @property {String}           id
 * @property {Object}           images
 * @property {Boolean}          isFav
 * @property {String}           marketTitle
 * @property {String}           title
 * @property {String}           url
 */

/**
 * @typedef {Object} StationJson
 * @property {String}          callsign
 * @property {String}          category
 * @property {Colors}          colors
 * @property {String}          description
 * @property {String}          entitySubtype
 * @property {String}          entityType
 * @property {Boolean}         followable
 * @property {Array<String>}   genres
 * @property {String}          id
 * @property {Object}          identifiers
 * @property {String}          identifiers.coreId
 * @property {String}          identifiers.tritonName
 * @property {Object}          images
 * @property {String}          images.alt
 * @property {String}          images.square
 * @property {String}          metaDescription
 * @property {String}          metaTitle
 * @property {String}          phoneNumber
 * @property {Boolean}         rewindable
 * @property {String}          siteSlug
 * @property {StreamProvider}  streamProviderName
 * @property {Object}          streamUrl
 * @property {String}         [streamUrl.aac]
 * @property {String}         [streamUrl.m3u8]
 * @property {String}         [streamUrl.mp3]
 * @property {String}          title
 * @property {String}         [url]
 */

/**
 * @typedef {Object}            FeaturedPlayable
 * @property {Episode|Station}  featuredPlayable.contentObj
 * @property {String}          [featuredPlayable.ctaTitle]
 */

/**
 * @typedef  {Object}  Meta
 * @property {String}  canonical
 * @property {String}  description
 * @property {String} [robots]
 * @property {Object}  social
 * @property {String}  social.description
 * @property {String} [social.image]
 * @property {String} [social.imageHeight]
 * @property {String} [social.imageWidth]
 * @property {String}  social.locale
 * @property {String} [social.siteName]
 * @property {String}  social.title
 * @property {String} [social.type]
 * @property {String}  title
 */

/**
 * @typedef {Object}           View
 * @property {ContentHashMap} [content]
 * @property {Show|Station}   [contentObj]
 * @property {String}          id
 * @property {Meta}            meta
 * @property {Array<Module>}  [modules]
 * @property {Status}         [status]
 * @property {ViewType}        type
 */

/**
 * @typedef {Object} Module
 * @property {String} moduleId
 * @property {ModuleType} moduleType
 * @property [config]
 * @property {Array<Module>} [modules]
 */

/**
 * @typedef Status
 * @property {Number} code
 * @property {String} message
 */

/**
 * @typedef {Object.<string,ContentSummary>} ContentHashMap
 */

/**
 * @typedef {Object.<string,Number>} PlaybacksHashMap
 */

/**
 * @typedef {Object.<string,Clip|Episode|Show|Station|Collection|StandaloneChapter>} FullContentHashMap
 */

/**
 * @typedef {Object} GetContentResult
 * @property {ContentHashMap|FullContentHashMap} content
 */

/**
 * @typedef {Object} FullContentResult
 * @property {FullContentHashMap} content
 */

/**
 * @typedef {Object} SummaryContentResult
 * @property {ContentHashMap} content
 */

/**
 * @typedef  {Object} ClientSettings
 *
 * @property {Object } global
 * @property {String } global.allEpisodes
 * @property {String } global.contactAudacy
 * @property {String } global.developerScreen
 * @property {String } global.landing
 * @property {String } global.login
 * @property {String } global.player
 * @property {String } global.privacyLegal
 * @property {String } global.profileAccount
 * @property {String } global.profileLocation
 * @property {String } global.profileNotification
 * @property {String } global.profileOpenSource
 * @property {String } global.profileOverview
 * @property {String } global.profileSecurity
 * @property {String } global.queue
 * @property {String } global.registrationAdditionalDetails
 * @property {String } global.registrationCreatePassword
 * @property {String } global.registrationEmailPassword
 * @property {String } global.registrationFacebookEmailError
 * @property {String } global.registrationName
 * @property {String } global.resetPasswordCreateNewPassword
 * @property {String } global.resetPasswordEnterEmail
 * @property {String } global.resetPasswordCheckEmail
 * @property {String } global.schedule
 * @property {String } global.search
 * @property {String } global.selectAuthMethod
 *
 * @property {Object } featureFlags
 * @property {Boolean} featureFlags.auth
 * @property {Boolean} featureFlags.chapters
 * @property {Boolean} featureFlags.follows
 * @property {Boolean} featureFlags.queue
 * @property {Boolean} featureFlags.recentHistory
 * @property {Boolean} featureFlags.search
 * @property {Boolean} featureFlags.settingsLinks
 * @property {Boolean} featureFlags.share
 *
 * @property {Object } [configuration]
 * @property {String } [configuration.adRefreshInterval]
 */

/**
 * @typedef ContentSummary
 * @property {String}         author
 * @property {Number}        [durationSeconds]
 * @property {EntitySubtype}  entitySubtype
 * @property {EntityType}     entityType
 * @property {Array<String>} [genres]
 * @property {String}         id
 * @property {Object}        [images]
 * @property {String}        [images.alt]
 * @property {String}        [images.square]
 * @property {Array<String>} [itemsIdList]
 * @property {String}        [marketTitle]
 * @property {Object}        [parentImage]
 * @property {String}        [parentImage.alt]
 * @property {String}        [parentImage.square]
 * @property {String}        [parentTitle]
 * @property {Boolean}       [playingNow]
 * @property {String}        [publishDate]
 * @property {String}         replayableUntilDateTime
 * @property {String}        [showContentId]
 * @property {String}        [startDateTime]
 * @property {String}         title
 * @property {String}        [updateDate]
 * @property {String}        [url]
 * @property {ContentSummary}[parentEpisode]
 * @property {ContentSummary}[parentShow]
 * @property {Boolean}       [isRewindable]
 */

/**
 * @typedef StubModuleData
 * @property {ContentHashMap}  content
 * @property {Array<Module>}   modules
 */

/**
 * @typedef {Object.<string,Array<PlayingEntry>>} RecentlyPlayed
 */

/**
 * @typedef PlayingEntry
 * @property {String} artist
 * @property {Object} image
 * @property {String} image.square
 * @property {String} image.alt
 * @property {String} title
 * @property {number} startDateTimeSeconds
 */

/**
 * @typedef {Object} CollectionSummary
 * @property {Array<Object>} clips
 * @property {String}        clips.contentId
 * @property {String}        clips.eyebrow
 * @property {Object}        config
 * @property {Object}        config.colors
 * @property {String}        config.colors.outline
 * @property {String}        config.colors.overlay
 * @property {String}        contentId
 * @property {"COLLECTION"}  contentType
 * @property {Boolean}       isFollowed
 * @property {Boolean}       isViewed
 */

/**
 * @typedef {Object} EpisodeSummary
 * @property {Object}    config
 * @property {Object}    config.colors
 * @property {String}    config.parentContentId
 * @property {String}    config.colors.outline
 * @property {String}    config.colors.overlay
 * @property {String}    contentId
 * @property {"EPISODE"} contentType
 * @property {Boolean}   isFollowed
 */

/**
 * @typedef {Object} MarketData
 * @property {String} marketId
 * @property {String} marketTitle
 */

/**
 * @module DataServices
 */
export default class DataServices extends Service {
  /**
   * @type {String|undefined} The current session ID
   */
  sessionId = undefined;

  get credentialsProvider() {
    return serviceBus.personalizationServices;
  }

  /**
   * Creates a new instance of DataServices.
   * @param {Object}       config
   * @param {Environment}  config.env
   * @param {Object}      [config.overrides]
   * @param {Object}      [config.overrides.podcastEpisode]
   * @param {ApiVersion}   config.ver
   */
  constructor(config) {
    super();
    this.logger = logger;
    this.ddLogger = logger.ddLogger;
    this.isTokenValid = RefreshTokenStatus.PENDING;

    this.envs = {
      [Environment.DEV]: `${EnvironmentHosts.DEV}/`,
      [Environment.PROD]: `${EnvironmentHosts.PROD}/`,
      [Environment.STG]: `${EnvironmentHosts.STG}/`,
      [Environment.QA]: `${EnvironmentHosts.QA}/`,
    };

    this.root = this.envs[config.env];
    this.env = config.env;
    this.version = config.ver;
    this.stats = {};

    this.overrides = {
      byCategory: { env: Environment.PROD, ver: ApiVersion.V1 },
      schedules: { env: Environment.PROD, ver: ApiVersion.V1 },
    };

    for (let key in config.overrides)
      if (config.overrides.hasOwnProperty(key)) this.overrides[key] = config.overrides[key];
  }

  /**
   * @param   {String} episodeId
   * @param   {String} callInRequestId
   * @returns {Promise}
   */
  acceptAtlCallin(episodeId, callInRequestId) {
    const startTime = Date.now();
    const { root } = this.getOverrides('acceptAtlCallin');
    const opts = this.dataServices.createAudacyBaseFetchOptions();
    const url =
      root +
      'anytime-live-creator-service/v1/creator/episode/' +
      episodeId +
      '/callin/' +
      callInRequestId +
      '/accept';

    const body = {
      episodeId: episodeId,
      callInRequestId: callInRequestId,
    };

    opts.method = 'PUT';
    opts.body = JSON.stringify(body);

    return this.getJsonAuthenticated(url, opts, true).then(() => {
      this.addStat('acceptAtlCallin', Date.now() - startTime);
    });
  }

  addFollows(array) {
    const { root } = this.getOverrides('addFollows');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/engagement/v1/follows';

    opts.method = 'POST';
    opts.body = JSON.stringify({ contentIds: array });

    return this.getJsonAuthenticated(url, opts);
  }

  addStat(key, time) {
    let struct = this.stats[key];

    if (struct) {
      struct.time += time;
      struct.count++;
      if (time > struct.slowest) struct.slowest = time;
      if (time < struct.fastest) struct.fastest = time;
      if (struct.lastTen.length < 10) struct.lastTen.push(time);
      else {
        struct.lastTen.shift();
        struct.lastTen.push(time);
      }
    } else {
      struct = this.stats[key] = {};
      struct.time = time;
      struct.count = 1;
      struct.slowest = time;
      struct.fastest = time;
      struct.lastTen = [time];
    }
  }

  /**
   * @param {Array<IHistoryEntry>} entries
   * @returns {Promise<Array<IHistoryEntry>>}
   */
  addToHistory(entries) {
    const startTime = Date.now();
    const { root } = this.getOverrides('addToHistory');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/engagement/v1/history';

    opts.method = 'POST';
    opts.body = JSON.stringify({ listenHistory: entries });

    return this.getJsonAuthenticated(url, opts).then((response) => {
      this.addStat('addToHistory', Date.now() - startTime);
      return response;
    });
  }

  /**
   * Processes unfollows sequentially as a workaround to a known bug.
   * To be updated post implementation of bulk-unfollow endpoint by Identity.
   * @param {Array} contentIds
   */
  bulkUnfollow(contentIds) {
    const processSequentialRequest = async (previousRequest, contentId) => {
      await previousRequest;
      return this.deleteFollow(contentId);
    };

    return contentIds.reduce(processSequentialRequest, Promise.resolve());
  }

  /**
   * @param   {String} episodeId
   * @param   {String} callInRequestId
   * @returns {Promise}
   */
  cancelAtlCallin(episodeId, callInRequestId) {
    const startTime = Date.now();
    const { root } = this.getOverrides('cancelAtlCallin');
    const opts = this.dataServices.createAudacyBaseFetchOptions();
    const url =
      root +
      'anytime-live-creator-service/v1/listener/episode/' +
      episodeId +
      '/callin/' +
      callInRequestId +
      '/cancel';

    const body = {
      episodeId: episodeId,
      callInRequestId: callInRequestId,
    };

    opts.method = 'PUT';
    opts.body = JSON.stringify(body);

    return this.getJsonAuthenticated(url, opts, true).then(() => {
      this.addStat('cancelAtlCallin', Date.now() - startTime);
    });
  }

  /**
   * @returns {Promise}
   */
  clearHistory() {
    const startTime = Date.now();
    const { root } = this.getOverrides('clearHistory');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/engagement/v1/history';

    opts.method = 'DELETE';

    return this.getJsonAuthenticated(url, opts).then((response) => {
      this.addStat('clearHistory', Date.now() - startTime);
      return response;
    });
  }

  /**
   * Creates an anonymous user and gets the associated uuid.
   * @returns {Promise<String>}
   */
  createAnonymousUser() {
    // TODO: [CCS-2789] connect to the backend service when it exists, fake it until then

    const resolver = (resolve) => {
      setTimeout(() => {
        const hash = sha256(createUuid());

        resolve(`f8k:${hash.substr(3, 32)}`);
      }, 500);
    };

    return new Promise(resolver);
  }

  /**
   * Create a default options object for use in a network call.
   * @param {String} [userToken]
   * @param {String} [correlationId]
   * @param {String} [language]
   */
  createAudacyBaseFetchOptions(
    userToken = this.credentialsProvider.userToken,
    correlationId = createUuid(),
    language = 'en-US',
  ) {
    const now = Date.now();

    if (this.lastInteraction === undefined || this.lastInteraction + 1800000 < now) {
      this.sessionId = this.createSessionId();
      this.lastInteraction = now;
    }

    return {
      mode: 'cors',
      headers: {
        Accept: '*/*',
        'Accept-Language': language,
        'Aud-Correlation-Id': correlationId,
        'Aud-Platform': this.platform,
        'Aud-Platform-Variant': this.platformVariant,
        'Aud-Client-Session-ID': this.sessionId,
        'Aud-User-Token': userToken,
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'origin',
    };
  }

  static createAudacyError(error, url, opts, code = 0) {
    const obj = {
      code: code,
      correlationId: opts && opts.headers['Aud-Correlation-Id'],
      domain: '', // TODO: [CCS-2790] parse url to figure out domain? network status?
      errors: [error],
      message: 'An unknown error has occurred calling ' + url,
      sessionId: opts && opts.headers['Aud-Client-Session-ID'],
      status: 'UNKNOWN_ERROR',
    };

    return new AudacyError(obj.message, obj);
  }

  createChatFetchOptions() {
    // TODO: [CCS-2791] this function is temporary - the backend expects 'Aud-Client-Session-Id' instead of 'Aud-Session-Id'
    let opts = this.createAudacyBaseFetchOptions();
    opts.headers['Aud-Session-Id'] = opts.headers['Aud-Client-Session-ID'];
    return opts;
  }

  static createEntity(data) {
    let retval;

    switch (data.entityType) {
      case EntityType.COLLECTION:
        retval = new Collection(data);
        break;

      case EntityType.EPISODE:
        retval = new Episode(data);
        break;

      case EntityType.SHOW:
        retval = new Show(data);
        break;

      case EntityType.STATION:
        retval = new Station(data);
        break;

      case EntityType.TAG:
        retval = new Tag(data);
        break;

      case EntityType.STANDALONE_CHAPTER:
        retval = new StandaloneChapter(data);
        break;

      case EntityType.TOPIC:
        retval = new Topic(data);
        break;

      default:
        retval = new DataObject(ObjectType.UNKNOWN, data);
        logger.warn('Unsupported entity type = ' + data.entityType);
        break;
    }

    return retval;
  }

  createSessionId() {
    const unformatted =
      sha256(
        this.credentialsProvider.userToken ? this.credentialsProvider.userToken : createUuid(),
      ).substr(0, 20) + sha256(Date.now() + '').substr(0, 12);

    return (
      unformatted.substr(0, 8) +
      '-' +
      unformatted.substr(8, 4) +
      '-' +
      unformatted.substr(12, 4) +
      '-' +
      unformatted.substr(16, 4) +
      '-' +
      unformatted.substr(20)
    );
  }

  deleteChatMessage(sessionId, messageId) {
    const { root } = this.getOverrides('deleteChatMessage');
    const opts = this.createChatFetchOptions(); //this.dataServices.createAudacyBaseFetchOptions();
    const url = `${root}/chat-publish/v1/publish/${sessionId}/text/${messageId}`;

    opts.method = 'DELETE';

    return this.getJsonAuthenticated(url, opts);
  }

  deleteFollow(id) {
    const { root } = this.getOverrides('deleteFollow');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/engagement/v1/follows/' + id;

    opts.method = 'DELETE';

    return this.getJsonAuthenticated(url, opts);
  }

  /**
   * Soft Deletes a User
   */
  deleteUser() {
    const startTime = Date.now();
    const { root } = this.getOverrides('deleteUser');
    const opts = this.createAudacyBaseFetchOptions();
    let url = `${root}identity/profile/v1/users/me`;

    opts.method = 'DELETE';

    return this.getJsonAuthenticated(url, opts).then((response) => {
      this.addStat('deleteUser', Date.now() - startTime);
      return response;
    });
  }

  editChatMessage(sessionId, messageId, text) {
    const { root } = this.getOverrides('editChatMessage');
    const opts = this.createChatFetchOptions(); //this.dataServices.createAudacyBaseFetchOptions();
    const url = `${root}/chat-publish/v1/publish/${sessionId}/text/${messageId}`;

    const postBody = {
      text: text,
    };

    opts.method = 'PATCH';
    opts.body = JSON.stringify(postBody);

    return this.getJsonAuthenticated(url, opts);
  }

  /**
   * @param   {String} episodeId
   * @returns {Promise<AtlLiveEpisode>}
   */
  endAtlEpisode(episodeId) {
    const startTime = Date.now();
    const { root } = this.getOverrides('endAtlEpisode');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'anytime-live-creator-service/v1/creator/end-episode';

    opts.method = 'POST';
    const postBody = {
      episodeId: episodeId,
    };
    opts.body = JSON.stringify(postBody);

    return this.getJsonAuthenticated(url, opts).then((episode) => {
      this.addStat('endAtlEpisode', Date.now() - startTime);
      return episode;
    });
  }

  /**
   * @param   {String} episodeId
   * @returns {Promise<Array<AtlCallin>>}
   */
  getAtlCreatorCallins(episodeId) {
    const startTime = Date.now();
    const { root } = this.getOverrides('getAtlCreatorCallins');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'anytime-live-creator-service/v1/creator/episode/' + episodeId + '/callin';

    return this.getJsonAuthenticated(url, opts).then((response) => {
      this.addStat('getAtlCreatorCallins', Date.now() - startTime);
      return response.callIns;
    });
  }

  /**
   * @returns {Promise<HostShowDetails>}
   */
  getAtlHostShowDetails() {
    const startTime = Date.now();
    const { root } = this.getOverrides('getHostShowDetails');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'anytime-live-creator-service/v1/creator/host-show-details';

    return this.getJsonAuthenticated(url, opts).then((details) => {
      this.addStat('getAtlHostShowDetails', Date.now() - startTime);
      return details;
    });
  }

  /**
   * @returns {Promise<Array<AtlLiveEpisode>>}
   */
  getAtlLiveEpisodes() {
    const startTime = Date.now();
    const { root } = this.getOverrides('getAtlLiveEpisodes');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'anytime-live-creator-service/v1/creator/episodes';

    return this.getJsonAuthenticated(url, opts).then(
      /**
       * @param {Object}                episodes
       * @param {Array<AtlLiveEpisode>} episodes.episodes
       * @returns {Array<AtlLiveEpisode>}
       */
      (episodes) => {
        this.addStat('getAtlLiveEpisodes', Date.now() - startTime);
        return episodes.episodes;
      },
    );
  }

  /**
   * @params {Number} [limit=10]
   * @returns {Promise<Array<Host>>}
   */
  getAtlRecentInvitees(limit = 10) {
    const startTime = Date.now();
    const { root } = this.getOverrides('getRecentInvitees');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'anytime-live-creator-service/v1/creator/recent-invitees?limit=' + limit;

    return this.getJsonAuthenticated(url, opts).then((invitees) => {
      this.addStat('getAtlRecentInvitees', Date.now() - startTime);
      return invitees;
    });
  }

  /**
   * Returns the audio preroll ad object
   * @param {string} id
   * @param {string} env
   */
  static async getAudioPreroll(tritonName, env) {
    const vastClient = new VASTClient(0, 0);
    const url = env === 'PROD' ? Vast.VAST_URL : Vast.QA_VAST_URL;
    // in the Vast enum in constants you can use the google test ad to test a fully featured Vast response
    const audioPrerollUrl = `${url}${tritonName}`;

    const xml = await vastClient.get(audioPrerollUrl);
    const creatives = get(xml, 'ads[0].creatives', []);

    if (creatives.length === 0) {
      const preroll = {
        adStartTrackers: [],
        creativeUrl: '',
        companionAd: '',
        companionAdClickThroughTracker: '',
      };
      return preroll;
    }

    // get companion ad url + click through tracker
    const companion = creatives.filter((creative) => creative.type === 'companion');
    let companionAdUrl = '';
    let clickThroughTracker = '';
    if (companion.length > 0) {
      const resource =
        companion[0]?.variations.filter((variation) => variation.staticResource) || '';
      if (resource && resource[0]) companionAdUrl = resource[0].staticResource || '';
      if (resource && resource[0])
        clickThroughTracker = resource[0].companionClickThroughURLTemplate || '';
    }

    // get the ad url
    const linear = creatives.filter((creative) => creative.type === 'linear');
    let ad = [];
    let creativeAdUrl = '';
    if (linear.length > 0) {
      ad =
        linear[0].mediaFiles.filter((file) => {
          return file.fileURL && file.fileURL.includes('https:');
        }) || [];
      creativeAdUrl = ad[0].fileURL || '';
    }

    const preroll = {
      adStartTrackers: get(xml, Vast.IMPRESSION_TEMPLATES, []),
      creativeUrl: creativeAdUrl,
      companionAd: companionAdUrl,
      companionAdClickThroughTracker: clickThroughTracker,
    };
    return preroll;
  }

  /**
   * @param {String} contentId
   * @returns {Promise<Array<Chapter>>}
   */
  getChapters(contentId) {
    const resolver = (resolve) => {
      if (contentId !== undefined) {
        const startTime = Date.now();
        const { root } = this.getOverrides('getChapters');
        const opts = this.createAudacyBaseFetchOptions();
        const url = `${root}experience/v1/content/${contentId}/chapters`;

        const onChapters = ({ chapters }) => {
          this.addStat('getChapters', Date.now() - startTime);

          const retval = [];

          if (chapters)
            for (let i = 0; i < chapters.length; i++) retval.push(new Chapter(chapters[i]));

          resolve(retval);
        };

        DataServices.getJson(url, opts)
          .then(onChapters)
          .catch(() => resolve([]));
      } else resolve([]);
    };

    return new Promise(resolver);
  }

  getChatConfig(sessionId) {
    const resolver = (resolve) => {
      const { root } = this.getOverrides('getChatConfig');
      const opts = this.createChatFetchOptions(); //this.dataServices.createAudacyBaseFetchOptions();
      const url = `${root}/chat-subscribe/v1/config/${sessionId}`;

      const onConfig = (config) => {
        if (config) {
          resolve(config);
        } else resolve([]);
      };

      this.getJsonAuthenticated(url, opts).then(onConfig);
    };

    return new Promise(resolver);
  }

  getChatQuickReactionRollups(sessionId, offset, page, maxEvents, fetchBackward) {
    const resolver = (resolve) => {
      const { root } = this.getOverrides('getChatQuickReactionRollups');
      const opts = this.createChatFetchOptions(); //this.dataServices.createAudacyBaseFetchOptions();
      const queryString = `?start-ms-offset=${offset}&page=${page}&max-events=${maxEvents}&fetch-backward=${fetchBackward}`;
      const url = `${root}/chat-history/v1/chat/${sessionId}/quick-reaction-rollups${queryString}`;

      const onResponse = (response) => {
        if (response) {
          this.eventListener && this.eventListener({ type: 'messagePosted', message: response });
          resolve(response);
        } else resolve([]);
      };

      this.getJsonAuthenticated(url, opts).then(onResponse);
    };

    return new Promise(resolver);
  }

  getChatQuickReactionsSummary(sessionId) {
    const resolver = (resolve) => {
      const { root } = this.getOverrides('getChatQuickReactionsSummary');
      const opts = this.createChatFetchOptions(); //this.dataServices.createAudacyBaseFetchOptions();
      const url = `${root}/chat-history/v1/chat/${sessionId}/quick-reactions-summary`;

      const onResponse = (response) => {
        if (response) resolve(response);
        else resolve([]);
      };

      this.getJsonAuthenticated(url, opts).then(onResponse);
    };

    return new Promise(resolver);
  }

  getChatTextEvents(sessionId, offset, page, maxEvents, fetchBackward) {
    const resolver = (resolve) => {
      const { root } = this.getOverrides('getChatTextEvents');
      const opts = this.createChatFetchOptions(); //this.dataServices.createAudacyBaseFetchOptions();
      const url = `${root}/chat-history/v1/chat/${sessionId}/text-events?start-ms-offset=${offset}&page=${page}&max-events=${maxEvents}&fetch-backward=${fetchBackward}`;

      const onEvents = (events) => {
        if (events) {
          resolve(events);
        } else resolve([]);
      };

      this.getJsonAuthenticated(url, opts).then(onEvents);
    };

    return new Promise(resolver);
  }

  /**
   * @typedef {Object}    ClientViewMapSubtypeEntry
   * @property {String}   viewId
   * @property {ViewType} viewType
   */

  /**
   * @typedef {Object.<string,ClientViewMapSubtypeEntry>} ClientViewMapTypeEntry
   */

  /**
   * @typedef {Object.<string,ClientViewMapTypeEntry>} ClientViewMap
   */

  /**
   * @typedef {Object}    ClientNavigationEntry
   * @property {String}   iconRef
   * @property {String}   label
   * @property {String}   viewId
   * @property {ViewType} viewType
   */

  /**
   * @typedef {Object} ClientNavigationTwo
   *
   * @property {ClientViewMap } viewMap
   * @property {Array<ClientNavigationEntry> } tabs
   */

  /**
   * @typedef {Object} ClientNavigation
   *
   * @property {Object } featureFlags
   * @property {Boolean} featureFlags.follows
   * @property {Boolean} featureFlags.queue
   * @property {Boolean} featureFlags.search
   * @property {Object } global
   * @property {String } global.contactAudacy
   * @property {String } global.player
   * @property {String } global.privacyLegal
   * @property {String } global.profileAccount
   * @property {String } global.profileLocation
   * @property {String } global.profileNotification
   * @property {String } global.profileOverview
   * @property {String } global.profileSecurity
   * @property {String } global.queue
   * @property {String } global.schedule
   * @property {String } global.search
   * @property {Array<ClientNavigationEntry>} tabs
   * @property {ClientViewMap} viewMap
   */

  /**
   * @returns {Promise<ClientNavigationTwo>}
   */
  getClientNavigationTwo() {
    const startTime = Date.now();
    const { root } = this.getOverrides('getClientNavigationTwo');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'experience/v1/config/navigation';

    return DataServices.getJson(url, opts).then((data) => {
      this.addStat('getClientNavigationTwo', Date.now() - startTime);
      return data;
    });
  }

  /**
   * @returns {Promise<ClientNavigation>}
   */
  getClientNavigation() {
    const startTime = Date.now();
    const { root } = this.getOverrides('getClientNavigation');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'experience/v1/navigation';

    return DataServices.getJson(url, opts).then((data) => {
      this.addStat('getClientNavigation', Date.now() - startTime);
      return data;
    });
  }

  /**
   * @returns {Promise<ClientSettings>}
   */
  getClientSettings() {
    const startTime = Date.now();
    const { root } = this.getOverrides('getClientSettings');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'experience/v1/config/settings';

    return DataServices.getJson(url, opts).then((data) => {
      this.addStat('getClientSettings', Date.now() - startTime);
      return data;
    });
  }

  /**
   * @param {String} contentId
   * @returns {Promise<ShowJson|StationJson>}
   */
  getContentJsonObject(contentId) {
    const resolver = (resolve, reject) => {
      const { root } = this.getOverrides('getContentJsonObject');
      const opts = this.createAudacyBaseFetchOptions();
      const url = `${root}experience/v1/content?contentId=${contentId}&objectType=${ContentType.FULL}`;
      const startTime = Date.now();

      Promise.all([
        DataServices.getJson(url, opts),
        this.personalizationServices.getFollows(),
      ]).then(([response, follows]) => {
        const data = response.content && response.content[0];

        if (data) {
          DataObject.cleanJsonObject(data); // this should go away once data objects are fixed

          data.isFav = follows.indexOf(data.id) !== -1;

          resolve(data);
        } else if (response) reject(response);

        this.addStat('getContentJsonObject', Date.now() - startTime);
      });
    };

    return new Promise(resolver);
  }

  /**
   * @typedef {Object.<string,ShowJson|StationJson>} FullContentJsonResult
   */

  /**
   * @param {Array<String>} contentIds
   * @returns {Promise<FullContentJsonResult>}
   */
  getContentJsonObjects(contentIds) {
    const resolver = (resolve, reject) => {
      const { root } = this.getOverrides('getContentJsonObjects');
      const opts = this.createAudacyBaseFetchOptions();
      const url = `${root}experience/v1/content?contentId=${contentIds.join('|')}&objectType=${
        ContentType.FULL
      }`;
      const startTime = Date.now();

      Promise.all([
        DataServices.getJson(url, opts),
        this.personalizationServices.getFollows(),
      ]).then(([response, follows]) => {
        const data = response.content;
        const map = {};

        if (data) {
          data.forEach((entry) => {
            DataObject.cleanJsonObject(entry); // this should go away once data objects are fixed

            entry.isFav = follows.indexOf(entry.id) !== -1;

            map[entry.id] = entry;
          });
        } else if (response) reject(response);

        this.addStat('getContentJsonObjects', Date.now() - startTime);

        resolve(map);
      });
    };

    return new Promise(resolver);
  }

  /**
   * Get the content object associated with the id.
   * @param {String} contentId
   * @returns {Promise<Clip|Episode|Show|Station|StandaloneChapter|Collection>}
   */
  getContentObject(contentId) {
    return this.getContent([contentId]).then((result) => {
      return result && result.content && result.content[contentId];
    });
  }

  /**
   * Get the content objects associated with the id(s).
   * @param {Array<string>}  contentIds
   * @returns {Promise<FullContentResult>}
   */
  getContentObjects(contentIds) {
    return this.getContent(contentIds, ContentType.FULL);
  }

  /**
   * Get the content summary objects associated with the id(s).
   * @param {Array<string>}  contentIds
   * @returns {Promise<SummaryContentResult>}
   */
  getContentSummaries(contentIds) {
    return this.getContent(contentIds, ContentType.SUMMARY);
  }

  /**
   * Get the content summary object associated with the id.
   * @param {String} contentId
   * @param {Boolean} [useSummaryObjects=false]
   * @returns {Promise<ContentSummary>|Promise<StationSummary>|Promise<ShowSummary>}
   */
  getContentSummary(contentId, useSummaryObjects = false) {
    return this.getContent([contentId], ContentType.SUMMARY, useSummaryObjects).then((result) => {
      return result && result.content && result.content[contentId];
    });
  }

  /**
   * Get the content object associated with the id(s).
   * @param {Array<string>}  contentIds
   * @param {ContentType  } [type=ContentType.FULL]
   * @param {Boolean}       [useSummaryObjects=false]
   * @returns {Promise<GetContentResult>}
   */
  getContent(contentIds, type = ContentType.FULL, useSummaryObjects = false) {
    if (!contentIds?.length) return Promise.resolve({ content: {} });

    const { root } = this.getOverrides('getContent');
    const opts = this.createAudacyBaseFetchOptions();
    const url = `${root}experience/v1/content?contentId=${contentIds.join('|')}&objectType=${type}`;
    const startTime = Date.now();

    const onData = ([results, follows]) => {
      const map = {};

      if (results.content) {
        if (type === ContentType.FULL) {
          results.content.forEach((contentObj) => {
            if (contentObj) {
              switch (contentObj.entityType) {
                case EntityType.CLIP:
                  map[contentObj.id] = new Clip(contentObj);
                  break;

                case EntityType.COLLECTION:
                  map[contentObj.id] = new Collection(contentObj);
                  break;

                case EntityType.EPISODE:
                  map[contentObj.id] = new Episode(contentObj);
                  break;

                case EntityType.SHOW:
                  map[contentObj.id] = new Show(contentObj);
                  break;

                case EntityType.STATION:
                  map[contentObj.id] = new Station(contentObj);
                  break;

                case EntityType.TAG:
                  map[contentObj.id] = new Tag(contentObj);
                  break;

                case EntityType.STANDALONE_CHAPTER:
                  map[contentObj.id] = new StandaloneChapter(contentObj);
                  break;

                case EntityType.TOPIC:
                  map[contentObj.id] = new Topic(contentObj);
                  break;

                default:
                  logger.error("Don't know what to do with content object = ", contentObj);
                  clientServicesConfig.ddError(
                    `Unknown Entity Type: ${JSON.stringify(contentObj)}`,
                  );
                  break;
              }
            }
          });

          for (let obj of Object.values(map))
            obj.markAsFavorite(follows.indexOf(obj.getId()) !== -1);
        } else if (type === ContentType.SUMMARY && useSummaryObjects) {
          results.content.forEach((contentObj) => {
            if (contentObj) {
              switch (contentObj.entityType) {
                case EntityType.STATION:
                  map[contentObj.id] = new StationSummary(contentObj);
                  break;

                case EntityType.SHOW:
                  map[contentObj.id] = new ShowSummary(contentObj);
                  break;

                default:
                  logger.error("Don't know what to do with content object = ", contentObj);
                  clientServicesConfig.ddError(
                    `No Summary Available: ${JSON.stringify(contentObj)}`,
                  );
                  break;
              }
            }
          });

          for (let obj of Object.values(map))
            obj.markAsFavorite(follows.indexOf(obj.getId()) !== -1);
        } else {
          results.content.forEach((contentObj) => {
            contentObj.isFav = follows.indexOf(contentObj.id) !== -1;
            map[contentObj.id] = contentObj;
          });
        }

        this.addStat('getContent', Date.now() - startTime);
      }

      return { content: map };
    };

    return Promise.all([
      DataServices.getJson(url, opts),
      this.personalizationServices.getFollows(),
    ]).then(onData);
  }

  /**
   * @param {Number|String} id
   * @returns {Promise<EpisodeList>}
   */
  getCurrentEpisodes(id) {
    const startTime = Date.now();
    const { root } = this.getOverrides('getCurrentEpisodes');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'experience/v1/content/' + id + '/schedule';

    return DataServices.getJson(url, opts).then((data) => {
      this.addStat('getCurrentEpisodes', Date.now() - startTime);

      const entries = data && data.schedule ? data.schedule : [];
      const now = Date.now() / 1000;
      const filtered = [];

      for (let i = 0; i < entries.length; i++)
        if (entries[i].startDateTimeSeconds <= now) filtered.push(entries[i]);

      return new EpisodeList(
        'v3',
        DataServices.makeSchedulesPagedResponse(id, filtered),
        undefined,
        undefined,
        false,
      );
    });
  }

  /**
   * Retrieve a json object from a url.
   * @param {String} url
   * @param {Object} opts
   * @return {Promise<Object>}
   */
  static async getJson(url, options = {}) {
    try {
      const response = await fetchWithTimeout(url, options);
      if (response.status >= 400) {
        const error = await response.json();
        this.ddLogger?.error(
          `Call to ${url} failed with status: ${response.status} and message: ${error.message}`,
          error,
        );
        throw new AudacyError(
          `call failed with status: ${response.status} and message: ${error.message}`,
          error,
        );
      } else if (response.status !== 204) {
        this.ddLogger?.info(`Success: To API url: ${url} with status: ${response.status}`);
        return response.json();
      }
    } catch (error) {
      this.ddLogger?.error(
        `Request failed with error: ${error.name}, ${error.message}, URL: ${url}`,
      );
      throw new Error(`Request failed with error: ${error.name}, ${error.message}, URL: ${url}`);
    }
  }

  async getJsonAuthenticated(url, opts, ignoreResponse = false, expectResponse = false) {
    const reauthenticate = () => {
      return this.triggerRefreshToken()
        .then(() => this.getJsonAuthenticated(url, opts, ignoreResponse, expectResponse))
        .catch((e) => {
          // if we throw during the reauthenticate call the token is invalid
          if (this.isTokenValid === RefreshTokenStatus.PENDING) {
            this.isTokenValid = RefreshTokenStatus.UNAUTHENTICATED;
          }
        });
    };

    opts.credentials = 'omit';
    opts.headers.Authorization = this.credentialsProvider.bearerHeader;

    const origToken = this.credentialsProvider.token;

    try {
      const response = await fetchWithTimeout(url, opts);
      switch (response.status) {
        case 200:
        case 201:
          this.ddLogger?.info(
            `Success: api request with url: ${url}, with status code: ${response.status}`,
          );
          if (response.url.indexOf('oauth2/authorize') >= 0) return reauthenticate();

          // 200 & 201 responses mean the token is valid
          if (this.isTokenValid === RefreshTokenStatus.PENDING) {
            this.isTokenValid = RefreshTokenStatus.AUTHENTICATED;
          }

          if ((response.status !== 201 && ignoreResponse === false) || expectResponse === true) {
            return response.json();
          }
          break;

        case 204:
          this.ddLogger?.info(
            `Success: api request with url: ${url}, with status code: ${response.status}`,
          );
          // 204 response means the token is valid
          if (this.isTokenValid === RefreshTokenStatus.PENDING) {
            this.isTokenValid = RefreshTokenStatus.AUTHENTICATED;
          }
          break;

        case 401:
          // If the refresh token has changed since the call was issued, another call has refreshed it,
          // no need to refresh it again. Go directly to the retry.
          if (this.credentialsProvider.token && origToken !== this.credentialsProvider.token) {
            this.ddLogger?.info(
              `Refresh token updated while requesting for url: ${url}, re-requesting...`,
            );
            return this.getJsonAuthenticated(url, opts, ignoreResponse, expectResponse);
          }
          // Will remove this log once FusionAuth login loop issue is solved
          console.info('Got a 401, reauthenticating...');
          return reauthenticate();

        default:
          const error = await response.json();
          // error case, token is not valid
          if (this.isTokenValid === RefreshTokenStatus.PENDING) {
            this.isTokenValid = RefreshTokenStatus.UNAUTHENTICATED;
          }
          this.ddLogger?.error(
            `Call to ${url} failed with status: ${response.status} and message: ${error?.message}`,
          );
          throw new AudacyError(
            `call to ${url} failed with status: ${response.status} and message: ${error?.message}`,
            error,
          );
      }
    } catch (error) {
      // thrown error from some other part of the request, token is not valid
      if (this.isTokenValid === RefreshTokenStatus.PENDING) {
        this.isTokenValid = RefreshTokenStatus.UNAUTHENTICATED;
      }
      throw new Error(`Request failed with error: ${error.name}, ${error.message}, URL: ${url}`);
    }
  }

  /**
   * Get the landing screen.
   * @return {Promise<View>}
   */
  getLandingPage() {
    return this.getView('for-you');
  }

  /**
   * @returns {Promise<String>}
   */
  getLogoutUrl() {
    const startTime = Date.now();
    const { root } = this.getOverrides('getLogoutUrl');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/logout';

    if (this.platform === 'WEB') url += '?parent=' + origin.split('//')[1];

    opts.headers['Refresh-Token'] = this.credentialsProvider.token;

    return DataServices.getJson(url, opts).then((url) => {
      this.addStat('getLogoutUrl', Date.now() - startTime);
      return url.frontChannelLogoutUrl;
    });
  }

  /**
   * @typedef {Object.<string,NowPlayingEntry>} NowPlaying
   */

  /**
   * @typedef NowPlayingEntry
   * @property {String} artist
   * @property {String} image
   * @property {String} songOrShow
   * @property {String} type
   */

  /**
   * Get the current now playing information for specified station.
   * @param {Array|String} idOrIds - a single station id or array of station ids.
   * @returns {Promise<NowPlaying>}
   */
  getNowPlaying(idOrIds) {
    const startTime = Date.now();
    const { root } = this.getOverrides('getNowPlaying');
    const opts = this.createAudacyBaseFetchOptions();
    const entries = Array.isArray(idOrIds) ? idOrIds.join('|') : idOrIds;
    const url = root + 'experience/v1/now-playing?contentIds=' + entries;

    const onResponse = (data) => {
      this.addStat('getNowPlaying', Date.now() - startTime);
      return data;
    };

    return DataServices.getJson(url, opts).then(onResponse);
  }

  /**
   * @typedef {Object} PersonalizedModules
   */

  /**
   * @param {String}       id
   * @param {Number}      [page=0]
   * @param {PodcastSort} [sort=PodcastSort.DATE_DESC]
   * @param {Boolean}     [bust=false]
   * @returns {Promise<EpisodeList>}
   */
  getEpisodes(id, page = 0, sort = PodcastSort.DATE_DESC, bust = false) {
    const startTime = Date.now();
    const { root } = this.getOverrides('getEpisodes');
    const opts = this.createAudacyBaseFetchOptions();
    const url =
      root +
      'experience/v1/content/' +
      id +
      '/episodes?page=' +
      page +
      '&sort=' +
      sort +
      (bust ? '&bust=' + createUuid() : '');

    const queryFunction = (page) => {
      return this.getEpisodes(id, page, sort);
    };

    return DataServices.getJson(url, opts).then((response) => {
      this.addStat('getEpisodes', Date.now() - startTime);

      if (response && response.results) {
        const entries = response.results;

        for (let i = 0; i < entries.length; i++) {
          if (entries[i].entitySubtype === EpisodeSubType.BROADCAST_SHOW_EPISODE) {
            if (entries[i].startDateTimeSeconds === undefined)
              entries[i].startDateTimeSeconds = new Date(entries[i].startDateTime).getTime() / 1000;

            if (entries[i].endDateTimeSeconds === undefined) {
              if (entries[i].endDateTime === undefined) {
                entries[i].endDateTimeSeconds =
                  entries[i].startDateTimeSeconds + entries[i].durationSeconds;
                entries[i].endDateTime = new Date(
                  entries[i].endDateTimeSeconds * 1000,
                ).toISOString();
              } else
                entries[i].endDateTimeSeconds = new Date(entries[i].endDateTime).getTime() / 1000;
            }
          }
        }
      }

      return new EpisodeList('v3', response ? response : [], this, queryFunction);
    });
  }

  /**
   * @returns {Promise<Array<String>>}
   */
  getFollows = async () => {
    const cachedData = cache.get(FOLLOWS);

    if (cachedData) {
      return cachedData;
    }

    const { root } = this.getOverrides('getFollows');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/engagement/v1/follows';

    let data;
    try {
      data = await this.getJsonAuthenticated(url, opts);
    } catch (e) {
      // the dd logger fires in getJsonAuthenticated function, so I don't think we need additional logging here
      return [];
    }

    // if there is data to cache, cache it
    if (data?.contentIds) cache.set(FOLLOWS, data.contentIds, TWELVE_HOURS);
    return data.contentIds;
  };

  /**
   * @returns {Promise<Array<IHistoryEntry>>}
   */
  getHistory() {
    const startTime = Date.now();
    const { root } = this.getOverrides('getHistory');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/engagement/v1/history';

    return this.getJsonAuthenticated(url, opts).then((response) => {
      this.addStat('getHistory', Date.now() - startTime);
      return response.listenHistory;
    });
  }

  /**
   * @returns {Promise<Array<String>>}
   */
  getIncompleteProfileData() {
    const startTime = Date.now();
    const { root } = this.getOverrides('getIncompleteProfileData');
    const opts = this.createAudacyBaseFetchOptions();
    const url = `${root}identity/profile/v1/users/me/incomplete`;

    return this.getJsonAuthenticated(url, opts).then((data) => {
      this.addStat('getIncompleteProfileData', Date.now() - startTime);
      return data;
    });
  }

  getTranscript(url) {
    const { root } = this.getOverrides('getTranscript');
    const opts = this.createAudacyBaseFetchOptions();

    try {
      const response = this.getJsonAuthenticated(url, opts);
      return response;
    } catch (error) {
      logger.error('Failed to get transcript', error);
      return error;
    }
  }

  /**
   * Gets the triton cookie value for use with AmperWave stream urls
   * @returns {Promise<String|undefined>}
   */
  static async getTritonCookie() {
    const url = 'https://yield-op-idsync.live.streamtheworld.com/partnerIds';
    const opts = {
      credentials: 'include',
    };

    try {
      const data = await DataServices.getJson(url, opts);
      return data['triton-uid'] ?? undefined;
    } catch {
      logger.error('Failed to get id from Triton cookie');
      return undefined;
    }
  }

  static async getFullTritonCookie() {
    const url = 'https://yield-op-idsync.live.streamtheworld.com/partnerIds';
    const opts = {
      credentials: 'include',
    };

    try {
      const data = await DataServices.getJson(url, opts);
      return data ?? undefined;
    } catch {
      logger.error('Failed to get full Triton cookie');
      return undefined;
    }
  }

  /**
   * @returns {Promise}
   */
  getAdTargetingStatus() {
    const { root } = this.getOverrides('getAdTargetingStatus');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/engagement/v1/users/me';

    return this.getJsonAuthenticated(url, opts).then((data) => data);
  }

  /**
   * @returns {Promise}
   */
  getAdTargetingEligibility() {
    const { root } = this.getOverrides('getAdTargetingEligibility');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/profile/v1/users/me/attributes?group=adTargeting';

    return this.getJsonAuthenticated(url, opts).then((data) => data);
  }

  getOverrides(which) {
    const override = this.overrides && this.overrides[which];
    const root = override ? this.envs[override.env] : this.root;
    const ver = override ? override.ver : this.version;

    return { root, ver };
  }

  /**
   * @param {String}  path
   * @param {String} [marketIds]
   * @returns {Promise<View>}
   */
  getPage(path, marketIds) {
    const startTime = Date.now();
    const { root } = this.getOverrides('getPage');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'experience/v1/page?path=' + encodeURIComponent(path);

    if (marketIds) url += '&marketIds=' + marketIds;

    return Promise.all([
      DataServices.getJson(url, opts),
      this.personalizationServices.getFollows(),
    ]).then(([page, favs]) => {
      this.addStat('getPage', Date.now() - startTime);

      if (page.contentObj) page.contentObj = DataServices.createEntity(page.contentObj);

      return DataServices.markFavs(page, favs);
    });
  }

  /**
   * @returns {Promise<PlaybacksHashMap>}
   */
  getPlaybacks = () => {
    const { root } = this.getOverrides('getPlaybacks');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/engagement/v1/playback';

    return this.getJsonAuthenticated(url, opts).then((data) => data.playbacks);
  };

  /**
   * @returns {Promise<Profile>}
   */
  getProfileData() {
    const startTime = Date.now();
    const { root } = this.getOverrides('getProfileData');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'identity/profile/v1/users/me';

    return this.getJsonAuthenticated(url, opts).then((data) => {
      this.addStat('getProfileData', Date.now() - startTime);
      return removePlaceholderData(PLACEHOLDER_REGEX, data);
    });
  }

  /**
   * @returns {Promise<Preferences>}
   */
  getPreferences() {
    const { root } = this.getOverrides('getPreferences');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'identity/engagement/v1/users/me/preferences';

    return this.getJsonAuthenticated(url, opts);
  }

  /**
   * @param {ProfilePatch} profile
   * @returns {Promise}
   */
  setPreferences(preferences) {
    const { root } = this.getOverrides('setPreferences');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'identity/engagement/v1/users/me/preferences';

    opts.method = 'PATCH';
    opts.body = JSON.stringify(preferences);

    return this.getJsonAuthenticated(url, opts);
  }

  /**
   * @returns {Promise<boolean>}
   */
  async checkUserTokenStatus() {
    const authState = serviceBus.personalizationServices.getAuthState();

    if (this.isTokenValid === RefreshTokenStatus.AUTHENTICATED) {
      return true;
    }

    // accounts for a bad token or the case where we don't have a token at all
    if (this.isTokenValid === RefreshTokenStatus.UNAUTHENTICATED || authState !== AuthState.AUTH) {
      return false;
    }

    // for RefreshTokenStatus.PENDING case we want to wait a half second and check again
    return new Promise((resolve) => {
      setTimeout(() => resolve(this.checkUserTokenStatus()), 500);
    });
  }

  /**
   * @returns {Promise<IServerQueue>}
   */
  getQueue() {
    const startTime = Date.now();
    const { root } = this.getOverrides('getQueue');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'identity/engagement/v1/queue';

    return this.getJsonAuthenticated(url, opts).then((data) => {
      this.addStat('getQueue', Date.now() - startTime);
      return data ? data : { currentId: '', itemIds: [] };
    });
  }

  /**
   * @returns {Promise<string[]>}
   */
  getSuggestedTerms() {
    const { root } = this.getOverrides('getSuggestedTerms');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'experience/v1/search/suggested-terms';

    return DataServices.getJson(url, opts).then((data) => {
      return data.suggestedTerms;
    });
  }

  /**
   * @param {Number|String} id
   * @returns {Promise<EpisodeList>}
   */
  getSchedules(id) {
    const startTime = Date.now();
    const { root } = this.getOverrides('getSchedules');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'experience/v1/content/' + id + '/schedule';

    return DataServices.getJson(url, opts).then((data) => {
      this.addStat('getSchedules', Date.now() - startTime);

      return new EpisodeList(
        'v3',
        DataServices.makeSchedulesPagedResponse(id, data && data.schedule ? data.schedule : []),
        undefined,
        undefined,
        true,
      );
    });
  }

  /**
   * @param {String}   id
   * @param {Number | String}   start
   * @param {Number | String}   end
   * @param {Episode} [episode=undefined]
   * @param {Station} [station=undefined]
   * @returns {Promise<SegmentList>}
   */
  getSegments(id, start, end, episode = undefined, station = undefined) {
    const maxBetweenTime = 1000 * 60 * 60 * 8; // API returns an error if the interval passed in is more than 8 hours
    const maxChunks = 5; // limit the number of calls in case too large of an interval was passed in

    let startMillis = new Date(start).getTime();
    let endMillis = new Date(end).getTime();
    let interval = endMillis - startMillis;

    // break into chunks if interval is larger than what the API can handle
    if (interval <= maxBetweenTime) {
      const url =
        'https://api.radio.com/v1/stations/' +
        id +
        '/segments?filter[between]=' +
        start +
        ',' +
        end +
        '&page[size]=500&filter[trim]=true&filter[current]=true&filter[fill]=true';

      return DataServices.getJson(url).then((data) => {
        return new SegmentList('v3', data.data ? data.data : [], this, episode, station);
      });
    } else {
      let chunkStart = startMillis;
      let numChunks = 0;
      let promises = [];

      while (chunkStart < endMillis && numChunks < maxChunks && chunkStart < Date.now()) {
        let chunkEnd =
          chunkStart + maxBetweenTime < endMillis ? chunkStart + maxBetweenTime : endMillis;
        promises.push(
          DataServices.getJson(
            'https://api.radio.com/v1/stations/' +
              id +
              '/segments?filter[between]=' +
              new Date(chunkStart).toISOString() +
              ',' +
              new Date(chunkEnd).toISOString() +
              '&page[size]=500&filter[trim]=true&filter[current]=true&filter[fill]=true',
          ),
        );
        chunkStart = chunkEnd;
        numChunks++;
      }

      // update seg1 in place with end time of seg2, update duration and source_url based on start_time and end_time
      const mergeSegments = (seg1, seg2) => {
        seg1.attributes.end_time = seg2.attributes.end_time;
        const durationInSeconds =
          (new Date(seg2.attributes.end_time).getTime() -
            new Date(seg1.attributes.start_time).getTime()) /
          1000;
        seg1.attributes.duration = durationInSeconds;

        // update the stream_url attribute with the new duration -
        // the url comes in the format "https://entercom-sgrewind.streamguys1.com/entercom/417/playlist_dvr_range-1652818039-1465.m3u8" where '1465' is the duration in seconds rounded up
        const hyphenIndex = seg1.attributes.stream_url.lastIndexOf('-');
        const dotIndex = seg1.attributes.stream_url.lastIndexOf('.');

        if (hyphenIndex > -1 && dotIndex > -1)
          seg1.attributes.stream_url =
            seg1.attributes.stream_url.substring(0, hyphenIndex + 1) +
            Math.ceil(durationInSeconds) +
            seg1.attributes.stream_url.substring(dotIndex);
      };

      return Promise.all(promises).then((data) => {
        let segments = [];

        // segments are in data.data
        // check last item of result against the first item of the next result - merge the segments if they have the same id and remove the item from the second result
        for (let i = 0; i < data.length - 1; i++) {
          let cur = data[i].data;
          let next = data[i + 1].data;

          if (cur.length > 0 && next.length > 0) {
            let seg1 = cur[cur.length - 1];
            let seg2 = next[0];

            if (seg1.id === seg2.id) {
              mergeSegments(seg1, seg2);
              next.splice(0, 1); //remove first element from array
            }
          }

          segments = segments.concat(cur);
        }

        segments = segments.concat(data[data.length - 1].data); // concat last array to results

        return new SegmentList('v3', segments, this, episode, station);
      });
    }
  }

  /**
   * @param {String} id - Station id
   * @param {Number | undefined} [limit] - Max number of SongHistory items
   * @return {Promise<ISongHistoryItem[]>}
   */
  async getSongHistory(id, limit) {
    const stationId = id.split('-')[1];
    const prtId = stationIdToPrtId[stationId];
    if (!prtId) {
      return [];
    }
    const itemLimit = limit ? limit : 5;
    const url = `https://api-nowplaying.amperwave.net/prt/nowplaying/2/${itemLimit}/${prtId}/nowplaying.json`;
    const response = await fetchWithTimeout(url);
    const { performances } = await response.json();
    return performances.map((item) => ({
      artist: item.artist,
      title: item.title,
      time: item.time,
      image: item.mediumimage,
    }));
  }

  /**
   * @param  {Number} id - Station id
   * @return {Promise<Station>}
   */
  getStation(id) {
    const override = this.overrides && this.overrides.station;
    const root = override ? this.envs[override.env] : this.root;
    const ver = override ? override.ver : this.version;

    const urls = {
      v1: root + ver + '/stations/' + id,
      v2: root + ver + '/stations/' + id,
    };

    const url = urls[ver];

    return DataServices.getJson(url).then((data) => {
      let retval;

      switch (this.version) {
        case 'v1':
          retval = data.data ? new Station(data.data) : undefined;
          break;

        case 'v2':
          retval = data.station ? new Station(data.station) : undefined;
          break;
      }

      return retval;
    });
  }

  /**
   * Get recently played items for a station using /playing endpoint.
   * @param {String} stationId - a single station id
   * @returns {Promise<RecentlyPlayed>}
   */
  getRecentlyPlayed(stationId) {
    const { root } = this.getOverrides('getRecentlyPlayed');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'experience/v1/playing?stationId=' + stationId;

    return DataServices.getJson(url, opts);
  }

  /**
   * @param {String|Station} contentIdOrStation
   * @param {Number} [num=3]
   */
  getStationRecents(contentIdOrStation, num = 3) {
    const resolver = (resolve, reject) => {
      const onGotDataObject = (obj) => {
        if (obj.getEntityType() === EntityType.STATION) {
          const id = obj.getId();
          const subtype = obj.getEntitySubtype();

          if (subtype === StationSubType.BROADCAST) {
            const url =
              'https://api.radio.com/v2/songs?page=1&station=' + id.split('_')[1] + '&size=' + num;

            DataServices.getJson(url).then(
              /**
               * @param {Object} data
               * @param {Array}  data.songs
               * @param {String} data.songs[].artist
               * @param {String} data.songs[].image_url
               * @param {Number} data.songs[].time_played
               * @param {String}  data.songs[].title
               */
              (data) => {
                const results = [];

                if (data && data.songs) {
                  const entries = data.songs;

                  for (let i = 0; i < entries.length; i++) {
                    results.push({
                      artist: entries[i].artist,
                      image: entries[i].image_url,
                      timePlayed: new Date(entries[i].time_played).getTime(),
                      title: entries[i].title,
                    });
                  }
                }

                resolve(new SongList('v3', results, this));
              },
              reject,
            );
          } else if (subtype === StationSubType.EXCLUSIVE) {
            const onGotPlayId = (playId) => {
              if (playId) {
                const url =
                  'https://smartstreams.radio.com/session/' +
                  playId +
                  '/' +
                  id.split('_')[1] +
                  '/recently-played?results=' +
                  num;

                DataServices.getJson(url).then(
                  /**
                   * @param {Object} data
                   * @param {Array}  data.recentlyPlayed
                   * @param {String} data.recentlyPlayed[].artist
                   * @param {String} data.recentlyPlayed[].imageUrl
                   * @param {Number} data.recentlyPlayed[].time_played
                   * @param {String} data.recentlyPlayed[].title
                   */
                  (data) => {
                    const results = [];

                    if (data) {
                      const entries = data.recentlyPlayed;

                      for (let i = 0; i < entries.length; i++) {
                        results.push({
                          artist: entries[i].artist,
                          image: entries[i].imageUrl,
                          timePlayed: new Date(entries[i].timePlayed).getTime(),
                          title: entries[i].title,
                        });
                      }
                    }

                    resolve(new SongList('v3', results, this));
                  },
                );
              } else resolve(new SongList('v3', [], this));
            };

            this.personalizationServices.dataStore
              .getData('digitalStreamPlayId')
              .then(onGotPlayId, reject);
          } else resolve(new SongList('v3', [], this));
        } else resolve(new SongList('v3', [], this));
      };

      if (typeof contentIdOrStation === 'string')
        this.getContentObject(contentIdOrStation).then(onGotDataObject);
      else if (contentIdOrStation instanceof Station) onGotDataObject(contentIdOrStation);
      else resolve(new SongList('v3', [], this));
    };

    return new Promise(resolver);
  }

  /**
   * Returns the market that is the geographically closest to the provided lat/long.
   * @param {Number}  [lat]
   * @param {Number}  [long]
   * @param {Boolean} [ipAvailable]
   * @returns {Promise<MarketData>}
   */
  getUserMarket(lat, long, ipAvailable = false) {
    const startTime = Date.now();
    const { root } = this.getOverrides('getUserMarket');
    const opts = this.createAudacyBaseFetchOptions();
    const ip = 'hasIpAddressLocationAvailable=' + ipAvailable;
    let url = `${root}experience/v1/location/market?${ip}`;

    if (lat && long) url += '&lat=' + lat + '&long=' + long;

    return DataServices.getJson(url, opts).then((data) => {
      this.addStat('getUserMarket', Date.now() - startTime);
      return data;
    });
  }

  /**
   * Returns the markets that are within 60 miles of the provided lat/long.
   * @param {Number}  [lat]
   * @param {Number}  [long]
   * @param {Boolean} [ipAvailable]
   * @returns {Promise<Array<MarketData>>}
   */
  getUserMarkets(lat, long, ipAvailable = false) {
    const startTime = Date.now();
    const { root } = this.getOverrides('getUserMarkets');
    const opts = this.createAudacyBaseFetchOptions();
    const ip = 'hasIpAddressLocationAvailable=' + ipAvailable;
    let url = `${root}experience/v1/location/markets?${ip}`;

    if (lat && long) url += '&lat=' + lat + '&long=' + long;

    return DataServices.getJson(url, opts).then((data) => {
      this.addStat('getUserMarkets', Date.now() - startTime);
      return data;
    });
  }

  /**
   * @param {String} id
   * @param {String} [contentId]
   * @param {String} [marketIds]
   * @return {Promise<View>}
   */
  getView(id, contentId, marketIds) {
    const startTime = Date.now();
    const { root } = this.getOverrides('getView');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'experience/v1/view/' + id + (contentId ? '/' + contentId : '');

    if (this.credentialsProvider.userToken === undefined) {
      logger.error('Calling getView() with no user token');
      clientServicesConfig.ddError('Calling getView() with no user token');
    }

    if (marketIds) url += '?marketIds=' + marketIds;

    return Promise.all([
      DataServices.getJson(url, opts),
      this.personalizationServices.getFollows(),
    ]).then(([page, favs]) => {
      if (page.contentObj) page.contentObj = DataServices.createEntity(page.contentObj);

      this.addStat('getView', Date.now() - startTime);

      return DataServices.markFavs(page, favs);
    });
  }

  /**
   * @param {String} [marketIds]
   * @return {Promise<View>}
   */
  getAutoView(marketIds) {
    const startTime = Date.now();
    const { root } = this.getOverrides('getAutoView');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'experience/v1/view/auto_home?viewId=auto_home&viewType=MODULES';
    if (marketIds) url += '&marketIds=' + marketIds;

    if (this.credentialsProvider.userToken === undefined) {
      logger.error('Calling getAutoView() with no user token');
      clientServicesConfig.ddError(`Calling getAutoView() with no user token`);
    }

    return DataServices.getJson(url, opts).then((data) => {
      this.addStat('getAutoView', Date.now() - startTime);
      return data;
    });
  }

  /**
   * @param {string} contentId
   * @returns {Promise<FeaturedPlayable|undefined>}
   */
  getFeaturedPlayable(contentId) {
    const url = `${this.root}experience/v1/content/${contentId}/featured-playable`;
    const opts = this.createAudacyBaseFetchOptions();

    return DataServices.getJson(url, opts)
      .then((data) => {
        if (data && data.contentObj) {
          let Entity;

          if (data.contentObj.entityType === EntityType.STATION) Entity = Station;
          else if (data.contentObj.entityType === EntityType.EPISODE) Entity = Episode;
          else if (data.contentObj.entityType === EntityType.STANDALONE_CHAPTER)
            Entity = StandaloneChapter;
          else if (data.contentObj.entityType === EntityType.TOPIC) Entity = Topic;

          if (Entity) {
            return {
              contentObj: new Entity(data.contentObj),
              ctaTitle: data.ctaTitle,
            };
          }
        }

        return undefined;
      })
      .catch(() => undefined);
  }

  /**
   * Retrieve an xml object from a url.
   * @param {String}   url
   * @param {Boolean} [noCors]
   * @return {Promise<Object>}
   */
  static getXml(url, noCors = false) {
    let retval;
    const options = noCors === true ? { mode: 'no-cors' } : {};

    if (url instanceof Array) {
      let promises = [];

      for (let i = 0; i < url.length; i++)
        promises.push(
          fetchWithTimeout(url[i], options).then(
            (response) => response.text(),
            (error) => {
              throw error;
            },
          ),
        );

      retval = Promise.all(promises);
    } else
      retval = fetchWithTimeout(url, options).then(
        (response) => {
          return response.text();
        },
        (e) => {
          logger.error('failed to fetch xml for', url);
          this.ddLogger?.error(`Failed to load fetch xml for url ${url}`);
          throw e;
        },
      );

    return retval;
  }

  /**
   * @param   {String} episodeId
   * @param   {String} callInRequestId
   * @returns {Promise}
   */
  hangupAtlCreatorCallin(episodeId, callInRequestId) {
    const startTime = Date.now();
    const { root } = this.getOverrides('hangupAtlCreatorCallin');
    const opts = this.dataServices.createAudacyBaseFetchOptions();
    const url =
      root +
      'anytime-live-creator-service/v1/creator/episode/' +
      episodeId +
      '/callin/' +
      callInRequestId +
      '/hangup';

    const body = {
      episodeId: episodeId,
      callInRequestId: callInRequestId,
    };

    opts.method = 'PUT';
    opts.body = JSON.stringify(body);

    return this.getJsonAuthenticated(url, opts, true).then(() => {
      this.addStat('hangupAtlCreatorCallin', Date.now() - startTime);
    });
  }

  /**
   * @param   {String} episodeId
   * @param   {String} callInRequestId
   * @returns {Promise}
   */
  hangupAtlListenerCallin(episodeId, callInRequestId) {
    const startTime = Date.now();
    const { root } = this.getOverrides('hangupAtlListenerCallin');
    const opts = this.dataServices.createAudacyBaseFetchOptions();
    const url =
      root +
      'anytime-live-creator-service/v1/listener/episode/' +
      episodeId +
      '/callin/' +
      callInRequestId +
      '/hangup';

    const body = {
      episodeId: episodeId,
      callInRequestId: callInRequestId,
    };

    opts.method = 'PUT';
    opts.body = JSON.stringify(body);

    return this.getJsonAuthenticated(url, opts, true).then(() => {
      this.addStat('hangupAtlListenerCallin', Date.now() - startTime);
    });
  }

  /**
   * Dynamically load and attach a javascript file.
   * @param  {String} url
   * @return {Promise}
   */
  static loadScript(url) {
    const resolver = function (resolve, reject) {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = url;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;

      document.getElementsByTagName('head')[0].appendChild(script);
    };

    return new Promise(resolver);
  }

  /**
   * Dynamically load and attach an image.
   * @param  {String} url
   * @return {Promise}
   */
  static loadImg(url) {
    const resolver = function (resolve, reject) {
      const img = document.createElement('img');

      img.src = url;

      document.getElementsByTagName('head')[0].appendChild(img);
    };

    return new Promise(resolver);
  }

  /**
   * Dynamically load and attach CSS.
   * @param  {String} url
   * @return {Promise}
   */
  static loadCSS(cssUrl) {
    return new Promise((resolve, reject) => {
      const linkTag = document.createElement('link');

      linkTag.rel = 'stylesheet';
      linkTag.onload = resolve;
      linkTag.onerror = reject;
      linkTag.href = cssUrl;

      document.head.appendChild(linkTag);
    });
  }

  static makeSchedulesPagedResponse(id, entries) {
    const result = {
      code: 200,
      id: id,
      page: 0,
      pageSize: entries.length,
      results: entries,
      total: entries.length,
    };

    for (let i = 0; i < entries.length; i++) {
      if (entries[i].startDateTimeSeconds === undefined)
        entries[i].startDateTimeSeconds = new Date(entries[i].startDateTime).getTime() / 1000;

      if (entries[i].endDateTimeSeconds === undefined) {
        if (entries[i].endDateTime === undefined) {
          entries[i].endDateTimeSeconds =
            entries[i].startDateTimeSeconds + entries[i].durationSeconds;
          entries[i].endDateTime = new Date(entries[i].endDateTimeSeconds * 1000).toISOString();
        } else entries[i].endDateTimeSeconds = new Date(entries[i].endDateTime).getTime() / 1000;
      }
    }

    return result;
  }

  static markFavs(page, favs) {
    for (const [key, inVal] of Object.entries(page.content)) inVal.isFav = favs.indexOf(key) !== -1;

    if (page.contentObj)
      page.contentObj.markAsFavorite(favs.indexOf(page.contentObj.getId()) !== -1);

    return page;
  }

  /**
   * @param {ProfilePatch} profile
   * @returns {Promise}
   */
  patchProfileData(profile) {
    const startTime = Date.now();
    const { root } = this.getOverrides('patchProfileData');
    const opts = this.createAudacyBaseFetchOptions();
    const url = `${root}identity/profile/v1/users/me`;

    opts.method = 'PATCH';
    opts.body = JSON.stringify(profile);

    return this.getJsonAuthenticated(url, opts, true).then((response) => {
      this.addStat('patchProfileData', Date.now() - startTime);
      return response;
    });
  }

  /**
   * Provides cross references for all services modules.
   * @param {Object}                  config
   * @param {AnalyticServices}        config.analyticServices
   * @param {AudioServices}           config.audioServices
   * @param {ChatServices}            config.chatServices
   * @param {DataServices}            config.dataServices
   * @param {PersonalizationServices} config.personalizationServices
   * @returns {Promise}
   */
  postServiceInit(config) {
    const onSettings = (settings) => {
      this.useChaptersEndpoint = settings?.featureFlags?.broadcastChapters;
    };

    if (this.onlineStatusProvider?.online) {
      return Promise.all([
        super.postServiceInit(config),
        this.getClientSettings().then(onSettings),
      ]);
    } else {
      return Promise.all([super.postServiceInit(config)]);
    }
  }

  /**
   * @param   {String} episodeId
   * @param   {String} callInRequestId
   * @returns {Promise}
   */
  rejectAtlCallin(episodeId, callInRequestId) {
    const startTime = Date.now();
    const { root } = this.getOverrides('rejectAtlCallin');
    const opts = this.dataServices.createAudacyBaseFetchOptions();
    const url =
      root +
      'anytime-live-creator-service/v1/creator/episode/' +
      episodeId +
      '/callin/' +
      callInRequestId +
      '/reject';

    const body = {
      episodeId: episodeId,
      callInRequestId: callInRequestId,
    };

    opts.method = 'PUT';
    opts.body = JSON.stringify(body);

    return this.getJsonAuthenticated(url, opts, true).then(() => {
      this.addStat('rejectAtlCallin', Date.now() - startTime);
    });
  }

  reportChatMessage(messageId, reportedReason) {
    const { root } = this.getOverrides('search');
    const opts = this.createChatFetchOptions(); //this.dataServices.createAudacyBaseFetchOptions();
    const url = `${root}/chat-moderation/v1/mod/report-message`;

    const postBody = {
      reportedReason: reportedReason,
      messageId: messageId,
    };

    opts.method = 'POST';
    opts.body = JSON.stringify(postBody);

    return this.getJsonAuthenticated(url, opts);
  }

  /**
   * @param   {String} episodeId
   * @param   {String} firstName
   * @param   {String} message
   * @param   {String} memberId
   * @returns {Promise}
   */
  requestAtlCallin(episodeId, firstName, message, memberId) {
    const startTime = Date.now();
    const { root } = this.getOverrides('requestAtlCallin');
    const opts = this.dataServices.createAudacyBaseFetchOptions();
    const url =
      root + 'anytime-live-creator-service/v1/listener/episode/' + episodeId + '/callin/request';

    const postBody = {
      firstName: firstName,
      message: message,
      memberId: memberId,
    };

    opts.method = 'POST';
    opts.body = JSON.stringify(postBody);

    return this.getJsonAuthenticated(url, opts, undefined, true).then((response) => {
      this.addStat('requestAtlCallin', Date.now() - startTime);
      return response;
    });
  }

  /**
   * @param {String}                searchStr
   * @param {Array<EntityType>}    [entityTypes = []]
   * @param {Array<EntitySubtype>} [entitySubtypes = []]
   * @param {Array<EntityType>}    [excludeEntityTypes = []]
   * @param {Array<EntitySubtype>} [excludeEntitySubtypes = []]
   * @param {Number}               [page = 0]
   * @returns {Promise<SearchResultList>}
   */
  search(
    searchStr,
    entityTypes = [],
    entitySubtypes = [],
    excludeEntityTypes = [],
    excludeEntitySubtypes = [],
    page = 0,
  ) {
    const { root } = this.getOverrides('search');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'experience/v1/search';
    let entityTypeQueryParam = 'entityType=';
    let entitySubtypeQueryParam = 'entitySubtype=';
    let excludeEntityTypeQueryParam = 'excludeEntityType=';
    let excludeEntitySubtypeQueryParam = 'excludeEntitySubtype=';
    let encodedString = encodeURIComponent(searchStr).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
    );
    url += `?term=${encodedString}&page=${page}`;

    if (entityTypes.length) {
      entityTypes.forEach(
        (entity, index) => (entityTypeQueryParam += `${index > 0 ? '%7C' : ''}${entity}`),
      );
      url += '&' + entityTypeQueryParam;
    }

    if (entitySubtypes.length) {
      entitySubtypes.forEach(
        (entity, index) => (entitySubtypeQueryParam += `${index > 0 ? '%7C' : ''}${entity}`),
      );
      url += '&' + entitySubtypeQueryParam;
    }

    if (excludeEntityTypes.length) {
      excludeEntityTypes.forEach(
        (entity, index) => (excludeEntityTypeQueryParam += `${index > 0 ? '%7C' : ''}${entity}`),
      );
      url += '&' + excludeEntityTypeQueryParam;
    }

    if (excludeEntitySubtypes.length) {
      excludeEntitySubtypes.forEach(
        (entity, index) => (excludeEntitySubtypeQueryParam += `${index > 0 ? '%7C' : ''}${entity}`),
      );
      url += '&' + excludeEntitySubtypeQueryParam;
    }

    return DataServices.getJson(url, opts).then(
      (data) => new SearchResultList(ApiVersion.V3, data, this, searchStr),
    );
  }

  /**
   * @param {String}  searchStr
   * @param {Number} [page = 0]
   */
  searchAtlHosts(searchStr, page) {
    const startTime = Date.now();
    const { root } = this.getOverrides('searchAtlHosts');
    const opts = this.createAudacyBaseFetchOptions();
    const url =
      root +
      'anytime-live-creator-service/v1/creator/search-hosts?search-term=' +
      searchStr +
      '&page-number=' +
      page;

    const queryFunction = (page) => {
      return this.searchAtlHosts(searchStr, page);
    };

    return this.getJsonAuthenticated(url, opts).then((results) => {
      this.addStat('searchAtlHosts', Date.now() - startTime);

      // TODO remove when hosts is replaced by results in response

      if (results.hosts) {
        results.results = results.hosts;
        delete results.hosts;
      }

      return new AtlHostsSearchResultList(ApiVersion.V3, results, this, searchStr, queryFunction);
    });
  }

  static sendAudioPrerollTrackers(trackers) {
    trackers.forEach((tracker) => {
      fetch(tracker);
    });
  }

  sendChatMessage(sessionId, text, offset, name, image) {
    const { root } = this.getOverrides('sendChatMessage');
    const opts = this.createChatFetchOptions(); //this.dataServices.createAudacyBaseFetchOptions();
    const url = `${root}chat-publish/v1/publish/${sessionId}/text`;

    const postBody = {
      streamOffsetMs: offset,
      text: text,
      userDisplayName: name,
      userProfilePictureUrl: image,
    };

    opts.method = 'POST';
    opts.body = JSON.stringify(postBody);

    return this.getJsonAuthenticated(url, opts);
  }

  sendChatMessageReaction(sessionId, targetMessageId, action, offset, name, image) {
    const { root } = this.getOverrides('sendChatMessageReaction');
    const opts = this.createChatFetchOptions(); //this.dataServices.createAudacyBaseFetchOptions();
    const url = `${root}chat-publish/v1/publish/${sessionId}/message-reaction`;

    const postBody = {
      streamOffsetMs: offset,
      action: action,
      targetMessageId: targetMessageId,
      userDisplayName: name,
      userProfilePictureUrl: image,
    };

    opts.method = 'POST';
    opts.body = JSON.stringify(postBody);

    return this.getJsonAuthenticated(url, opts);
  }

  sendChatQuickReaction(sessionId, reactionId, streamOffsetMs, name, image) {
    const { root } = this.getOverrides('sendChatQuickReaction');
    const opts = this.createChatFetchOptions(); //this.dataServices.createAudacyBaseFetchOptions();
    const url = `${root}chat-publish/v1/publish/${sessionId}/quick-reaction`;

    const postBody = {
      streamOffsetMs: streamOffsetMs,
      reactionId: reactionId,
      userDisplayName: name,
      userProfilePictureUrl: image,
    };

    opts.method = 'POST';
    opts.body = JSON.stringify(postBody);

    return this.getJsonAuthenticated(url, opts);
  }

  /**
   * Sends user events to the server using the Audacy data-events API.
   * @param {string} appName - The name of the app sending the events.
   * @param {string} appVersion - The version of the app sending the events.
   * @param {string} appBuildNumber - The build number of the app sending the events.
   * @param {string} userAgent - The user agent of the device sending the events.
   * @param {string} body - The body of the request, containing the events to be sent.
   * @returns {Promise<{code: number}>} A promise that resolves to an object containing
   */
  sendUserEvents(appName, appVersion, appBuildNumber, userAgent, body) {
    const { root } = this.getOverrides('sendUserEvents');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'data-events/v1/events';

    opts.headers['Aud-App-Name'] = appName;
    opts.headers['Aud-App-Version'] = appVersion;
    opts.headers['Aud-App-Build-Number'] = appBuildNumber;
    opts.headers['Aud-Logged-In'] =
      this.credentialsProvider.bearerHeader && this.credentialsProvider.token ? 1 : 0;
    opts.headers['User-Agent'] = userAgent;
    opts.method = 'POST';
    opts.body = body;

    return DataServices.getJson(url, opts).catch(() => {
      throw new Error(
        `sendUserEvents request failed with error: ${error.name}, ${error.message}, URL: ${url}`,
      );
    });
  }

  /**
   * @param {String} adTargetingStatus
   * @returns {Promise}
   */
  setAdTargetingStatus(adTargeting) {
    const { root } = this.getOverrides('setAdTargetingStatus');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/engagement/v1/users/me';

    opts.method = 'PATCH';
    opts.body = JSON.stringify({ adTargeting });

    return this.getJsonAuthenticated(url, opts, true);
  }

  setPlatformAndVariant(platform, platformVariant) {
    this.platform = platform;
    this.platformVariant = platformVariant;
  }

  /**
   * @param {String} id
   * @param {Number} offset
   * @returns {Promise}
   */
  setPlayback(id, offset) {
    const { root } = this.getOverrides('setPlayback');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/engagement/v1/playback';

    opts.method = 'POST';
    opts.body = JSON.stringify({ playbacks: [{ contentId: id, playbackOffset: offset }] });

    return this.getJsonAuthenticated(url, opts);
  }

  /**
   * @param {Array<TPlayback>} [playbacks = []]
   * @returns {Promise}
   */
  setPlaybacks(playbacks) {
    const { root } = this.getOverrides('setPlaybacks');
    const opts = this.createAudacyBaseFetchOptions();
    let url = root + 'identity/engagement/v1/playback';

    opts.method = 'POST';
    opts.body = JSON.stringify({ playbacks });

    return this.getJsonAuthenticated(url, opts);
  }

  /**
   * Reactivate a User
   * @returns {Promise}
   */

  reactivateUser() {
    const startTime = Date.now();
    const { root } = this.getOverrides('reactivateUser');
    const opts = this.createAudacyBaseFetchOptions();
    let url = `${root}identity/profile/v1/users/reactivate`;

    opts.method = 'POST';

    return this.getJsonAuthenticated(url, opts, true).then((response) => {
      this.addStat('reactivateUser', Date.now() - startTime);
      return response;
    });
  }

  /**
   * @param {IServerQueue} queue
   * @returns {Promise}
   */
  setQueue(queue) {
    const startTime = Date.now();
    const { root } = this.getOverrides('setQueue');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'identity/engagement/v1/queue';

    opts.method = 'PATCH';
    opts.body = JSON.stringify(queue);

    return this.getJsonAuthenticated(url, opts, true).then(() => {
      this.addStat('setQueue', Date.now() - startTime);
    });
  }

  /**
   * Send the password reset email
   * @returns {Promise}
   */

  triggerPasswordResetEmail() {
    const startTime = Date.now();
    const { root } = this.getOverrides('triggerPasswordResetEmail');
    const opts = this.createAudacyBaseFetchOptions();
    const url = root + 'identity/forgot-password';

    opts.method = 'POST';

    return this.getJsonAuthenticated(url, opts, true).then(() => {
      this.addStat('triggerPasswordResetEmail', Date.now() - startTime);
    });
  }

  async triggerRefreshToken() {
    if (!this.authFetch) {
      this.ddLogger?.info(`Refresh token function triggered`);
      if (!this.credentialsProvider.token) {
        await serviceBus.personalizationServices.setAuthState({ state: AuthState.NONE });
        this.authFetch = undefined;
        this.ddLogger?.error(
          `Error occured on token refresh for user ${this.credentialsProvider.userId}, token = undefined`,
        );
        throw new AudacyAuthError('DS:Alpha');
      }

      const token = this.credentialsProvider.token;
      const { root } = this.getOverrides('triggerRefreshToken');
      const opts = this.createAudacyBaseFetchOptions();
      const url = root + 'identity/tokens';

      opts.credentials = 'omit';
      opts.method = 'POST';
      opts.headers['Refresh-Token'] = this.credentialsProvider.token;

      this.authFetch = fetchWithTimeout(url, opts)
        .then(async (response) => {
          if (response.status !== 201 && response.status !== 200) {
            await serviceBus.personalizationServices.setAuthState({ state: AuthState.NONE });
            this.authFetch = undefined;
            this.ddLogger?.error(
              `Error occured on token refresh for user ${this.credentialsProvider.userId} token: " ${token} status = " ${response.status}`,
            );
            throw new AudacyAuthError('DS:Bravo - (' + token + ') status = ' + response.status);
          } else {
            this.ddLogger?.info(`Token refreshed for user: ${this.credentialsProvider.userId}`);
            return response.json();
          }
        })
        .then(async (json) => {
          await serviceBus.personalizationServices.setAuthState({
            state: AuthState.AUTH,
            userId: this.credentialsProvider.userToken,
            refreshToken: json.refreshToken,
            accessToken: json.accessToken,
          });
          this.authFetch = undefined;
        })
        .catch(async (err) => {
          await serviceBus.personalizationServices.setAuthState({ state: AuthState.NONE });
          this.authFetch = undefined;
          this.ddLogger?.error(
            `Error occured on token refresh for user ${this.credentialsProvider.userId} token: " ${token}, error:  ${err}`,
          );
          throw err instanceof AudacyAuthError
            ? err
            : new AudacyAuthError('DS:Charlie - (' + token + ') ' + err);
        });
    }

    return await this.authFetch;
  }

  updateInteractionTime() {
    this.lastInteraction = Date.now();
  }
}
