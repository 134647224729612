import clientServices from '@audacy-clients/core/utils/clientServices';
import { selector, useRecoilValueLoadable } from 'recoil';
import { nowPlaying } from './player';

export type TTranscriptData = {
  transcript: Array<TTranscript>;
};

export type TTranscript = {
  duration: number;
  end_time: number;
  speaker: string;
  start_time: number;
  text: string;
};

export const transcriptQuery = selector<TTranscriptData>({
  get: async ({ get }) => {
    try {
      const episode = get(nowPlaying);
      const { url, isNavigableTranscript } = episode?.episodeDataObject?.transcript ?? {};

      if (!url && !isNavigableTranscript) {
        return undefined;
      }

      const transcript = await clientServices.getDataServices().getTranscript(url);
      return transcript;
    } catch (e) {
      console.error('Transcript error: ', e);
    }
  },
  key: 'Transcript',
});

export const useTranscriptData = (): {
  transcript: TTranscriptData | undefined;
  loading: boolean;
  hasError: boolean;
} => {
  const data = useRecoilValueLoadable(transcriptQuery);

  return {
    transcript: data.valueMaybe(),
    loading: data.state === 'loading',
    hasError: data.state === 'hasError',
  };
};
