import {
  Gender,
  TProfilePatch,
} from '@audacy-clients/client-services/src/personalizationServices/types';
import * as braze from '@braze/web-sdk';
import { split } from 'lodash';

import { logIn } from '~/state/auth';

interface IBrazeCustomAttributeParameters {
  attributeKey: string;
  attributeValue: string;
  callback?: () => void;
}

interface IBrazeDateOfBirth {
  day: number;
  month: number;
  year: number;
}

const clearBrazeCache = () => {
  Object.keys(localStorage)
    .filter((item) => item.startsWith('ab.'))
    .forEach((brazeItem) => localStorage.removeItem(brazeItem));
};

const flushBraze = (callback?: () => void) => {
  braze.requestImmediateDataFlush(callback);
};

const flushBrazeAndClearCache = (): Promise<void> => {
  return new Promise((resolve) => {
    flushBraze(() => {
      clearBrazeCache();
      resolve();
    });
  });
};

export const flushAndClearCallback = (): void => {
  // setTimeout ensures that the iframe "appBoy" events have been sent to braze first
  setTimeout(async () => {
    // async flush and clear to blow away braze local storage
    await flushBrazeAndClearCache();
    // rebuilds braze local storage (otherwise we wait ~ 10 seconds for it to be rebuilt)
    // this avoids the "I changed my settings but i don't see them reflected" problem
    flushBraze();
  }, 500);
};

// listens for messages sent from braze popups
export const addBrazeEventListener = (): void => {
  window.addEventListener('message', (event) => {
    switch (event.data) {
      case 'flushandclearbrazecache':
        flushAndClearCallback();
        break;
      case 'login':
        logIn();
        break;
    }
  });
};

const getBrazeDateOfBirthFromProfile = ({
  dateOfBirth,
}: {
  dateOfBirth?: TProfilePatch['dateOfBirth'];
}): IBrazeDateOfBirth => {
  const [year, month, day] = split(dateOfBirth, '-').map((num) => parseInt(num, 10));
  return { year, month, day };
};

const getBrazeGenderFromProfile = (gender: Gender) => {
  switch (gender) {
    case Gender.MALE:
      return 'm';
    case Gender.FEMALE:
      return 'f';
    case Gender.NON_BINARY:
      return 'o';
    case Gender.NOT_SPECIFIED:
    default:
      return 'u';
  }
};

export const setBrazeCustomAttribute = ({
  attributeKey,
  attributeValue,
}: IBrazeCustomAttributeParameters): void => {
  if (disableBraze) {
    return;
  }
  braze.getUser()?.setCustomUserAttribute(attributeKey, attributeValue);
};

export const setBrazeUserAttributes = ({
  emailAddress,
  firstName,
  lastName,
  zipCode,
  gender,
  dateOfBirth,
}: TProfilePatch): void => {
  if (disableBraze) {
    return;
  }

  if (emailAddress) {
    braze.getUser()?.setEmail(emailAddress);
  }
  if (firstName) {
    braze.getUser()?.setFirstName(firstName);
  }
  if (lastName) {
    braze.getUser()?.setLastName(lastName);
  }
  if (gender) {
    braze.getUser()?.setGender(getBrazeGenderFromProfile(gender));
  }
  if (zipCode) {
    braze.getUser()?.setHomeCity(zipCode);
  }
  if (dateOfBirth) {
    const formattedDateOfBirth = getBrazeDateOfBirthFromProfile({ dateOfBirth });
    const { year, month, day } = formattedDateOfBirth;
    braze.getUser()?.setDateOfBirth(year, month, day);
  }
};

export const disableBraze =
  process.env.DISABLE_BRAZE === 'true' && process.env.NODE_ENV !== 'production';
