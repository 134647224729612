import StandaloneChapter from '@audacy-clients/client-services/src/dataServices/StandaloneChapter';
import { getNullSafeDate } from '@audacy-clients/core/utils/date';

import { wrapDataObject } from '.';
import { IStandaloneChapter } from './types';

export const wrapStandaloneChapter = (standaloneChapter: StandaloneChapter): IStandaloneChapter => {
  const now = new Date().getTime();
  const replayableUntilDate = getNullSafeDate(standaloneChapter.getReplayableUntil());
  const publishDate =
    getNullSafeDate(standaloneChapter.getPublishDate()) ??
    getNullSafeDate(standaloneChapter.getStartTime());

  return {
    ...wrapDataObject<StandaloneChapter>(standaloneChapter),
    duration: standaloneChapter.getDuration(),
    endDateTime: getNullSafeDate(standaloneChapter.getEndTime()),
    endTime: standaloneChapter.getEndTimeMillis(),
    isDoneAndRewind: standaloneChapter.getEndTimeMillis() < now && standaloneChapter.isRewindable(),
    isReplayable: standaloneChapter.isRewindable(),
    isRewind: standaloneChapter.isRewindable(),
    parentEpisodeId: standaloneChapter.getParentEpisodeId(),
    parentEpisodeUrl: standaloneChapter.getParentEpisodeUrl(),
    parentImage: standaloneChapter.getParentImageSquare(),
    parentImageAlt: standaloneChapter.getParentImageAlt(),
    parentShowId: standaloneChapter.getParentShowId(),
    parentShowUrl: standaloneChapter.getParentShowUrl(),
    parentStationId: standaloneChapter.getParentStationId(),
    parentTitle: standaloneChapter.getParentTitle(),
    publishDate,
    replayableUntilDateTime: replayableUntilDate?.toISOString(),
    showContentId: standaloneChapter.getParentShowId(),
    startDateTime: getNullSafeDate(standaloneChapter.getStartTime()),
    startTime: standaloneChapter.getStartTimeMillis(),
    standaloneChapterId: standaloneChapter.getId(),
    isOnAirOnly: false,
  };
};
