import { useCallback, useEffect, useRef } from 'react';

import { StreamErrors } from '@audacy-clients/client-services/core';
import AudioServices from '@audacy-clients/client-services/src/audioServices';
import { PLAYBACK_RATES } from '@audacy-clients/client-services/src/audioServices/players/constants';
import {
  IStreamerMachineContext,
  IStreamerState,
} from '@audacy-clients/client-services/src/audioServices/streamers/types';
import {
  TPlayableObject,
  ESleepTimerState,
  IAudioEventListenerContext,
} from '@audacy-clients/client-services/src/audioServices/types';
import { shouldEnablePlaybackRates } from '@audacy-clients/client-services/src/utils/playbackRate';
import {
  chapterIndexState,
  audioServicesProvidedChapters,
} from '@audacy-clients/core/atoms/chapters';
import {
  activeCollectionState,
  collectionPlaybackModeState,
} from '@audacy-clients/core/atoms/collections';
import { useFeatureFlag } from '@audacy-clients/core/atoms/config/settings';
import { playbackResumePointsState } from '@audacy-clients/core/atoms/playbackResumePoints';
import playerState, {
  playerMetadataState,
  playerTimeState,
  playerVolumeState,
  playerSpeedState,
  playerLoadingState,
  IPlayerState,
} from '@audacy-clients/core/atoms/player';
import { sleepUntilAtom, sleepUntilEndOfEpisodeAtom } from '@audacy-clients/core/atoms/sleepTimer';
import { wrapChapters } from '@audacy-clients/core/atoms/wrappers/chapter';
import { wrapContentObject } from '@audacy-clients/core/atoms/wrappers/content';
import { wrapEpisode } from '@audacy-clients/core/atoms/wrappers/episode';
import { wrapStandaloneChapter } from '@audacy-clients/core/atoms/wrappers/standaloneChapter';
import { PlayerState } from '@audacy-clients/core/types/player';
// import { NOT_AVAILABLE } from '@audacy-clients/core/constants/player';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { unescapeString } from '@audacy-clients/core/utils/strings';
import {
  RecoilState,
  useRecoilTransaction_UNSTABLE,
  useSetRecoilState,
  useResetRecoilState,
} from 'recoil';
import { StateValue } from 'xstate';

import { isDefined, useGetContextDiff } from './utils';

// TODO: [CCS-1391] Add case for Disabled?
// TODO: [CCS-1392] Figure out the constant approach / type sharing approach
const mapCSPlayerStateToAppPlayerState = (csState: StateValue): PlayerState => {
  switch (csState) {
    case 'PLAY_REQUESTED':
    case 'SKIP_REQUESTED':
    case 'LOADING':
    case 'FAILURE_WITH_RETRY':
    case 'BUFFERING':
    case 'TRANSITION_TO_BUFFERING':
      return PlayerState.Loading;
    case 'PLAYING':
      return PlayerState.Playing;
    case 'PAUSED':
    case 'PAUSE_REQUESTED':
    case 'LOADED':
      return PlayerState.Paused;
    case 'ENDED':
      return PlayerState.Ended;
    case 'DESTROYED':
      return PlayerState.Idle;
    case 'FAILURE':
    default:
      return PlayerState.Error;
  }
};

interface IPlayerStateConnectorProps {
  onPlaybackError?: (event: unknown) => void; // TODO: [CCS-933] fix and change event type
  onExpiredContentError?: () => void;
  resetPlayerStateOnError?: boolean;
  onEvent?: (event: unknown) => void; // TODO: [CCS-933] fix and change event type
}

const PlayerStateConnector = ({
  onPlaybackError,
  resetPlayerStateOnError,
  onEvent,
}: IPlayerStateConnectorProps): null => {
  const diffAudioServicesContext = useGetContextDiff<IAudioEventListenerContext>();

  const lastAudioServicesStateRef = useRef<StateValue>();

  const lastStreamerStateRef = useRef<IStreamerState<TPlayableObject>['value']>();

  const diffStreamerContext = useGetContextDiff<IStreamerMachineContext<TPlayableObject>>();

  const isChaptersEnabled = useFeatureFlag('chapters');
  const resetPlayerState = useResetRecoilState(playerState);
  const resetPlayerTimeState = useResetRecoilState(playerTimeState);

  const handleError = useCallback(
    (event: unknown) => {
      if (resetPlayerStateOnError) {
        resetPlayerState();
        resetPlayerTimeState();
      }

      if (onPlaybackError) {
        onPlaybackError(event);
      }
    },
    [onPlaybackError, resetPlayerState, resetPlayerTimeState, resetPlayerStateOnError],
  );

  const handleEvent: Parameters<AudioServices['setEventListener']>[0] =
    useRecoilTransaction_UNSTABLE(
      ({ set, reset }) => {
        const cSet =
          <T,>(state: RecoilState<T>) =>
          (c: ((currVal: T) => T) | T) =>
            set(state, c);

        const setPlayerMetadataState = cSet(playerMetadataState);
        const setPlayerState = cSet(playerState);
        const setPlayerTimeState = cSet(playerTimeState);
        const setPlayerSpeedState = cSet(playerSpeedState);
        const setChapterIndexState = cSet(chapterIndexState);
        const resetChapterIndexState = () => reset(chapterIndexState);
        const setPlayerVolumeState = cSet(playerVolumeState);
        const setPlayerLoadingState = cSet(playerLoadingState);
        const setSleepUntilAtom = cSet(sleepUntilAtom);
        const setSleepUntilEndOfEpisodeAtom = cSet(sleepUntilEndOfEpisodeAtom);
        const setPlaybackResumePointsState = cSet(playbackResumePointsState);
        const setActiveCollection = cSet(activeCollectionState);
        const setCollectionPlaybackModeState = cSet(collectionPlaybackModeState);

        return ({ audioServicesState, streamerState }) => {
          const changedAudioServicesContextProperties = diffAudioServicesContext(
            audioServicesState.context,
          );

          const audioServicesStateValueChanged =
            audioServicesState.value.MAIN !== lastAudioServicesStateRef.current;
          lastAudioServicesStateRef.current = audioServicesState.value.MAIN;

          if (audioServicesStateValueChanged && audioServicesState.value.MAIN === 'FAILURE') {
            setPlayerLoadingState(false);
            resetPlayerState();
            // Using setTimeout to call handleError in a separate thread
            // so as not to violate Recoil's prohibition against updating an atom within
            // the execution of a state updater function
            setTimeout(
              () =>
                handleError({
                  type: 'error',
                  error: StreamErrors.UNKNOWN_ERROR,
                }),
              10,
            );
          }

          if (
            changedAudioServicesContextProperties.volume ||
            changedAudioServicesContextProperties.isMuted
          ) {
            setPlayerVolumeState((oldState) => ({
              ...oldState,
              volume: audioServicesState.context.volume,
              isMuted: audioServicesState.context.isMuted,
            }));
          }

          if (changedAudioServicesContextProperties.rate) {
            setPlayerSpeedState((prevState) => ({
              ...prevState,
              currentRate: audioServicesState.context.rate,
            }));
          }

          if (changedAudioServicesContextProperties.playbacks) {
            setPlaybackResumePointsState(audioServicesState.context.playbacks);
          }

          if (changedAudioServicesContextProperties.collectionPlaybackMode) {
            setCollectionPlaybackModeState(audioServicesState.context.collectionPlaybackMode);
          }

          if (changedAudioServicesContextProperties.activeCollection) {
            setActiveCollection(audioServicesState.context.activeCollection);
          }

          // Everything below this line has all streamer state logic

          const { value, context: streamerContext } = streamerState ?? {};

          const streamerStateValueChanged = value !== lastStreamerStateRef.current;
          lastStreamerStateRef.current = value;

          if (!value || !streamerContext) {
            return;
          }

          const changedStreamerContextProperties = diffStreamerContext(streamerContext);

          if (
            changedStreamerContextProperties.metadata ||
            changedStreamerContextProperties.episode ||
            changedStreamerContextProperties.item ||
            changedStreamerContextProperties.skipsCount
          ) {
            const metadata = streamerContext.metadata;
            setPlayerMetadataState({
              ...metadata,
              skips: metadata?.skips,
              artist: unescapeString(metadata?.artist),
              songOrShow: unescapeString(metadata?.songOrShow),
              station: unescapeString(metadata?.station),
              dataObject: wrapContentObject(streamerContext.item),
              episodeDataObject: streamerContext.episode
                ? wrapEpisode(streamerContext.episode)
                : undefined, //canUseEpisode ? episode : undefined
              standaloneChapterDataObject: streamerContext.standaloneChapter
                ? wrapStandaloneChapter(streamerContext.standaloneChapter)
                : undefined,
            });
          }

          if (changedStreamerContextProperties.currentChapterIndex) {
            if (streamerContext.currentChapterIndex !== undefined) {
              setChapterIndexState(streamerContext.currentChapterIndex);
            } else {
              resetChapterIndexState();
            }
          }

          if (
            changedStreamerContextProperties.chapters &&
            streamerContext.episode &&
            isChaptersEnabled
          ) {
            const atom = audioServicesProvidedChapters(streamerContext.episode.getId());
            if (streamerContext.chapters) {
              set(atom, wrapChapters(streamerContext.chapters));
            } else {
              reset(atom);
            }
          }

          if (streamerStateValueChanged) {
            setPlayerLoadingState(value === 'LOADING'); // TODO: [CCS-1392] figure out approach to constants / type checking for state values
          }

          if (streamerStateValueChanged || changedStreamerContextProperties.contentType) {
            setPlayerState((oldState: IPlayerState) => ({
              ...oldState,
              contentType: streamerContext.contentType,
              playState: mapCSPlayerStateToAppPlayerState(value), // TODO: [CCS-1392] remove eventually
            }));
          }

          if (streamerStateValueChanged && value === 'FAILURE') {
            setPlayerLoadingState(false);
            resetPlayerState();
            // Using setTimeout to call handleError in a separate thread
            // so as not to violate Recoil's prohibition against updating an atom within
            // the execution of a state updater function
            setTimeout(
              () =>
                handleError({
                  type: 'error',
                  error: StreamErrors.UNKNOWN_ERROR,
                  msg: 'Playback failed',
                }),
              10,
            );
          }

          if (changedStreamerContextProperties.contentType) {
            setPlayerSpeedState((prevState) => ({
              ...prevState,
              availableRates: shouldEnablePlaybackRates(
                audioServicesState.context.activePlayableObject?.getEntityType(),
                audioServicesState.context.activePlayableObject?.getEntitySubtype(),
              )
                ? PLAYBACK_RATES
                : [],
            }));
          }

          if (
            changedStreamerContextProperties.elapsed ||
            changedStreamerContextProperties.metadata
          ) {
            const { metadata, episode } = streamerContext;
            let { elapsed } = streamerContext;
            const duration = metadata?.duration;
            const start = metadata?.start;

            let progress =
              isDefined(duration) && isDefined(elapsed) ? elapsed / duration : undefined;

            // prevents scrub bar from paintaing over 100%
            if (progress && progress > 1) {
              progress = 1;
            }

            let remaining =
              isDefined(duration) && isDefined(elapsed) ? duration - elapsed : undefined;

            // ensures the scrub bar time values don't keep ticking after it has finished
            if (remaining && remaining < 0) {
              remaining = 0;
            }
            if (elapsed && duration && elapsed > duration) {
              elapsed = duration;
            }

            const fewMinsAgo = Date.now() / 1000 - 120;

            // Fake live fraction of a few minutes ago due to SSR limitation
            const playbackLiveFraction =
              episode?.isLiveOnAir() && duration && start
                ? (fewMinsAgo - start / 1000) / duration
                : undefined;

            setPlayerTimeState({
              duration,
              offset: elapsed,
              remaining: remaining,
              playbackFraction: progress,
              playbackLiveFraction,
            });
          }

          if (changedAudioServicesContextProperties.sleepTimerState) {
            switch (audioServicesState.context.sleepTimerState) {
              case ESleepTimerState.Idle:
                setSleepUntilEndOfEpisodeAtom(false);
                setSleepUntilAtom(0);
                break;
              case ESleepTimerState.EndOfEpisode:
                setSleepUntilEndOfEpisodeAtom(true);
                break;
              default:
                setSleepUntilEndOfEpisodeAtom(false);
                setSleepUntilAtom(audioServicesState.context.sleepTimerState);
            }
          }
        };
      },
      [lastStreamerStateRef],
    );

  const { loading, clientServices } = useClientServices();
  const setPlayerVolumeState = useSetRecoilState(playerVolumeState);

  useEffect(() => {
    if (loading) {
      return;
    }
    const audioServices = clientServices.getAudioServices();
    setPlayerVolumeState({
      isMuted: audioServices.getMuted(),
      volume: audioServices.getVolume(),
    });
  }, [clientServices, loading, setPlayerVolumeState]);

  useEffect((): (() => void) | undefined => {
    if (loading) {
      return;
    }
    clientServices.getAudioServices().setEventListener(handleEvent);
    return () => clientServices.getAudioServices().setEventListener(() => {});
  }, [clientServices, handleEvent, loading, onEvent]);

  return null;
};

export default PlayerStateConnector;
