import { ClickFeature, LocalStorageKey } from '@audacy-clients/client-services/core';
import { isPlaylistClickFeature } from '@audacy-clients/client-services/src/utils/playlistFeature';
import { checkPlayingById, isInPlayer } from '@audacy-clients/core/atoms/player';
import { IPlayContext, IPlayOptions } from '@audacy-clients/core/hooks/useAudioHelpers';
import { useClientServices } from '@audacy-clients/core/utils/clientServices';
import { useRecoilCallback } from 'recoil';

import { useAudioHelpersWeb } from '~/hooks/use-audio-helpers-web';

export const usePlayOrPause = (
  contentId?: string,
  collectionId?: string,
): ((playContext: IPlayContext, options?: IPlayOptions) => void) => {
  const audioHelpers = useAudioHelpersWeb();
  const { clientServices } = useClientServices();

  return useRecoilCallback(
    ({ snapshot }) =>
      async (playContext: IPlayContext, options?: IPlayOptions) => {
        if (!contentId) {
          return undefined;
        }

        const isPlaylistFeature = isPlaylistClickFeature(playContext.feature);

        const [isPlaying, currentlySelected] = await Promise.all([
          snapshot.getPromise(checkPlayingById({ contentId })),
          snapshot.getPromise(isInPlayer(contentId)),
        ]);

        if (!isPlaying) {
          if (currentlySelected) {
            audioHelpers.resume(playContext);
          } else if (playContext.feature === ClickFeature.QUEUE) {
            audioHelpers.playQueue(contentId, playContext);
          } else if (isPlaylistFeature) {
            clientServices
              .getPersonalizationServices()
              .dataStore.setDataSync(LocalStorageKey.COLLECTION_ID, collectionId ?? contentId);
            playContext.playlistId = collectionId;
            playContext.contentId = contentId;

            audioHelpers.playPlaylist({
              collectionId: collectionId ?? contentId,
              itemId: contentId,
              playContext,
            });
          } else if (options?.episode?.isLive) {
            audioHelpers.playEpisodeLive(options.episode, playContext);
          } else if (options?.episode) {
            audioHelpers.play(options.episode.getDataObject(), playContext);
          } else {
            audioHelpers.pause(playContext);
            audioHelpers.playFromId(contentId, playContext, options);
          }
        } else {
          audioHelpers.pause(playContext);
        }
      },
    [audioHelpers, collectionId, contentId],
  );
};
