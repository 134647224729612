import { useState } from 'react';
import TranscriptContent from '~/components/Transcript/TranscriptContent';
import styles from './styles';

const TranscriptPanel = (): JSX.Element => {
  const [isContentExpanded, setIsContentExpanded] = useState(false);

  const toggleContentExpansion = () => {
    setIsContentExpanded((prev) => !prev);
  };

  return (
    <>
      <div css={[styles.transcriptContainer, !isContentExpanded ? styles.collapsedContainer : {}]}>
        <div css={styles.transcriptHeader}>
          <h2 css={styles.transcriptTitle}>Transcript</h2>
          <button css={styles.toggleButton} onClick={toggleContentExpansion}>
            {isContentExpanded ? 'Collapse' : 'Expand'}
          </button>
        </div>

        {isContentExpanded && (
          <div css={styles.transcriptContent}>
            <TranscriptContent />
          </div>
        )}
      </div>
    </>
  );
};

export default TranscriptPanel;
