import { AuthState, LocalStorageKey } from '@audacy-clients/client-services/core';
import { eraseRubiconUserId } from '@audacy-clients/client-services/src/utils/magnite';
import { auth, isLoggedIn, isSoftDeletedState } from '@audacy-clients/core/atoms/auth';
import { locationMarketsState } from '@audacy-clients/core/atoms/location';
import { playbackResumePointsState } from '@audacy-clients/core/atoms/playbackResumePoints';
import { getProfileData } from '@audacy-clients/core/atoms/profile';
import { checkIfHasIncompleteProfileData } from '@audacy-clients/core/hooks/useProfile';
import clientServices, {
  getClientServices,
  useClientServices,
} from '@audacy-clients/core/utils/clientServices';
import { getRecoil, setRecoil, refreshRecoil } from '@audacy-clients/core/utils/recoil';
import { useCallback } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { appInitializationState } from '~/atoms/app';
import { Path } from '~/routes';
import { viewContext } from '~/state/dataEvents';
import Auth from '~/utils/auth';

type IUseAuth = {
  isLoggedIn: boolean;
  logIn: () => Promise<void>;
  logInAnonymous: () => void;
  logOut: () => Promise<void>;
}

const useAuth = (): IUseAuth => {
  const isAppInitialized = useRecoilValue(appInitializationState);
  const loggedInLoadable = useRecoilValueLoadable(isLoggedIn); // because async atom, needs Suspence to work
  const { clientServices } = useClientServices();

  const onLogIn = useCallback(async () => {
    await logIn();
    const playbacks: Record<string, number> | undefined = clientServices
      .getPersonalizationServices()
      .dataStore.getDataSync(LocalStorageKey.PLAYBACKS);
    playbacks && setRecoil(playbackResumePointsState, playbacks);
  }, [clientServices]);

  let loggedIn = false;

  switch (loggedInLoadable.state) {
    case 'hasValue':
      loggedIn = loggedInLoadable.contents;
      break;
    case 'loading':
      loggedIn = false;
      break;
    case 'hasError':
      loggedIn = false;
      break;
  }

  return {
    // Need one render loop to avoid SSR VS CSR conflicts
    isLoggedIn: !!(isAppInitialized && loggedIn),
    logIn: onLogIn,
    logInAnonymous,
    logOut,
  };
};

export default useAuth;

export const logIn = async (): Promise<void> => {
  const cs = await getClientServices();
  const url = cs.getPersonalizationServices().authUrl;
  const dataEvent = await Auth.getToken(url);

  if (!dataEvent) {
    await cs.getPersonalizationServices().setAuthState({ state: AuthState.NONE });
    return;
  }

  eraseRubiconUserId();

  cs.getPersonalizationServices().setIsSoftDeleted(dataEvent.data?.isSoftDeleted);
  await cs.getPersonalizationServices().setAuthState({
    state: AuthState.AUTH,
    data: dataEvent.data,
    isTrusted: dataEvent.isTrusted,
    origin: dataEvent.origin,
  });

  const newAuthState = cs.getPersonalizationServices().getAuthState();
  setRecoil(auth, newAuthState);

  const isSoftDeleted = await cs.getPersonalizationServices().getIsSoftDeleted();
  setRecoil(isSoftDeletedState, isSoftDeleted);

  const markets = await cs.getDataServices().getUserMarkets(undefined, undefined, true);
  setRecoil(locationMarketsState, { markets });
  localStorage.setItem('locationMarketsState', JSON.stringify({ markets }));

  const incompleteProfileData = await clientServices
    .getPersonalizationServices()
    .getIncompleteProfileData();

  const hasIncompleteProfileData = !!incompleteProfileData.length;

  if (hasIncompleteProfileData) {
    const isStepOne =
      incompleteProfileData.includes('firstName') || incompleteProfileData.includes('lastName');
    const registrationStep = isStepOne ? Path.RegistrationStepOne : Path.RegistrationStepTwo;
    window.location.href = registrationStep;
  }

  cs.getAnalyticServices().sendLoginLogout(
    getRecoil(viewContext),
    hasIncompleteProfileData,
    dataEvent.data.loginMethod,
    true,
  );
};

export const logOut = async (): Promise<void> => {
  const cs = await getClientServices();

  try {
    const logoutUrl = await cs.getPersonalizationServices().executeLogout();

    if (logoutUrl) {
      await Auth.clearToken(logoutUrl);
    }
  } catch (_e) {
    // TODO: [A2-3894] handle error
  }

  const hasIncompleteProfileData = await checkIfHasIncompleteProfileData();

  cs.getAnalyticServices().sendLoginLogout(getRecoil(viewContext), hasIncompleteProfileData);

  // Whether or not frontchannel (FA) logout succeeds,
  // set the client to a logged out state

  // reset market state
  setRecoil(locationMarketsState, { markets: [] });
  localStorage.removeItem('locationMarketsState');
  localStorage.removeItem(LocalStorageKey.BRAZE_DEVICE_ID)

  eraseRubiconUserId();

  await logInAnonymous();
  window.location.href = Path.Home;

  refreshRecoil(getProfileData);
};

export const logInAnonymous = async (): Promise<void> => {
  const cs = await getClientServices();
  await cs.getPersonalizationServices().setAuthState({ state: AuthState.ANON });
  const userToken = cs.getPersonalizationServices().dataStore.getDataSync<string>(LocalStorageKey.USER_TOKEN)
  cs.getPersonalizationServices().dataStore.setDataSync(LocalStorageKey.BRAZE_DEVICE_ID, userToken)
  setRecoil(auth, cs.getPersonalizationServices().getAuthState());
};
