import { assign, createMachine, send } from 'xstate';
import { choose } from 'xstate/lib/actions';

import { PlayerAction, PlayerMetric, SaveResumePointTimings } from '../../../Constants';
import { handleErrorAction, handleTimeoutAction, setQuickPauseChapterCache } from '../../../utils';
import {
  type TStreamerMachineEvent,
  EContentType,
  type TPlayableObject,
  type IFailure,
} from '../../types';
import {
  AUTO_TRANSITION,
  COMMON_ACTIONS,
  LOADING_TIMEOUT,
  MAX_RETRY_ATTEMPTS,
  NON_REWINDABLE_ERROR,
  SCHEDULE_UPDATE_INTERVAL,
  SILENCE_TIMEOUT,
} from '../constants';
import { type IStreamerMachineContext, type TStreamSelector } from '../types';
import {
  DEFAULT_PLAYER_ACTIONS,
  getExponentialBackoffDelay,
  getFirstAudioSourceUrl,
  isContentTypeAd,
  toIntegerOrUndefined,
} from '../utils';
import { type IRewindProvider, type IRewindProviderContext } from './provider';
import { getPlayableDataObject, mergeMetadata, normalizeResumePoint } from './sharedUtils';
import { serverSideRewindProvider } from './ssrUtils';

// Test station slugs, e.g. /stations/wfan, on STG backend:
// Normal stations: wfan, 94wip, 1010wins, etc.
// Non-rewindable test case: test670thescore
// Alternate rewind/non-rewind each hour: test1010wins
// Also recommend setting useHls (below and in HtmlPlayer.ts) to false to test non-HLS on desktop Safari

const { RESUME_POINT_INTERVAL_MSEC } = SaveResumePointTimings;
const { saveResumePoints } = serverSideRewindProvider;

// TODO: [CCS-2787] check for expired episode
export type IRewindStationMachineContext = {
  rewindContext: IRewindProviderContext;
  rewindProvider: IRewindProvider<IRewindProviderContext>;
} & IStreamerMachineContext<TPlayableObject>;

export const REWIND_MACHINE_ID = 'rewindStationMachine';

type TLegacyRewindStationMachineServices = {
  getSchedule: {
    data: Awaited<ReturnType<IRewindProvider<IRewindProviderContext>['getSchedule']>>;
  };
  getChapters: {
    data: Awaited<ReturnType<IRewindProvider<IRewindProviderContext>['getChapters']>>;
  };
  loadStationSources: {
    data: Awaited<ReturnType<IRewindProvider<IRewindProviderContext>['loadSources']>>;
  };
};

const logTransition = (state: string) => {
  return (context: IRewindStationMachineContext) => {
    context.ddLogger?.info(
      `Streamer transition to ${state} for ${context.item.getId()} - ${context.item.getTitle()}`,
    );
  };
};

const SEEK_ACTIONS = {
  GO_TO_LIVE: {
    actions: ['stop', 'exitRewindMode', 'setPlayLive'],
    target: 'LOADING',
  },
  SEEK_TIME: [
    {
      cond: 'canSeekWithinSegment',
      actions: 'seekWithinSegment',
    },
    {
      cond: 'hasSeekedToEndOfStandaloneChapter',
      actions: ['stop', 'resetResumePoint', 'handleRewindContentEnded', logTransition('PAUSED')],
      target: 'PAUSED',
    },
    {
      actions: ['stop', 'assignSeekTime', 'saveResumePoint'],
      target: 'LOADING',
    },
  ],
};

const IS_VOD_AD_ERROR = {
  // CCS-2706: fixes edgecase where playback fails when loading vod stream during Ad
  cond: 'isVodAdError',
  actions: ['stop', 'exitRewindMode', 'setPlayLive'],
  target: 'LOADING',
};

const rewindStationMachine = createMachine(
  {
    predictableActionArguments: true,
    id: 'rewindStationMachine',
    initial: 'LOADING',
    tsTypes: {} as import('./index.typegen').Typegen0,
    schema: {
      context: {
        timeSinceLastSavedResumePoint: Date.now(),
      } as IRewindStationMachineContext,
      events: {} as TStreamerMachineEvent,
      services: {} as TLegacyRewindStationMachineServices,
    },
    states: {
      LOADING: {
        id: 'loading',
        entry: 'assignDefaults',
        initial: 'LOADING_SOURCES',
        states: {
          LOADING_SOURCES: {
            invoke: {
              id: 'loadStationSources',
              src: 'loadStationSources',
              onDone: {
                actions: ['assignRewindContext', logTransition('LOADING_SCHEDULE')],
                target: 'LOADING_SCHEDULE',
              },
              onError: {
                actions: [
                  logTransition('FAILED_TO_LOAD'),
                  handleErrorAction('LOADING_SOURCES', 'rewindStationMachine'),
                ],
                target: 'FAILED_TO_LOAD',
              },
            },
            after: {
              [LOADING_TIMEOUT]: {
                actions: [
                  logTransition('FAILED_TO_LOAD'),
                  handleTimeoutAction('LOADING_SOURCES', 'rewindStationMachine'),
                ],
                target: 'FAILED_TO_LOAD',
              },
            },
          },
          LOADING_SCHEDULE: {
            invoke: {
              id: 'getSchedule',
              src: 'getSchedule',
              onDone: {
                actions: [
                  'assignScheduleResponse',
                  'assignMetadata',
                  'assignCurrentChapterIndexWithOffset',
                  'loadAudio',
                  'clearPlayLive',
                ],
              },
              onError: {
                actions: [
                  handleErrorAction('LOADING_SCHEDULE', 'rewindStationMachine'),
                  logTransition('FAILED_TO_LOAD'),
                ],
                target: 'FAILED_TO_LOAD',
              },
            },
            after: {
              [LOADING_TIMEOUT]: {
                actions: [
                  logTransition('FAILED_TO_LOAD'),
                  handleTimeoutAction('LOADING_SCHEDULE', 'rewindStationMachine'),
                ],
                target: 'FAILED_TO_LOAD',
              },
            },
          },
          FAILED_TO_LOAD: {
            entry: ['logLoadingError', 'assignTrackError'],
            after: [
              {
                delay: AUTO_TRANSITION,
                actions: logTransition('FAILURE'),
                target: '#failureWithRetry',
              },
            ],
          },
        },
        on: {
          REPORT_LOADED: {
            actions: logTransition('LOADED'),
            target: 'LOADED',
          },
          REPORT_FAILURE: [
            { ...IS_VOD_AD_ERROR },
            {
              actions: [
                'logPlaybackError',
                'sendPlaybackErrorEvent',
                'assignTrackError',
                logTransition('FAILURE'),
              ],
              target: '#failureWithRetry',
            },
          ],
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          PAUSE: {
            target: 'PAUSED',
            actions: ['stop', logTransition('PAUSED')],
          },
          ...COMMON_ACTIONS,
        },
      },
      LOADED: {
        entry: 'autoplay',
        on: {
          PLAY: {
            actions: logTransition('PLAY_REQUESTED'),
            target: 'PLAY_REQUESTED',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          // Destroy stream when autoplay is false to stop infinite HLS buffering
          // Go straight to PAUSED because the audio was never loaded in the first place on mobile
          PAUSE: {
            target: 'PAUSED',
            actions: ['stop', logTransition('PAUSED')],
          },
          ...COMMON_ACTIONS,
        },
      },
      PLAY_REQUESTED: {
        id: 'playRequested',
        entry: 'playWithOffset',
        on: {
          REPORT_PLAYING: {
            actions: ['sendPlayEvent', 'addToHistory', logTransition('PLAYING')],
            target: 'PLAYING',
          },
          REPORT_METADATA: {
            actions: 'assignMetadata',
          },
          REPORT_AUTOPLAY_POLICY: {
            actions: logTransition('PAUSED'),
            target: 'PAUSED',
          },
          PAUSE: {
            target: 'PAUSED',
            actions: ['stop', logTransition('PAUSED')],
          },
          REPORT_FAILURE: [
            { ...IS_VOD_AD_ERROR },
            {
              actions: ['logPlaybackError', 'assignTrackError', logTransition('FAILURE')],
              target: '#failureWithRetry',
            },
          ],
          ...COMMON_ACTIONS,
        },
        after: {
          [LOADING_TIMEOUT]: {
            actions: logTransition('FAILURE'),
            target: '#failureWithRetry',
          },
        },
      },
      PLAYING: {
        initial: 'IS_PLAYING',
        entry: 'assignRetryCountToZero',
        states: {
          IS_PLAYING: {
            on: {
              REPORT_METADATA: {
                actions: ['assignMetadata', 'clearPlayLive'],
                target: 'UPDATING_CHAPTERS',
              },
              REPORT_FAILURE: {
                actions: [
                  'logPlaybackError',
                  'sendPlaybackErrorEvent',
                  'assignTrackError',
                  logTransition('IS_ERROR'),
                ],
                target: 'IS_ERROR',
              },
              REPORT_TIME_UPDATE: [
                {
                  cond: 'isPlayingAfterEntityEnd',
                  actions: [
                    'assignTime',
                    'saveResumePoint',
                    'resetElapsed',
                    logTransition('UPDATING_SCHEDULE'),
                  ],
                  target: 'UPDATING_SCHEDULE',
                },
                {
                  target: 'IS_PLAYING',
                  actions: [
                    'assignTime',
                    'assignCurrentChapterIndex',
                    'saveLocalResumePoint',
                    logTransition('IS_PLAYING'),
                  ],
                },
              ],
            },
            after: [
              {
                delay: SCHEDULE_UPDATE_INTERVAL,
                actions: logTransition('UPDATING_SCHEDULE'),
                target: 'UPDATING_SCHEDULE',
              },
              {
                delay: SILENCE_TIMEOUT,
                actions: logTransition('IS_FATAL_ERROR'),
                target: 'IS_FATAL_ERROR',
              },
            ],
          },
          UPDATING_SCHEDULE: {
            invoke: {
              id: 'getSchedule',
              src: 'getSchedule',
              onDone: [
                {
                  cond: 'isNonRewindableContent',
                  actions: [
                    'stop',
                    'exitRewindMode',
                    'setPlayLive',
                    handleErrorAction('UPDATING_SCHEDULE', 'rewindStationMachine'),
                  ],
                  target: '#loading',
                },
                {
                  actions: [
                    'assignScheduleResponse',
                    'assignMetadata',
                    'addEpisodeToHistory',
                    'assignCurrentChapterIndex',
                    logTransition('IS_PLAYING'),
                  ],
                  target: 'IS_PLAYING',
                },
              ],
              onError: [
                {
                  cond: 'isNonRewindableContentError',
                  actions: [
                    'stop',
                    'exitRewindMode',
                    'setPlayLive',
                    handleErrorAction('UPDATING_SCHEDULE', 'rewindStationMachine'),
                  ],
                  target: '#loading',
                },
                {
                  actions: [
                    logTransition('IS_ERROR'),
                    handleErrorAction('UPDATING_SCHEDULE', 'rewindStationMachine'),
                  ],
                  target: 'IS_ERROR',
                },
              ],
            },
          },
          UPDATING_CHAPTERS: {
            invoke: {
              id: 'getChapters',
              src: 'getChapters',
              onDone: {
                actions: ['assignChapterResponse', 'assignCurrentChapterIndex'],
                target: 'IS_PLAYING',
              },
            },
          },
          IS_ERROR: {
            on: {
              REPORT_FAILURE: {
                actions: ['logPlaybackError', 'sendPlaybackErrorEvent', 'assignTrackError'],
              },
              REPORT_TIME_UPDATE: {
                target: 'IS_PLAYING',
                actions: choose([
                  {
                    cond: 'isPlayingAfterEntityEnd',
                    actions: ['sendReportEnded', logTransition('IS_PLAYING')],
                  },
                  {
                    actions: [
                      'assignTime',
                      'assignCurrentChapterIndex',
                      logTransition('IS_PLAYING'),
                    ],
                  },
                ]),
              },
            },
            after: [
              {
                delay: SILENCE_TIMEOUT,
                actions: logTransition('IS_FATAL_ERROR'),
                target: 'IS_FATAL_ERROR',
              },
            ],
          },
          IS_FATAL_ERROR: {
            after: [
              {
                delay: AUTO_TRANSITION,
                actions: logTransition('FAILURE'),
                target: '#failureWithRetry',
              },
            ],
          },
        },
        on: {
          ...SEEK_ACTIONS,
          REPORT_ENDED: [
            {
              cond: 'isStandaloneChapter',
              actions: [
                'stop',
                'handleRewindContentEnded',
                'sendStopEvent',
                logTransition('PAUSED'),
              ],
              target: 'PAUSED',
            },
            {
              cond: 'isPlayingRewindContent',
              actions: [
                'stop',
                'handleRewindContentEnded',
                'sendStopEvent',
                logTransition('LOADING'),
              ],
              target: 'LOADING',
            },
          ],
          REPORT_CONTINUE_PLAYING: [
            {
              cond: 'shouldStandaloneChapterStop',
              actions: [
                'stop',
                'resetResumePoint',
                'handleRewindContentEnded',
                logTransition('PAUSED'),
              ],
              target: 'PAUSED',
            },
            {
              target: 'LOADING',
              actions: ['setIsLiveAndRequestedOffset'],
            },
          ],
          REPORT_PAUSED: [
            {
              cond: 'isStandaloneChapter',
              actions: [
                'stop',
                'handleRewindContentEnded',
                'sendStopEvent',
                logTransition('PAUSED'),
              ],
              target: 'PAUSED',
            },
            {
              actions: [logTransition('PAUSED')],
              target: 'PAUSED',
            },
          ],
          PAUSE: {
            actions: logTransition('PAUSE_REQUESTED'),
            target: 'PAUSE_REQUESTED',
          },
          STOP: {
            actions: logTransition('PAUSE_REQUESTED'),
            target: 'PAUSE_REQUESTED',
          },
          REPORT_TIME_UPDATE: [
            {
              cond: 'isPlayingAfterEntityEnd',
              actions: 'sendReportEnded',
            },
            {
              actions: 'assignTime',
            },
          ],
          REPORT_METADATA: {
            actions: ['assignMetadata', 'clearPlayLive'],
          },
          ...COMMON_ACTIONS,
        },
      },
      PAUSE_REQUESTED: {
        entry: choose([
          {
            cond: 'isAd',
            actions: 'pause',
          },
          {
            cond: 'isRewindable',
            actions: ['saveResumePoint', 'cacheChaptersOnPause', 'stop'],
          },
          {
            actions: 'stop',
          },
        ]),
        on: {
          ...COMMON_ACTIONS,
          REPORT_PAUSED: {
            actions: logTransition('PAUSED'),
            target: 'PAUSED',
          },
        },
        after: {
          [LOADING_TIMEOUT]: {
            actions: logTransition('FAILURE'),
            target: 'FAILURE',
          },
        },
      },
      PAUSED: {
        entry: ['sendStopEvent', 'assignRetryCountToZero'],
        on: {
          ...SEEK_ACTIONS,
          PLAY: [
            {
              cond: 'isAd',
              actions: logTransition('PLAY_REQUESTED'),
              target: 'PLAY_REQUESTED',
            },
            {
              actions: logTransition('LOADING'),
              target: 'LOADING',
            },
          ],
          ...COMMON_ACTIONS,
        },
      },
      ENDED: {
        on: {
          ...SEEK_ACTIONS,
          ...COMMON_ACTIONS,
        },
      },
      FAILURE_WITH_RETRY: {
        id: 'failureWithRetry',
        entry: ['assignIncrementRetryCount', 'logRetry'],
        on: {
          PLAY: {
            target: 'LOADING',
          },
          PAUSE: {
            target: 'PAUSED',
            actions: 'stop',
          },
          ...COMMON_ACTIONS,
        },
        after: [
          {
            target: 'FAILURE',
            delay: (context) => getExponentialBackoffDelay(context.retryCount),
            cond: ({ retryCount = 0 }) => retryCount >= MAX_RETRY_ATTEMPTS,
          },
          {
            target: 'LOADING',
            delay: (context) => getExponentialBackoffDelay(context.retryCount),
            cond: ({ retryCount = 0 }) => retryCount < MAX_RETRY_ATTEMPTS,
          },
        ],
      },
      FAILURE: {
        id: 'failure',
        entry: ['logPlaybackFailure', 'stop', 'sendPlaybackFailedEvent'],
        on: {
          ...COMMON_ACTIONS,
        },
        after: [
          {
            delay: AUTO_TRANSITION,
            actions: logTransition('PAUSE_REQUESTED'),
            target: 'PAUSE_REQUESTED',
          },
        ],
      },
      DESTROYED: {
        entry: ['stop', 'sendStopEvent', 'saveResumePoint'],
        type: 'final',
      },
    },
  },
  {
    services: {
      getChapters: (ctx) => {
        const { rewindContext, rewindProvider } = ctx;
        return rewindProvider.getChapters(rewindContext, ctx);
      },
      getSchedule: (ctx) => {
        const { rewindContext, rewindProvider } = ctx;
        return rewindProvider.getSchedule(rewindContext, ctx);
      },
      loadStationSources: (ctx) => {
        const { rewindContext, rewindProvider } = ctx;
        return rewindProvider.loadSources(rewindContext, ctx);
      },
    },
    guards: {
      // Check if playing non-live content
      isPlayingRewindContent: (ctx) => {
        const isPlayingRewindContent = ctx.rewindProvider.isPlayingRewindContent(
          ctx,
          ctx.rewindContext,
        );
        return isPlayingRewindContent;
      },
      isPlayingAfterEntityEnd: (ctx) => {
        const { episode, elapsed, standaloneChapter } = ctx;
        const entity = standaloneChapter ?? episode;
        const isPlayingAfterEntityEnd = (elapsed ?? 0) > (entity?.getDuration() ?? 0);
        return isPlayingAfterEntityEnd;
      },
      // This is dead code, always false
      canSeekWithinSegment: (ctx, { time }) => {
        const { episode, rewindContext, rewindProvider } = ctx;
        return episode
          ? rewindProvider.canSeekToTimeWithoutLoading(ctx, rewindContext, time)
          : false;
      },
      isAd: ({ metadata }) => isContentTypeAd(metadata?.contentType),
      isRewindable: (ctx) => {
        const { rewindProvider, rewindContext } = ctx;
        return rewindProvider.isRewindable(ctx, rewindContext);
      },
      isNonRewindableContent: (ctx) => {
        return ctx.contentType === EContentType.Rewind && !ctx.episode?.isRewindable();
      },
      isNonRewindableContentError: (_, event) => {
        const { message } = event.data as Error;
        return message === NON_REWINDABLE_ERROR;
      },
      isVodAdError: (_, event) => {
        const { error } = event;
        const extractedError = error && (error.raw || error);

        // Handles IOS problem with VOD where listener is a few minutes behind live and live point is in ad break
        // For explanation of this problem, see MR for CCS-3283
        // When in this state, AVPlayer will emit CoreMedia error code -16845, HTTP 400: (unhandled))
        // For explanation of the second conjunct, see MR for CCS-3717
        const isBreakingIOSError =
          (extractedError as { domain: string }).domain === 'CoreMediaErrorDomain' && (extractedError as { code: number }).code === -16845;

        const vodErrorUrl = (extractedError as { url: string }).url;

        const isVodUrl = vodErrorUrl ? vodErrorUrl.includes('mode=vod') : false;

        const is503Error =
          (extractedError as { response?: { code?: number } })?.response?.code === 503;

        return (isVodUrl && is503Error) || isBreakingIOSError;
      },
      isStandaloneChapter: (ctx) => !!ctx.standaloneChapter,
      shouldStandaloneChapterStop: (ctx) => {
        // when a standalone chapter ends the player still attempts a tarzan swing
        // preroll ads can exist, so we want to make sure we only stop if the contentType is content.
        // beyond that a standaloneChapter and requestedOffset exiting tell us that we want to stop.
        const { metadata, requestedOffset, standaloneChapter } = ctx;
        return !!standaloneChapter && !!requestedOffset && metadata?.contentType === 'content';
      },
      hasSeekedToEndOfStandaloneChapter: (ctx, { time }) => {
        const { standaloneChapter } = ctx;

        if (!standaloneChapter) {
          return false;
        }

        const startTs = normalizeResumePoint({
          resumePoint: time,
          entity: standaloneChapter,
          streamType: 'STANDALONE_CHAPTER',
        });

        if (!startTs) {
          return false;
        }

        const endTs = standaloneChapter.getEndTimeSeconds();

        return startTs >= endTs;
      },
    },
    actions: {
      // Set default metadata so we have something to show while we load the audio
      assignDefaults: assign(({ item, metadata, firstLoadTime, analyticsProvider }) => {
        if (!metadata) {
          // Initial load
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_LOAD,
            eventDetails: {
              stationId: item.getId(),
              streamerType: EContentType.Rewind,
            },
          });
        }
        return {
          firstLoadTime: firstLoadTime || Date.now(),
          metadata: metadata ?? {
            image: item.getImageSquare(),
            station: item.getTitle(),
          },
        };
      }),
      assignRetryCountToZero: assign({
        retryCount: 0,
      }),
      assignIncrementRetryCount: assign({
        retryCount: ({ retryCount = 0 }) => retryCount + 1,
      }),
      // Add station and episode to history so both are accounted for in recents
      addToHistory: (ctx) => {
        const stationId = ctx.item.getId();
        const episodeId = ctx.episode?.getId();
        const standaloneChapterId = ctx.standaloneChapter?.getId();
        const contentIdsToAddToHistory = [];
        if (stationId) {
          contentIdsToAddToHistory.push(stationId);
        }
        if (episodeId) {
          contentIdsToAddToHistory.push(episodeId);
        }
        if (standaloneChapterId) {
          contentIdsToAddToHistory.push(standaloneChapterId);
        }

        // to avoid duplication of ids
        const uniqueContentIdsToAddToHistory = [...new Set(contentIdsToAddToHistory)];
        if (uniqueContentIdsToAddToHistory.length > 0) {
          ctx.personalizationProvider.addToHistory(uniqueContentIdsToAddToHistory);
        }
      },
      // [CCS-3193]: Type coersion used temporarily to fix issue
      // Come back and fix IRewindProviderContext
      // May need to remove the RewindProviderContext type from the IRewindProvider interface
      addEpisodeToHistory: (ctx, event) => {
        const data = event.data as IRewindStationMachineContext;
        const episodeId = data.episode?.getId();
        if (episodeId) {
          ctx.personalizationProvider.addToHistory([episodeId]);
        }
      },
      assignScheduleResponse: assign(({ chapters, episode }, { data }) => ({
        ...data,
        chapters,
        episode,
      })),
      assignChapterResponse: assign((_, { data }) => ({
        chapters: data?.chapters,
      })),
      // Perhaps a little too generic, this is only used to assign rewindContext.source aka the URL to play from
      assignRewindContext: assign((_, { data }) => ({
        rewindContext: data,
      })),
      // Gets new metadata from AVPlayer/Exoplayer every second, then merge that with old metadata we may need,
      // update ctx.metadata, then in "onTransition" we send metadata to PlayerStateConnector then to Recoil atoms
      assignMetadata: assign((ctx, e) => {
        const {
          metadata,
          episode,
          standaloneChapter,
          item,
          logger,
          rewindContext,
          rewindProvider,
        } = ctx;

        const retVal: Partial<IRewindStationMachineContext> = {};

        const entity = standaloneChapter ?? episode;

        const newMetadata = 'metadata' in e ? e.metadata : undefined;

        // Takes care of edgecase where data for title is incorrect from manifest
        if (newMetadata?.songOrShow) {
          newMetadata.songOrShow = '';
          newMetadata.artist = '';
        }

        retVal.metadata = mergeMetadata({
          existingMetadata: metadata,
          newMetadata,
          logger,
          entity,
          item,
        });

        retVal.contentType = rewindProvider.isRewindable(ctx, rewindContext)
          ? EContentType.Rewind
          : EContentType.RewindNoReplay;

        return retVal;
      }),
      assignTrackError: assign(({ errors }, event) => {
        const { error, errorMessage = 'Unknown Error' } = (event as IFailure) || {};
        const extractedError = error ? error.raw || error : errorMessage;

        return {
          errors: [
            // Save the 5 most recent errors to send to Datadog
            { errorMessage, context: extractedError },
            ...(errors || []),
          ].slice(0, 5),
        };
      }),
      // Exit rewind mode and go back to live
      // TODO: [CCS-986]: Potentially need to accomodate StandaloneChapter
      exitRewindMode: assign(({ rewindProvider, rewindContext }) => {
        return {
          rewindContext: rewindProvider.exitRewindMode(rewindContext),
          requestedOffset: undefined,
          episode: undefined,
          rewindProvider: {
            ...rewindProvider,
            playLive: true,
          },
        };
      }),
      sendReportEnded: send({
        type: 'REPORT_ENDED',
      }),
      // This is dead code
      seekWithinSegment: (ctx, { time }) => {
        const { rewindContext, rewindProvider, player } = ctx;
        const position = rewindProvider.mapBufferedSeekTime(rewindContext, ctx, time);
        if (position !== undefined) {
          player?.setPosition(position);
        }
      },
      playWithOffset: (ctx) => {
        // Offset is already in the startTs of the audio URL
        return ctx.player.play();
      },
      saveResumePoint: assign((ctx) => saveResumePoints(ctx, true)),
      // This is geared towards making less API requests as a result of Audacy going down due to nationwide FEMA alert.
      cacheChaptersOnPause: (ctx) => {
        const id = ctx.episode?.getId() || '';
        setQuickPauseChapterCache(id);
      },
      // Save the resume point every few seconds locally aka without API calls
      // With this approach we can save often and never lose our place.
      // using ts-ignore because, otherwise it throws error on using constant RESUME_POINT_INTERVAL_MSEC from other file
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      saveLocalResumePoint: assign((context) => {
        if (!context.timeSinceLastSavedResumePoint) {
          context.timeSinceLastSavedResumePoint = Date.now();
        }
        const currentTime = Date.now();
        const updateTime = context.timeSinceLastSavedResumePoint + RESUME_POINT_INTERVAL_MSEC;

        if (currentTime >= updateTime) {
          return saveResumePoints(context, false);
        }
        return context;
      }),
      // Reset the resume point to the beginning of the episode
      // Used when we finished an episode but don't want to resume from the end
      // Used to recover when GoLive playback fails as a workaround for a backend issue
      resetResumePoint: assign((ctx) => {
        const { rewindProvider, elapsed, personalizationProvider, episode, standaloneChapter } =
          ctx;

        const entity = standaloneChapter ?? episode;

        if (!entity || !elapsed) {
          return ctx;
        }

        const resumePoint = entity.getStartTimeSeconds();

        personalizationProvider.setPlayback(entity.getId(), resumePoint);

        ctx.elapsed = 0;
        return rewindProvider.saveResumePoint(ctx, resumePoint);
      }),
      resetElapsed: assign((ctx) => {
        ctx.elapsed = 0;
        return ctx;
      }),
      // When we seek we need to update requestedOffset, elapsed, seekingInChapter etc.
      assignSeekTime: assign((ctx, { time }) => {
        const {
          episode,
          standaloneChapter,
          analyticsProvider,
          elapsed,
          rewindContext,
          rewindProvider,
          metadata: { isLive = false } = {},
          deviceInfoProvider,
        } = ctx;

        const entity = standaloneChapter ?? episode;

        const [newRewindContext, partialContextUpdate] = rewindProvider.performSeek(rewindContext, {
          ...ctx,
          time,
        });

        const audioRoute = deviceInfoProvider?.getAudioRoute();

        if (elapsed !== undefined && entity?.data.id) {
          analyticsProvider?.sendPlayerEvent({
            type: time > elapsed ? PlayerAction.FAST_FORWARD : PlayerAction.REWIND,
            contentId: entity?.data.id,
            rewindFlag: isLive,
            currentPosition: toIntegerOrUndefined(entity.getStartTimeSeconds() + elapsed),
            streamUrl: getFirstAudioSourceUrl(entity?.data.streamUrl),
            connectionType: audioRoute,
          });
        }
        return {
          rewindContext: newRewindContext,
          ...partialContextUpdate,
        };
      }),
      // Every second or we get a time update from AVPlayer/Exoplayer/HLS.js
      // Here we use that update to update the "elapsed" value which affects the timestamps in the UI
      assignTime: assign(({ rewindProvider, rewindContext, ...rest }, { time }) => ({
        ...rewindProvider.mapPlayerTime(rewindContext, {
          ...rest,
          time,
        }),
      })),
      // Every second and whenever we scrub, we could be going into a different chapter and need to sync this
      assignCurrentChapterIndex: assign((ctx) => {
        const { currentChapterIndex } = ctx.rewindProvider.getCurrentChapterIndex(ctx);
        return {
          currentChapterIndex,
        };
      }),
      // Before loading audio, we need to set the correct chapter index
      assignCurrentChapterIndexWithOffset: assign((ctx) => {
        const { currentChapterIndex, seekingInChapter } = ctx.rewindProvider.getCurrentChapterIndex(
          ctx,
          ctx.rewindContext,
          true,
        );

        return {
          currentChapterIndex,
          rewindContext: {
            ...ctx.rewindContext,
            seekingInChapter,
          },
        };
      }),
      // This allows us to override resume points and play live radio
      // We use this in CarPlay for a feature called "Jump back in live" as well as the "Go to live" button
      setPlayLive: assign(({ rewindProvider, ...rest }: IRewindStationMachineContext) => ({
        ...rest,
        rewindProvider: {
          ...rewindProvider,
          playLive: true,
        },
      })),
      // We use this to clear the setPlayLive flag immediately after we've used it
      clearPlayLive: assign(({ rewindProvider, ...rest }: IRewindStationMachineContext) => ({
        ...rest,
        rewindProvider: {
          ...rewindProvider,
          playLive: false,
        },
      })),
      // Not sure why we have this, probably can be replaced with exitRewindMode
      handleRewindContentEnded: assign((ctx) => {
        const { rewindProvider, rewindContext, ...rest } = ctx;
        return {
          ...rewindProvider.handleRewindContentEnded(rewindContext, ctx, {
            ...rest,
          }),
        };
      }),
      // This generates a playable URL and feeds it into the native player with "player.load"
      loadAudio: assign((ctx) => {
        const { player, rewindContext, rewindProvider } = ctx;

        const loadParams = rewindProvider.getLoadParams(rewindContext, ctx);

        player?.load({
          url: loadParams.url,
          isPodcast: loadParams.isPodcast,
          isSuperHifi: false,
          isAudioPreroll: false,
          liveContentUrl: '',
          autoplay: ctx.autoplay,
        });

        return {
          rewindContext: {
            ...rewindContext,
            seekingInChapter: loadParams.seekingInChapter,
          },
        };
      }),
      setIsLiveAndRequestedOffset: assign((ctx, e) => {
        return {
          requestedOffset: e.playlistNextStartTime,
          rewindProvider: {
            ...ctx.rewindProvider,
            playLive: e.playLive,
          },
        };
      }),
      // Analytics stop event
      sendStopEvent: async ({
        analyticsProvider,
        episode,
        firstLoadTime,
        retryCount,
        errors,
        elapsed,
        contentType,
        metadata: { isLive = false } = {},
        deviceInfoProvider,
      }) => {
        const contentId = episode?.data?.id;
        if (!contentId) {
          return;
        }

        const audioRoute = deviceInfoProvider?.getAudioRoute();

        analyticsProvider.sendPlayerEvent({
          type: PlayerAction.STOP,
          contentId: contentId,
          rewindFlag: isLive,
          currentPosition: toIntegerOrUndefined(episode.getStartTimeSeconds() + (elapsed ?? 0)),
          streamUrl: getFirstAudioSourceUrl(episode?.data.streamUrl),
          connectionType: audioRoute,
        });

        const { bandwidthEstimateBPS, historicBandwidthEstimateBPS, type } =
          (await deviceInfoProvider?.getNetworkInfo()) || {};

        analyticsProvider.sendEventToListener({
          type: PlayerMetric.STREAM_PAUSE,
          eventDetails: {
            stationId: contentId,
            streamerType: contentType,
            uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
            retryCount,
            bandwidthEstimateBPS,
            historicBandwidthEstimateBPS,
            connectivityType: type,
          },
        });
        if (retryCount) {
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_RECONNECT_ABORTED,
            eventDetails: {
              stationId: contentId,
              streamerType: contentType,
              uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
              retryCount,
              mostRecentError: errors?.[0],
              bandwidthEstimateBPS,
              historicBandwidthEstimateBPS,
              connectivityType: type,
            },
          });
        }
      },
      // Analytics play event
      sendPlayEvent: async ({
        analyticsProvider,
        episode,
        firstLoadTime,
        retryCount,
        errors,
        contentType,
        elapsed,
        metadata: { isLive = false } = {},
        deviceInfoProvider,
      }) => {
        const contentId = episode?.data?.id;
        if (!contentId) {
          return;
        }

        const audioRoute = deviceInfoProvider?.getAudioRoute();

        analyticsProvider.sendPlayerEvent({
          type: PlayerAction.PLAY,
          contentId,
          rewindFlag: isLive,
          currentPosition: toIntegerOrUndefined(episode.getStartTimeSeconds() + (elapsed ?? 0)),
          streamUrl: getFirstAudioSourceUrl(episode?.data.streamUrl),
          connectionType: audioRoute,
        });

        const { bandwidthEstimateBPS, historicBandwidthEstimateBPS, type } =
          (await deviceInfoProvider?.getNetworkInfo()) || {};

        analyticsProvider.sendEventToListener({
          type: PlayerMetric.STREAM_PLAY,
          eventDetails: {
            stationId: contentId,
            streamerType: contentType,
            uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
            retryCount,
            bandwidthEstimateBPS,
            historicBandwidthEstimateBPS,
            connectivityType: type,
          },
        });
        if (retryCount) {
          analyticsProvider.sendEventToListener({
            type: PlayerMetric.STREAM_RECONNECT_SUCCESSFUL,
            eventDetails: {
              stationId: contentId,
              streamerType: contentType,
              uptimeMs: firstLoadTime ? Date.now() - firstLoadTime : 0,
              retryCount,
              mostRecentError: errors?.[0],
              bandwidthEstimateBPS,
              historicBandwidthEstimateBPS,
              connectivityType: type,
            },
          });
        }
      },
      ...DEFAULT_PLAYER_ACTIONS,
    },
  },
);

const selector: TStreamSelector<typeof rewindStationMachine> = async (
  dataObject,
  dataProvider,
  logger,
) => {
  const rewindProvider = serverSideRewindProvider;
  const rewindContext = {};

  const playableDataObject = await getPlayableDataObject(dataObject);

  if (!playableDataObject) {
    return;
  }

  logger?.info('Using server-side rewind provider', dataObject);

  const { station, episode, streamType, standaloneChapter } = playableDataObject;

  rewindProvider.streamType = streamType;

  return rewindStationMachine.withContext({
    ...rewindStationMachine.context,
    item: streamType === 'BROADCAST' ? station : dataObject, // the UI expects ctx.item to be the station not the episode in this case
    station,
    episode,
    standaloneChapter,
    rewindProvider,
    rewindContext,
  });
};

export default selector;
