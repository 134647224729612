import { transparentize } from 'polished';

export const BasicColors = {
  black: '#000000',
  transparent: 'transparent',
  transparentWhite: '#ffffff00',
  white: '#ffffff',
};

// darkest to lightest
export const GreyScale = {
  grey33: '#333333',
  grey66: '#666666',
  grey75: '#757575',
  grey80: '#808080',
  greyA6: '#A6A6A6',
  greyAA: '#aaaaaa',
  greyC5: '#C5C4C1',
  greyDF: '#DFDEDD',
  greyEB: '#EBEBEB',
};

export const AlphaColors = {
  black08: transparentize(0.92, BasicColors.black),
  black15: transparentize(0.85, BasicColors.black),
  black40: transparentize(0.6, BasicColors.black),
  black50: transparentize(0.5, BasicColors.black),
  black60: transparentize(0.4, BasicColors.black),
  white15: transparentize(0.85, BasicColors.white),
  white18: transparentize(0.82, BasicColors.white),
  white25: transparentize(0.75, BasicColors.white),
  white30: transparentize(0.7, BasicColors.white),
  white50: transparentize(0.5, BasicColors.white),
  white60: transparentize(0.4, BasicColors.white),
  white70: transparentize(0.3, BasicColors.white),
  white80: transparentize(0.2, BasicColors.white),
};

export const AudacyColors = {
  audacyCioccolato: '#673F2E',
  audacyDarkNavy: '#090527',
  audacyDarkPurple: '#0D0C24',
  audacyMatisse: '#395773',
  audacyOrange: '#FE5000',
  audacyOrange2: '#FE7333',
  audacyOrange3: '#FFB999',
  audacyBlue: '#3333C3',
  error: '#FA4949',
  audacyCrimson: '#E21C39',
  lines: AlphaColors.white15,
  mediumGray: GreyScale.greyA6,
  secondaryBrightSpruceGreen: '#2EC5A6',
  secondaryCopy: AlphaColors.white60,
  secondaryDeepBlue: '#11076F',
  secondaryDeepBlue2: '#0F0FA1',
  secondaryDeepBlue2_5: '#003594',
  secondaryDeepBlue3: '#0068B2',
  secondaryDusk: '#1F2772',
  secondaryDusk2: '#363FB2',
  secondaryDusk3: '#6879E0',
  secondaryDusk4: '#0267B2',
  secondaryEndeavour: '#0062B8',
  secondaryError: '#E12727',
  secondaryGrannySmithApple: '#99E18B',
  secondaryLightGrey: GreyScale.greyC5,
  secondaryLightGrey2: GreyScale.greyDF,
  secondaryLilac: '#9E9EDD',
  secondaryLilac2: '#B3B0E2',
  secondaryLilac3: '#D2D1ED',
  secondaryMartinique: '#2f2949',
  secondaryMediumBlue: '#005FAE',
  secondaryMediumGrey: GreyScale.grey80,
  secondaryMustard: '#CDB300',
  secondaryMustard2: '#DFC746',
  secondaryPurple: '#7A3BCB',
  secondaryPurple2: '#9466DD',
  secondarySilicaSea: '#6FE2DE',
  secondarySpruceGreen: '#024239',
  secondarySpruceGreen2: '#078574',
  secondarySpruceGreen3: '#2EC5A6',
  success: '#57DC94',
  liked: '#FC659C',
};

// The spectrum is a gradient, that goes from orange to purple
export const AudacySpectrum = {
  '0.5x': AudacyColors.audacyOrange,
  '0.6x': '#F64F0E',
  '0.7x': '#ED4E1C',
  '0.8x': '#E44D29',
  '0.9x': '#DC4B37',
  '1x': '#D34A44',
  '1.1x': '#CA4852',
  '1.2x': '#C14760',
  '1.3x': '#B8456D',
  '1.4x': '#AF447B',
  '1.5x': '#A64388',
  '1.6x': '#9E4196',
  '1.7x': '#9540A3',
  '1.8x': '#8C3FB1',
  '1.9x': '#833DBE',
  '2x': AudacyColors.secondaryPurple,
};
