import Hls from 'hls.js';
import isString from 'lodash/isString';

import { SILENT_AUDIO } from '../../Constants';
import { type ILogger } from '../../logger';
import { isAnySafari } from '../../utils/browser';
import { isContentTypeAd } from '../streamers/utils';
import audacyLogger, { LoggerTopic } from '@audacy-clients/client-services/src/AudacyLogger';
import {
  type IHtmlPlayer,
  type IPlayerLoad,
  type IPlayerMetadata,
  type IPlayerOptions,
  type TPlaybackRate,
  type TPlayerEventListener,
} from './types';
import { parseMetadata, isVodFeatureEnabled, isCloseToLive } from './utils';
import { 
  SUPERHIFI_FRAG_LOAD_POLICY, 
  SUPERHIFI_MANIFEST_LOAD_POLICY, 
  SUPERHIFI_PLAYLIST_LOAD_POLICY, 
  SUPERHIFI_STEERING_MANIFEST_LOAD_POLICY,
  AMPERWAVE_HLS_CONFIG } from './constants';

type ITrackData = {
  title?: string;
  artist?: string;
  album?: string;
  image?: string;
};

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState

enum EReadyState {
  HAVE_NOTHING = 0,
  HAVE_METADATA,
  HAVE_CURRENT_DATA,
  HAVE_FUTURE_DATA,
  HAVE_ENOUGH_DATA,
}

type IDeferredMetadata = {
  data: IPlayerMetadata;
  time: number;
};

// continuity parameter for Super Hi-Fi loader
let continuity = 0;
// action parameter for Super Hi-Fi loader
let action = 'refresh';

// Super Hi-Fi custom loader [CCS-2755]
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
class CustomPlaylistLoader extends Hls.DefaultConfig.loader {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  load(context: any, config: any, callbacks: any) {
    const url = new URL(context.url);

    // assign action from url params
    // otherwise use the last action (i.e., refresh)
    action = url.searchParams.get('action') ?? action;

    // assign continuity from last metadata update
    // otherwise, use continuity value on url
    continuity = continuity ?? Number(url.searchParams.get('continuity'));

    // if continuity is 0 and action isn't skip, take load action
    if (!continuity && action !== 'skip') {
      action = 'load';
    }

    // reset continuity to 0 if skipping
    if (action === 'skip') {
      continuity = 0;
    }

    // set action and continuity as parameters on SHF url
    url.searchParams.set('action', action);
    url.searchParams.set('continuity', String(continuity));
    context.url = url;

    super.load(context, config, callbacks);
  }
}

let currentTrack: {
  image?: string;
  artist?: string;
  title?: string;
};

let useNewSongData = false;
let shfTrackStart = 0;
let playListStart = 0;
let currentTime = 0;

export default class HtmlPlayer implements IHtmlPlayer {
  logger?: ILogger;
  private deferredMetadata: Array<IDeferredMetadata> = [];
  playerElement = document.body.appendChild(document.createElement('audio'));
  private silentAudioPlayed = false;
  private silentAudioInProgress = false;
  private defaultSpeed: TPlaybackRate = 1.0;
  private stalled = false;
  private ignoreNextLoadingEvent = false;
  private backOnline = false;
  private ignoreZeroUpdateEvent = false;
  private lastMetadata: IPlayerMetadata = {};
  private hls?: Hls;
  private eventListeners = new Set<TPlayerEventListener>();
  private ctxRate: TPlaybackRate = 1.0;
  // NOTE: CCS-1104 (VOD speed controls): Remove after full feature flag rollout
  vodFeatureEnabled = false;
  private isSuperHifi = false;
  private duration = 0;
  private url = '';
  private hasFailed = false;
  private loadParams: IPlayerLoad;

  constructor(options: IPlayerOptions = {}) {
    this.logger = options.logger;
    this.vodFeatureEnabled = isVodFeatureEnabled(options);

    const interval = 1.0;
    let nextTick = 0;
    this.defaultSpeed = 1.0;

    this.playerElement.autoplay = false;
    this.playerElement.preload = 'auto';
    this.loadParams = { url: '', isSuperHifi: false, isAudioPreroll: false, liveContentUrl: '' };

    // this.player.onabort = onEvent; // do we need this one?

    this.playerElement.onended = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native Ended Event`);
      this.logger?.trace('onEnded');

      // Checks if stream is in VOD mode by checking if there is a playlistNextStartTime
      if (
        this.lastMetadata.playlistNextStartTime &&
        this.playerElement.currentTime >= this.playerElement.duration
      ) {
        this.notify({
          type: 'continuePlaying',
          playlistNextStartTime: this.lastMetadata.playlistNextStartTime,
        });
      } else {
        this.notify({ type: 'ended', raw: e });
      }
    };

    this.playerElement.onerror = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native Error Event`);

      if (this.isEmpty(e)) {
        return;
      }

      this.logger?.error('onError', e);

      // silentAudioInProgress is set to false as soon as we start loading real audio
      // so we can ignore errors than might come in until this is false
      if (!this.silentAudioInProgress) {
        this.notify({ type: 'error', raw: e });
      }
    };

    this.playerElement.onloadstart = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native On Load Start Event`);

      this.logger?.trace('onLoadStart');
      nextTick = 0;
      if (this.ignoreNextLoadingEvent === true) {
        this.ignoreNextLoadingEvent = false;
      } else {
        this.stalled = true;
        this.notify({ type: 'loading', raw: e });
      }
    };

    this.playerElement.onloadedmetadata = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native On Loaded Metadata Event`);

      this.logger?.trace('onLoadedMetadata');

      // silent audio causes this to prematurely think we're ready to play
      // we set it to false when we load the real audio then this check will work as expected.
      if (
        !this.silentAudioInProgress &&
        this.playerElement.readyState >= EReadyState.HAVE_METADATA
      ) {
        this.notify({ type: 'loaded', raw: e });
      }
    };

    this.playerElement.onpause = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native On Pause Event`);

      this.logger?.trace('onPause');

      if (
        this.lastMetadata.playlistNextStartTime &&
        this.playerElement.currentTime >= this.playerElement.duration
      ) {
        this.notify({
          type: 'continuePlaying',
          playlistNextStartTime: this.lastMetadata.playlistNextStartTime,
        });
      } else {
        this.notify({ type: 'paused', raw: e });
      }
    };

    // When we 'stop', we destroy the stream which fires 'emptied' but not 'pause' event
    this.playerElement.onemptied = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native On Emptied Event`);

      this.logger?.trace('onEmptied');
      // on a super hifi skip, the empty event is fired, but we don't want to show the play button
      if (action !== 'skip') {
        this.notify({ type: 'paused', raw: e });
      }
    };

    this.playerElement.onplaying = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native On Playing Event`);

      this.logger?.trace('onPlaying');
      this.stalled = false;
      this.hasFailed = false;
      this.notify({ type: 'playing', raw: e });
    };

    this.playerElement.onseeked = () => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native On Seeked Event`);

      this.logger?.trace('onSeeked');
      nextTick = 0;
    };

    this.playerElement.onstalled = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native On Stalled Event`);

      this.logger?.trace('onStalled');
      this.stalled = true;
      this.notify({ type: 'loading', raw: e });
    };

    this.playerElement.onwaiting = (e) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native On Waiting Event`);

      this.logger?.trace('onWaiting');
      this.stalled = true;
      if (this.backOnline === true) {
        this.backOnline = false;
      }
      this.notify({ type: 'loading', raw: e });
    };

    this.playerElement.ontimeupdate = (e) => {
     // At 4 times pers second this would likely be too verbose for even verbose logs to leaving this out for now. 
     // audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native On Load Start Event`);

      this.logger?.trace('onTimeUpdate');
      if (this.stalled === true) {
        this.stalled = false;
        this.notify({ type: 'playing', raw: e });
      }

      if (this.isSuperHifi) {
        this.handleSuperHifiTimeUpdate(e);
      } else {
        const current = this.playerElement.currentTime;

        if (current >= nextTick && (this.ignoreZeroUpdateEvent !== true || current !== 0)) {
          this.ignoreZeroUpdateEvent = false;
          nextTick = current + interval;
          this.notify({
            type: 'update',
            time: current,
            duration: this.playerElement.duration,
            raw: e,
          });
        }
        this.ignoreZeroUpdateEvent = false;
      }
    };

    this.playerElement.textTracks.addEventListener('addtrack', function (e1) {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native Add Track Event`);

      e1.track?.addEventListener('cuechange', function (e2) {
        audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Native Cue Change Event`);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const cues = e2.target?.activeCues || [];
        let trackData: ITrackData = {};
        for (let i = 0; i < cues?.length; i++) {
          if (cues[i].track.kind == 'metadata') {
            if (!trackData) {
              trackData = {};
            }
            switch (cues[i].value?.key) {
              case 'TIT2':
                trackData.title = cues[i].value?.data;
                break;
              case 'TPE1':
                trackData.artist = cues[i].value?.data;
                break;
              case 'TALB':
                trackData.album = cues[i].value?.data;
                break;
              case 'WXXX':
                trackData.image = cues[i].value?.data;
                break;
            }
          }
        }
        if (
          trackData &&
          currentTrack?.title !== trackData?.title &&
          currentTrack?.artist !== trackData?.artist &&
          currentTrack?.image !== trackData.image
        ) {
          const now = Date.now() / 1000;
          currentTrack = trackData;
          useNewSongData = true;
          shfTrackStart = currentTime || 0;

          if (!playListStart) {
            playListStart = now;
          }
        }
      });
    });
  }

  handleSuperHifiTimeUpdate(e: Event): void {
    currentTime = this.playerElement.currentTime;
    const current = this.playerElement.currentTime - shfTrackStart;

    this.notify({
      type: 'update',
      time: current,
      duration: this.playerElement.duration,
      raw: e,
    });
  }

  parseHlsMetadata(data: string, startDts?: number) {
    this.logger?.trace('parseHlsMetadata');
    try {
      const parsed = parseMetadata(JSON.parse(data));

      if (this.isSuperHifi) {
        parsed.songOrShow = currentTrack.title ?? parsed.songOrShow;
        parsed.artist = currentTrack.artist ?? parsed.artist;
        parsed.image = currentTrack.image ?? parsed.image;

        // sends continuity parameter for SHF streams
        continuity = parsed.continuity || continuity;

        if (parsed.duration && !this.duration) {
          this.duration = parsed.duration;
        }

        if (!useNewSongData) {
          parsed.duration = this.duration;
        } else if (useNewSongData && parsed.duration && parsed.duration !== this.duration) {
          this.duration = parsed.duration;
          useNewSongData = false;
        }

        this.notify({
          type: 'metadata',
          ...parsed,
          raw: data,
        });
        return;
      }

      if (
        this.lastMetadata.songOrShow !== parsed.songOrShow ||
        this.lastMetadata.artist !== parsed.artist ||
        this.lastMetadata.image !== parsed.image ||
        this.lastMetadata.companionAdIframeUrl !== parsed.companionAdIframeUrl ||
        this.lastMetadata.trackStart !== parsed.trackStart ||
        this.lastMetadata.playlistNextStartTime !== parsed.playlistNextStartTime ||
        this.lastMetadata.playlistTailStartTime !== parsed.playlistTailStartTime ||
        this.lastMetadata.liveTime !== parsed.liveTime
      ) {
        this.lastMetadata = { ...this.lastMetadata, ...parsed };

        this.handleVod();

        if (startDts !== undefined) {
          this.deferredMetadata.push({
            data: parsed,
            time: startDts,
          });
        } else {
          this.notify({
            type: 'metadata',
            ...parsed,
            raw: data,
          });
        }
      }
    } catch (error) {
      this.logger?.error(`Error parsing hls metadata: ${data}`);
    }
  }

  private handleVod() {
    // NOTE: CCS-1104 (VOD speed controls): Remove conditional after full feature flag rollout
    if (!this.vodFeatureEnabled) {
      return;
    }

    this.handleVodLiveTransition();
    this.handleVodPlaybackRate();
  }

  private handleVodLiveTransition() {
    const { isLive, datumTime } = this.lastMetadata;

    if (!isLive && isCloseToLive(this.lastMetadata) && datumTime) {
      this.notify({
        type: 'continuePlaying',
        playlistNextStartTime: datumTime,
        playLive: true,
      });
      this.lastMetadata.isLive = true;
    }
  }

  private handleVodPlaybackRate() {
    const isAd = isContentTypeAd(this.lastMetadata.contentType);

    if (this.lastMetadata.isLive || isAd) {
      this.playerElement.playbackRate = this.defaultSpeed;
    } else {
      this.playerElement.playbackRate = this.ctxRate;
    }
  }

  play() {
    audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Play Event`);

    this.logger?.trace('play');
    this.playerElement.play()?.catch((e) => {
      if (this.loadParams.url) {
        this.load(this.loadParams);
        this.loadParams = {
          url: '',
          isSuperHifi: false,
          isAudioPreroll: false,
          liveContentUrl: '',
        };
      } else {
        this.notify({
          type: 'error',
          raw: e,
        });
        this.logger?.error(`play error: ${JSON.stringify(e, undefined, 2)}`);
      }
    });
  }

  playPrerollInProgress(): void {}

  playWithOffset(offset?: number) {
    audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Play with Offset Event`);

    if (this.isSuperHifi) {
      return;
    }
    this.logger?.trace('playWithOffset');
    if (offset !== undefined) {
      this.setPosition(offset);
    }

    this.play();
  }

  // SHF streams use hls.startLoad to manage play/resume
  playSuperHiFi(): void {
    audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Play Super Hifi Event`);

    this.logger?.trace('playSuperHifi');
    this.play();
    this.hls?.startLoad();
  }

  stop() {
    audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Stop Event`);

    this.logger?.trace('stop');
    this.pause();
    if (this.isSuperHifi && this.hls) {
      this.hls.stopLoad();
      return;
    }
    // eslint-disable-next-line no-self-assign
    this.playerElement.src = this.playerElement.src;
    this.ignoreNextLoadingEvent = true;
    this.ignoreZeroUpdateEvent = true;

    if (this.hls && !this.isSuperHifi) {
      this.hls.destroy();
      this.hls = undefined;
    }
    else if (!this.isSuperHifi) this.playerElement.src = '';
  }

  private createHls(): Hls {
    audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Private Create Hls Event`);

    this.logger?.trace('createHls');
    // Docs: https://github.com/video-dev/hls.js/blob/master/docs/API.md

    let hls: Hls;

    // SHF streams require a custom HLS loader
    if (this.isSuperHifi) {
      hls = this.createSuperHifiHls();
    } else {
      hls = new Hls( AMPERWAVE_HLS_CONFIG );
    }

    hls.on(Hls.Events.FRAG_CHANGED, (event, data) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Hls.Event FRAG_CHANGED Event`);

      // Note: data.frag.url tells you what AAC file is playing

      // we use FRAG_CHANGED events to assing SHF metadata
      if (this.isSuperHifi) {
        action = 'refresh';
      }
      this.logger?.trace(`on${event}`);
      const frag = data && data.frag;
      const title = frag && frag.title;
      // frag.title is a JSON string of all metadata in #EXTINF
      // so, parsing the frag.title parses all metadata for that fragment
      isString(title) && this.parseHlsMetadata(title);
      if (
        this.deferredMetadata.length > 0 &&
        this.deferredMetadata[0].time <= (frag && frag.start)
      ) {
        this.notify({
          type: 'metadata',
          ...(this.deferredMetadata.shift()?.data ?? {}),
        });
      }
    });

    hls.on(Hls.Events.FRAG_PARSING_METADATA, (event, data) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Hls.Event FRAG_PARSING_METADATA Event`);

      // we don't use this event for assigning SHF metadata
      if (this.isSuperHifi) {
        return;
      }

      this.logger?.trace(`on${event}`);

      // FRAG_CHANGED event does not occur on SHF initial load
      // so, we must use FRAG_PARSING_METADATA to assign metadata

      const header = Array.from(data.samples[0].data)
        .map((item) => String.fromCharCode(item))
        .join('');

      const tagIndex = header.indexOf('amperwave.metadata');
      if (tagIndex < 0) {
        return;
      }
      const jsonStartIndex = header.indexOf('{', tagIndex);
      if (jsonStartIndex < 0) {
        return;
      }
      const jsonEndIndex = header.indexOf('}', jsonStartIndex);
      if (jsonEndIndex < 0) {
        return;
      }
      this.parseHlsMetadata(header.slice(jsonStartIndex, jsonEndIndex + 1), data.frag.start);
    });

    hls.on(Hls.Events.MANIFEST_PARSED, (event) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Hls.Event MANIFEST_PARSED Event`);

      this.logger?.trace(`on${event}`);
      const maxBitrate = Math.max(...hls.levels.map((level) => level.bitrate));
      const index = hls.levels.findIndex((level) => level.bitrate === maxBitrate);
      hls.startLevel = index;
    });

    hls.on(Hls.Events.ERROR, (_event, error) => {
      audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Hls.Event ERROR Event`);

      // delete stuff that contains circular references
      delete error.context;
      delete error.loader;
      if (error.fatal) {
        this.hasFailed = true;
        this.notify({ type: 'playbackFailed', raw: error})
        audacyLogger.error(`[${LoggerTopic.Streaming}] Hls Playback Failure: ${JSON.stringify(error, null, 2)}`)
      }
      // log and notify with the cleaned up error.
      else {
        audacyLogger.error(`[${LoggerTopic.Streaming}] HLSError: ${JSON.stringify(error, null, 2)}`);
      }
    });

    return hls;
  }

  private createSuperHifiHls() {
    return new Hls(
          {
          autoStartLoad: true,
          enableID3MetadataCues: true,
          enableWorker: true,
          nudgeMaxRetry: 3,
          maxBufferSize: 60 * 1000 * 1000,
          maxBufferLength: 60,
          maxBufferHole: 0.5,
          lowBufferWatchdogPeriod: 0.5,
          highBufferWatchdogPeriod: 3,
          // debug:true,
          fragLoadPolicy: SUPERHIFI_FRAG_LOAD_POLICY,
          manifestLoadPolicy: SUPERHIFI_MANIFEST_LOAD_POLICY,
          playlistLoadPolicy: SUPERHIFI_PLAYLIST_LOAD_POLICY,
          steeringManifestLoadPolicy: SUPERHIFI_STEERING_MANIFEST_LOAD_POLICY,
          // @ts-ignore
          pLoader: CustomPlaylistLoader,
          xhrSetup: function (xhr) {
            xhr.withCredentials = true; // do send cookies
          },
      });
    }

  async load(loadParams: IPlayerLoad) {
    audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Load Event`);

    this.isSuperHifi = loadParams.isSuperHifi;

    // if stream is SHF, determine if resuming and set initial values
    if (this.isSuperHifi) {
      const shouldResume = this.isSuperHifiResuming(loadParams.url);
      this.setInitialSuperHifiValues(loadParams.url);

      // if resuming, resumeSuperHifi and avoid further load steps
      if (shouldResume) {
        this.resumeSuperHifi();
        return;
      }
    }

    this.logger?.trace('load');
    this.lastMetadata = {};
    this.deferredMetadata = [];
    this.stop();
    this.playerElement.defaultPlaybackRate = this.defaultSpeed;
    this.notify({
      type: 'loading',
      raw: { url: loadParams.url, isHLS: loadParams.isPodcast },
    });

    if (!loadParams.isPodcast) {
      this.playerElement.src = '';
      this.hls = this.createHls();
      this.hls.attachMedia(this.playerElement);
      this.hls.loadSource(loadParams.url);
    } else {
      // still needed for podcasts at the moment
      this.playerElement.src = loadParams.url;
    }
    // at the end of load, we want to make sure this is set to false
    if (this.silentAudioInProgress) {
      this.silentAudioInProgress = false;
    }
  }

  // resets duration for scrubber bar if SHF track skipped
  setInitialSuperHifiValues(loadUrl: string) {
    const isSkipping = loadUrl.includes('skip');
    if (isSkipping) {
      this.duration = 0;
    }
    this.url = loadUrl.split('?')[0];
  }

  // if resuming, treat as playSuperHifi and notify loaded
  resumeSuperHifi() {
    this.notify({ type: 'loaded' });
  }

  // if load is called on same SHF stream without skipping, treat as resume
  isSuperHifiResuming = (loadUrl: string): boolean => {
    const isSkipping = loadUrl.includes('skip');
    const sameStation = this.url === loadUrl.split('?')[0];

    const shouldResume = !isSkipping && sameStation && !this.hasFailed;

    return shouldResume;
  };

  getMuted = () => this.playerElement.muted;
  setMuted = (muted: boolean) => (this.playerElement.muted = muted);
  getCurrentTime = () => this.playerElement.currentTime;
  getVolume = () => this.playerElement.volume;
  pause = () => {
    this.logger?.trace('pause');
    this.playerElement.pause();
    // if SHF stream, use hls.stopLoad to pause stream
    if (this.isSuperHifi) {
      this.hls?.stopLoad();
    }
  };

  setDefaultRate = (speed: TPlaybackRate) => (this.defaultSpeed = speed);
  setPosition = (secs: number) => (this.playerElement.currentTime = secs);
  setRate = (speed: TPlaybackRate) => {
    this.ctxRate = speed;
    this.playerElement.playbackRate = speed;
  };
  getRate = () => this.playerElement.playbackRate as TPlaybackRate;
  skip = (secs: number) => (this.playerElement.currentTime += secs);

  setVolume(volume: number) {
    if (!isNaN(volume)) {
      this.playerElement.volume = volume;
    } else {
      this.logger?.error(`Trying to set volume to something not a number = ${volume}`);
    }
  }

  silentAudioHack() {
    audacyLogger.info(`[${LoggerTopic.Streaming}] HTML Player: Silent Audio Detection`);

    const shouldPlaySilentAudio =
      typeof document !== 'undefined' && isAnySafari() && !this.silentAudioPlayed;

    if (shouldPlaySilentAudio) {
      this.logger?.trace('silentAudioHack');
      // we want to ignore the load event that happens when we load the silent audio
      this.ignoreNextLoadingEvent = true;
      // this is used to track the silent audio from when it we initiate it until we load the actual audio
      this.silentAudioInProgress = true;
      this.playerElement.defaultPlaybackRate = this.defaultSpeed;
      this.playerElement.src = SILENT_AUDIO;
      this.playerElement.load();
      // long term we'll replace this with a value in the machines to control whether we want to call this at all
      this.silentAudioPlayed = true;
    }
  }

  private notify: (...args: Parameters<TPlayerEventListener>) => void = (event) => {
    this.eventListeners.forEach((listener) => listener(event));
  };

  addEventListener = (listener: TPlayerEventListener) => this.eventListeners.add(listener);
  removeEventListener = (listener: TPlayerEventListener) => this.eventListeners.delete(listener);

  private isEmpty(event: string | Event) {
    const eventString = JSON.stringify(event);
    this.logger?.debug(`isempty: ${eventString}`);
    const isTrusted = JSON.stringify({ isTrusted: true });
    const empty = JSON.stringify({});
    return !eventString || eventString === isTrusted || eventString === empty;
  }
}
