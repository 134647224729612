/* eslint-disable @typescript-eslint/ban-ts-comment */
import { inspect } from '@xstate/inspect';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import { type AnyStateMachine, type StateValue } from 'xstate';

import { type TFeatureFlags } from '../@types/featureFlags';
import AudacyLogger, { LoggerTopic } from '../AudacyLogger';
import { EntityType } from '../Constants';
import { type PlaybacksHashMap } from '../dataServices/DataServices';
import StandaloneChapter from '../dataServices/StandaloneChapter';
import { type IddLogger, type ILogger } from '../logger';
import { isConsideredPlayed } from '../utils/playbackResumePoints';
import { type IPlayer, type TPlaybackRate, type TPlayerEvent, type TPlayerEventListener } from './players/types';
import { STREAMER_ERROR_STRING } from './streamers/constants';
import exclusiveStationMachineSelector from './streamers/ExclusiveStationMachine';
import liveStationMachineSelector from './streamers/nativePlayerErrorHandling/LiveStationMachine';
import podcastMachine from './streamers/nativePlayerErrorHandling/PodcastEpisodeMachine';
import nativeErrorRewindStationMachineSelector from './streamers/nativePlayerErrorHandling/RewindStationMachine';
import superHifiMachineSelector from './streamers/nativePlayerErrorHandling/superHifiMachine';
import podcastEpisodeMachineSelector, { PODCAST_MACHINE_ID} from './streamers/PodcastEpisodeMachine';
import rewindStationMachineSelector, { REWIND_MACHINE_ID} from './streamers/RewindStationMachine';
import superHifiMachine from './streamers/superHifiMachine'
import { type IStreamerMachineContext } from './streamers/types';
import {
  type IAudioServicesMachineContext,
  type IDataProvider,
  type TPlayableObject,
  type TPlayerReportEvent,
} from './types';
import AudacyDeviceInfo from '@audacy-clients/client-services/src/deviceInfo/DeviceInfo';

export const bindPlayer = (
  player: Pick<IPlayer, 'addEventListener' | 'removeEventListener' | 'getCurrentTime'>,
  send: (e: TPlayerReportEvent) => unknown,
  logger?: ILogger,
  ddLogger?: IddLogger,
): (() => void) => {
  const listener: TPlayerEventListener = (event: TPlayerEvent) => {
    switch (event.type) {
      // TODO: [CCS-1386] Figure out how to handle continuePlay events
      case 'loading':
        send({ type: 'REPORT_LOADING' });
        break;
      case 'loaded':
        send({ type: 'REPORT_LOADED' });
        break;
      case 'playing':
        send({ type: 'REPORT_PLAYING' });
        break;
      case 'paused':
        send({ type: 'REPORT_PAUSED' });
        break;
      case 'destroyed':
        send({ type: 'DESTROY' });
        break;
      case 'continuePlaying':
        send({
          type: 'REPORT_CONTINUE_PLAYING',
          playlistNextStartTime: event.playlistNextStartTime,
          playLive: event.playLive,
        });
        break;
      case 'ended':
        send({ type: 'REPORT_ENDED' });
        break;
      case 'update':
        send({
          type: 'REPORT_TIME_UPDATE',
          time: event.time,
          duration: event.duration,
        });
        break;
      case 'metadata':
        send({
          type: 'REPORT_METADATA',
          metadata: event,
        });
        break;
      case 'error':
        //"NotAllowedError" occurs when autoplay is disabled in user's browser. If so, then pause playback.
        if ((event.raw as { name?: string })?.name === 'NotAllowedError') {
          send({ type: 'REPORT_AUTOPLAY_POLICY' });
        } else {
          send({
            type: 'REPORT_FAILURE',
            errorMessage: STREAMER_ERROR_STRING,
            error: event,
          });
        }
        break;
      case 'playbackFailed':
        send({
          type: 'REPORT_PLAYBACK_FAILED',
          errorMessage: STREAMER_ERROR_STRING,
          error: event,
        });
        break;
      case 'stats':
        logger?.info(`player stats: ${JSON.stringify(event)}`);
        break;
      default:
        logger?.error(
          `Unhandled player event: ${event.type} with payload: ${JSON.stringify(event)}`,
        );
        ddLogger?.error(
          `Unhandled player event: ${event.type} with payload: ${JSON.stringify(event)}`,
        );
        break;
    }
  };
  player.addEventListener(listener);
  return () => player.removeEventListener(listener);
};

export const findMachineForDataObject = async (
  dataObject: TPlayableObject,
  dataProvider: IDataProvider,
  logger: ILogger,
  featureFlags: TFeatureFlags | undefined,
): Promise<AnyStateMachine | undefined> => {
  const isNativeErrorHandlingEnabled = featureFlags?.nativePlayerErrorHandlingPhaseTwoAndroid && AudacyDeviceInfo.getDeviceInfo().platform === 'android';
  AudacyLogger.info(
      `[${
          LoggerTopic.Streaming
      }]: AudioServicesUtils: Choosing machine with isNativeErrorHandlingEnabled:${isNativeErrorHandlingEnabled} flag:${featureFlags?.nativePlayerErrorHandlingPhaseTwoAndroid} platform:${AudacyDeviceInfo.getDeviceInfo().platform}`,
  );
  const selectors = [
    isNativeErrorHandlingEnabled
        ? podcastMachine
        : podcastEpisodeMachineSelector,
    isNativeErrorHandlingEnabled
        ? superHifiMachineSelector
        : superHifiMachine,
    exclusiveStationMachineSelector,

    isNativeErrorHandlingEnabled ?
        nativeErrorRewindStationMachineSelector :
        rewindStationMachineSelector,
    // important that the "Live Station Streamer" is at the bottom-- other station streamers should be tried first, this one is a last resort
    liveStationMachineSelector,
  ];

  for (const selector of selectors) {
    const machine = await selector(dataObject, dataProvider, logger);
    if (machine) {
      return machine;
    }
  }
};

/**
 * Returns the root state node from the given state.
 * If the state is a string, returns the same string.
 * Otherwise, returns the first key of the state object.
 *
 * @param state - The state to extract the root state node from.
 * @returns The root state node of the given state.
 */
export const extractRootState = (state: StateValue) =>
  isString(state) ? state : Object.keys(state)[0];

export const startInspector = () => {
  /**
   * XState Inspector. Disabled for React-Native
   */
  const shouldStartInspector = [
    process.env.NODE_ENV !== 'production',
    process.env.DISABLE_XSTATE_INSPECTOR !== 'true',
    typeof document !== 'undefined',
  ].every(Boolean);

  if (shouldStartInspector) {
    /**
     * We filter out some "big sounding" objects from serialization for the inspector view
     * because they slow the UI wayyyyyy down. - NPL
     */
    const FILTERS = [/services/i, /provider/i, /player/i, /client/i, /logger/i];
    inspect({
      url: 'https://statecharts.io/inspect',
      iframe: false,
      serialize: (k, v) => {
        if (FILTERS.some((f) => f.test(k))) {
          return '[OBJECT TOO BIG]';
        } else if (isFunction(v)) {
          return '[FUNCTION]';
        }
        return v;
      },
    });
  }
};

export const initialize = (
  context: IAudioServicesMachineContext,
): Promise<[Array<TPlayableObject>, PlaybacksHashMap, number, boolean, TPlaybackRate, number?]> => {
  const activeCollection = context.personalizationProvider.getQueue().then((queue) => queue.items);
  const playbacks = context.personalizationProvider.getPlaybacks();
  const volume = context.playerSettingsProvider.getVolume();
  const isMuted = context.playerSettingsProvider.getIsMuted();
  const rate = context.playerSettingsProvider.getPlaybackRate();
  const unauthenticatedPlaybackTime =
    context.unauthenticatedProvider?.getUnauthenticatedPlaybackTime();
  return Promise.all([
    activeCollection,
    playbacks,
    volume,
    isMuted,
    rate,
    unauthenticatedPlaybackTime,
  ]);
};

export const findNextPlayableObject = (
  context: IAudioServicesMachineContext,
): TPlayableObject | undefined => {
  const { activeCollection, activePlayableObject, playbacks } = context;

  const currentPlayingIndex = activeCollection.findIndex(
    (item) => item.data.id === activePlayableObject?.data.id,
  );

  const nextPlayableIndex = activeCollection.findIndex((item, index) => {
    let resumeTime = playbacks[item.data.id];
    if (
      item.getEntityType() === EntityType.STANDALONE_CHAPTER &&
      item instanceof StandaloneChapter
    ) {
      resumeTime = resumeTime - (item).getStartOffset();
    }
    return (
      index > currentPlayingIndex && !isConsideredPlayed(item.data.durationSeconds, resumeTime)
    );
  });

  return activeCollection[nextPlayableIndex];
};

export const findPreviousPlayableObject = (
  context: IAudioServicesMachineContext,
): TPlayableObject | undefined => {
  const { activeCollection, activePlayableObject, playbacks } = context;

  const currentPlayingIndex = activeCollection.findIndex(
    (item) => item.data.id === activePlayableObject?.data.id,
  );

  // We should be able to use findLastIndex and avoid the slice().reverse() but it's not available in ES2015
  const previousPlayableIndexFromEnd = activeCollection
    .slice()
    .reverse()
    .findIndex((item, index, collection) => {
      let resumeTime = playbacks[item.data.id];
      if (
        item.getEntityType() === EntityType.STANDALONE_CHAPTER &&
        item instanceof StandaloneChapter
      ) {
        resumeTime = resumeTime - (item).getStartOffset();
      }
      return (
        collection.length - 1 - index < currentPlayingIndex &&
        !isConsideredPlayed(item.data.durationSeconds, resumeTime)
      );
    });

  const previousPlayableIndex =
    previousPlayableIndexFromEnd === -1
      ? -1
      : activeCollection.length - 1 - previousPlayableIndexFromEnd;

  return activeCollection[previousPlayableIndex];
};

export const findStreamer = async (ctx: IAudioServicesMachineContext) => {
  const {
    activePlayableObject,
    player,
    marketingDataProvider,
    personalizationProvider,
    logger,
    ddError,
    ddLogger,
    locationProvider,
    credentialsProvider,
    platform,
    autoplay,
    startOffset,
    dataProvider,
    signalWireEventListener,
    analyticsProvider,
    deviceInfoProvider,
    playLive,
    skipsCount,
    featureFlags,
    unauthenticatedProvider,
  } = ctx;

  if (!activePlayableObject) {
    return;
  }

  logger?.info('Searching for machine to play object with ID', activePlayableObject.getId());

  ddLogger?.info(`Searching for machine to play object with ID ${activePlayableObject.getId()}`);
  const machine = await findMachineForDataObject(
    activePlayableObject,
    dataProvider,
    logger,
    featureFlags,
  );

  if (!machine) {
    // Could actually happen if we don't have a streamer for the playable object
    logger?.error('No machine found for playable object with ID', activePlayableObject.getId());
    ddLogger?.error(`No machine found for playable object with ID ${activePlayableObject.getId()}`);
    throw new Error('No machine found for playable object'); // TODO: [CCS-1381] model this with a FAILURE state on parent machine?
  } else {
    logger?.info(
      'Found machine',
      machine.id,
      'for playable object with ID',
      activePlayableObject.getId(),
    );
    ddLogger?.info(
      `Found machine ${machine.id} for playable object with ID ${activePlayableObject.getId()}`,
    );
  }

  const childLogger = logger?.getSubLogger({
    name: `Streamer(${machine.id})`,
  });

  const childMachineContext: Omit<
    IStreamerMachineContext<TPlayableObject>,
    /*
     * Item is intentionally omitted here, because some streamers (like the rewind streamer) play a station (which they look up) no matter what the passed object is.
     * For this reason, the selectors decide which object to include as the "item" in the context before returning the machine.
     */
    'contentType' | 'playerSettingsProvider' | 'item'
  > = {
    personalizationProvider,
    marketingDataProvider,
    logger: childLogger,
    ddError,
    ddLogger,
    locationProvider,
    credentialsProvider,
    player,
    platform,
    autoplay,
    requestedOffset: startOffset,
    dataProvider,
    signalWireEventListener,
    analyticsProvider,
    deviceInfoProvider,
    playLive,
    skipsCount,
    featureFlags,
    unauthenticatedProvider,
  };

  return machine.withContext({
    ...machine.context,
    ...childMachineContext,
    rewindProvider: { ...machine.context.rewindProvider, playLive },
  });
};

export const startStreamer = ({
  currentStreamerMachine,
}: IAudioServicesMachineContext): AnyStateMachine => currentStreamerMachine!;

export const shouldIncludeCurrentPosition = (currentStreamerId: string | undefined): boolean => {
 return currentStreamerId === REWIND_MACHINE_ID || currentStreamerId === PODCAST_MACHINE_ID
}
