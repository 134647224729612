import { useCallback } from 'react';

import { IAudioHelpers, useAudioHelpers } from '@audacy-clients/core/hooks/useAudioHelpers';
import { useRecoilValue } from 'recoil';

import { viewContext } from '~/state/dataEvents';

export const useAudioHelpersWeb = (): IAudioHelpers => {
  const viewContextValue = useRecoilValue(viewContext);
  const getViewContext = useCallback(() => ({ ...viewContextValue }), [viewContextValue]);
  const audioHelpers = useAudioHelpers(getViewContext);

  return audioHelpers;
};
